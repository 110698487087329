export const AllDimensionKeys = [
    "error_analytics_meta_source",
    "error_analytics_meta_version",
    "error_analytics_meta_host",
    "prebidUserIdProviderUsed",
    "prebidUserIdProviderConfigured",
    "adQualityIsBlocked",
    "adQualityBlockingType",
    "date",
    "unified",
    "preBidWon",
    "bidder_prebid",
    "bidder",
    "winning_bidder",
    "highest_bidder",
    "dsp",
    "buyer",
    "brand",
    "advertiserDomain",
    "deal",
    "mapped_deal",
    "mediaType",
    "bid",
    "timeToRespond",
    "dfpAdvertiser",
    "dfpAdvertiserId",
    "dfpOrder",
    "dfpOrderId",
    "dfpLineItem",
    "dfpLineItemId",
    "dfpLineItemType",
    "dfpLineItemCostType",
    "dfpCreative",
    "mapped_dfpAdvertiser",
    "mapped_dfpAdvertiserId",
    "mapped_dfpOrder",
    "mapped_dfpOrderId",
    "mapped_dfpLineItem",
    "mapped_dfpLineItemId",
    "mapped_dfpCreative",
    "dfpIsBackfill",
    "source",
    "dfpHash",
    "dfpHashRaw",
    "adUnitCode",
    "mapped_adUnit",
    "placement",
    "adUnit",
    "size",
    "prebid_version",
    "assertiveVersion",
    "timeout",
    "layout",
    "userState",
    "custom_1",
    "custom_2",
    "custom_3",
    "custom_4",
    "custom_5",
    "custom_6",
    "custom_7",
    "custom_8",
    "custom_9",
    "custom_10",
    "custom_11",
    "custom_12",
    "custom_13",
    "custom_14",
    "custom_15",
    "mapped_layout",
    "mapped_userState",
    "mapped_custom_1",
    "mapped_custom_2",
    "mapped_custom_3",
    "mapped_custom_4",
    "mapped_custom_5",
    "mapped_custom_6",
    "mapped_custom_7",
    "mapped_custom_8",
    "mapped_custom_9",
    "newVsReturning",
    "clientDepthSessions",
    "sessionDepthPageViews",
    "sessionDepthImpressions",
    "window_innerSize",
    "window_scrollY",
    "network_type",
    "network_effectiveType",
    "network_downlink",
    "network_rtt",
    "network_saveData",
    "ivt_category",
    "ivt_type",
    "device",
    "operatingSystem",
    "browser",
    "country",
    "countryCode",
    "region",
    "continent",
    "language",
    "referrerType",
    "referrerDomain",
    "referrer",
    "utmSource",
    "utmMedium",
    "utmCampaign",
    "utmTerm",
    "utmContent",
    "mapped_utmSource",
    "mapped_utmMedium",
    "mapped_utmCampaign",
    "mapped_utmTerm",
    "mapped_utmContent",
    "host",
    "entryPage",
    "content",
    "pageLevel1",
    "pageLevel2",
    "pageLevel3",
    "pageLevel4",
    "pageLevel1Inf",
    "content_headline",
    "content_author",
    "content_datePublished",
    "content_dateModified",
    "is_ad_unfilled",
    "buy_report_platform",
    "buy_report_campaign_name",
    "buy_report_adset_name",
    "buy_report_ad_name",
    "buy_report_campaign_id",
    "buy_report_adset_id",
    "buy_report_ad_id",
    "buy_report_subAccountId",
    "buy_report_subAccountName",
    "site_report_site",
    "site_report_platform",
    "site_report_site_name",
    "site_report_site_url",
    "site_report_site_id",
    "site_report_campaign_name",
    "site_report_campaign_id",
    "site_report_subAccountId",
    "site_report_subAccountName",
    "spent_by_section_report_platform",
    "spent_by_section_report_section_name",
    "spent_by_section_report_section_url",
    "spent_by_section_report_section_id",
    "spent_by_section_report_campaign_name",
    "spent_by_section_report_campaign_id",
    "spent_by_section_report_subAccountId",
    "spent_by_section_report_subAccountName",
    "request_reduction_server_datacenter",
    "request_reduction_server_node",
    "request_reduction_server_samples_threshold",
    "request_reduction_server_samples_bidder",
    "request_reduction_server_samples_datacenter",
    "request_reduction_server_samples_bidder_mapped",
    "request_reduction_server_samples_country",
    "request_reduction_server_samples_publisher_id",
    "request_reduction_server_samples_placement_id",
    "request_reduction_server_samples_request_type",
    "request_reduction_server_samples_format",
    "request_reduction_server_samples_sizes",
    "request_reduction_server_samples_platform",
    "request_reduction_server_samples_bundle",
    "request_reduction_server_samples_os",
    "request_reduction_server_samples_browser",
    "request_reduction_server_samples_device",
    "request_reduction_server_samples_has_gps",
    "request_reduction_server_samples_codebase_version",
    "request_reduction_server_samples_version",
    "request_reduction_server_samples_device_language",
    "request_reduction_server_samples_utc_offset",
    "request_reduction_server_samples_gdpr",
    "request_reduction_server_samples_us_privacy",
    "request_reduction_server_samples_video_startdelay",
    "request_reduction_server_samples_video_skip",
    "request_reduction_server_samples_video_rewarded",
    "request_reduction_server_samples_url",
    "request_reduction_server_samples_video_playback_method",
    "request_reduction_server_samples_site_cat",
    "request_reduction_server_samples_unit_types",
    "request_reduction_server_samples_page_cat",
    "request_reduction_server_samples_has_omsdk",
    "request_reduction_server_samples_has_age",
    "request_reduction_server_samples_has_gender",
    "request_reduction_server_samples_has_mraid",
    "request_reduction_server_samples_has_vast",
    "request_reduction_server_samples_has_vpaid",
    "request_reduction_server_samples_has_consent",
    "request_reduction_server_samples_is_qps",
    "request_reduction_server_samples_bid_confusion_matrix",
    "request_reduction_server_samples_win_confusion_matrix",
    "request_reduction_server_samples_domain",
    "request_reduction_server_samples_custom1",
    "request_reduction_server_samples_custom2",
    "request_reduction_server_samples_custom3",
    "hourOfDay",
    "dayOfWeek",
    "dayOfMonth",
    "monthOfYear",
    "fullDate",
    "timeZone",
    "acquisition_clickId",
    "acquisition_clickId_param",
    "manager_status",
    "manager_deployId",
    "manager_deployedAt",
    "manager_versionId",
    "manager_versionName",
    "entityName",
    "publisherName",
    "organizationName",
    "entityId",
    "floor_status",
    "floor_prebid",
    "floor_amazon",
    "floor_gpt",
    "floor_group",
    "floor_threshold",
    "floor_threshold_raw",
    "floor_pbo",
    "request_reduction_client_status",
    "discrepancy_revenue_bidder",
    "discrepancy_revenue_placement",
    "financial_bidder",
    "financial_placement_id",
    "financial_currency",
    "financial_timezone",
    "sessionHadAds",
    "pageViewHadAds",
    "refreshCount",
    "placementRenderCount",
    "topics",
    "cookieDeprecationLabel",
    "domain_and_content",
    "externalId",
    "sessionId",
    "request_reduction_client_v2_country",
    "request_reduction_client_v2_bidder",
    "request_reduction_client_v2_host",
    "request_reduction_client_v2_device_name",
    "request_reduction_client_v2_os_name",
    "request_reduction_client_v2_reduction_group",
    "prebid_source",
] as const;
export type DimensionKeys = (typeof AllDimensionKeys)[number];
