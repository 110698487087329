import { manualISSStats } from "../../../../utils/iss";
import { CalculationType, OptimizationObjective } from "../../calculated-metrics.types-input";
import { PrebidMetricMap } from "../calculated-metrics.types";

export const calculatedPrebidMetrics = PrebidMetricMap({
    // Auctions
    prebid_report_requests: {
        label: "Requests",
        explanation: "Bid Requests sent out",
        formula: m => m.prebid_report_requests,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.NoObjective,
    },
    prebid_report_auctions: {
        label: "Auctions",
        explanation: "Amount of Prebid Auctions",
        formula: m => m.prebid_report_auctions,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.NoObjective,
    },
    prebid_report_request_cpm: {
        label: "Request Bid CPM",
        explanation: "The Bid Request CPM",
        formula: m => (m.prebid_report_bids_revenue / m.prebid_report_requests) * 1000,
        calculationType: CalculationType.Mean,
        decimalPlaces: 3,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_request_win_rpm: {
        label: "Request Win RPM",
        explanation: "The Won Bids RPM",
        formula: m => (m.prebid_report_wins_revenue / m.prebid_report_requests) * 1000,
        calculationType: CalculationType.Mean,
        decimalPlaces: 3,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_auction_win_rpm: {
        label: "Auction Win RPM",
        explanation: "The Won Bids RPM",
        formula: m => (m.prebid_report_wins_revenue / m.prebid_report_auctions) * 1000,
        calculationType: CalculationType.Mean,
        decimalPlaces: 3,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_requests_per_auction: {
        label: "Requests per Auction",
        explanation: "Amount of Requests, per Auction",
        formula: m => m.prebid_report_requests / m.prebid_report_auctions,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.NoObjective,
    },
    prebid_report_bidVolume_auction_cpm: {
        label: "Bid Volume Auction CPM",
        explanation: "The Bid Volume (sum of Bids) per Auction",
        formula: m => (m.prebid_report_bids_revenue / m.prebid_report_auctions) * 1000,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_bidVolume_pageView_cpm: {
        label: "Bid Volume PageView CPM",
        explanation: "The Bid Volume (sum of Bids) per PageView",
        formula: m => (m.prebid_report_bids_revenue / m.prebid_report_pageViews) * 1000,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_bidVolume_session_cpm: {
        label: "Bid Volume Session CPM",
        explanation: "The Bid Volume (sum of Bids) per Session",
        formula: m => (m.prebid_report_bids_revenue / m.prebid_report_sessions) * 1000,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_bidDensity_auction: {
        label: "Bid Density Auction",
        explanation: "The amount of Bids received per Auction",
        formula: m => m.prebid_report_bids / m.prebid_report_auctions,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_bidDensity_pageView: {
        label: "Bid Density PageView",
        explanation: "The amount of Bids received per Page View",
        formula: m => m.prebid_report_bids / m.prebid_report_pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_bidDensity_session: {
        label: "Bid Density Session",
        explanation: "The amount of Bids received per Session",
        formula: m => m.prebid_report_bids / m.prebid_report_sessions,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_timeToRespond: {
        label: "Response Time",
        explanation: "Average responsive time on bid requests",
        formula: m => m.prebid_report_timeToRespond / m.prebid_report_bids,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
    },
    prebid_report_ivt_rate: {
        label: "IVT Rate",
        explanation: "Rate of invalid traffic over entire traffic",
        formula: m =>
            (m.prebid_report_givt_impressions + m.prebid_report_sivt_impressions) /
            m.prebid_report_ivt_measurable_impressions,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    prebid_report_givt_rate: {
        label: "GIVT Rate",
        explanation: "Rate of general invalid traffic over entire traffic",
        formula: m => m.prebid_report_givt_impressions / m.prebid_report_ivt_measurable_impressions,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    prebid_report_sivt_rate: {
        label: "SIVT Rate",
        explanation: "Rate of sophisticated invalid traffic over entire traffic",
        formula: m => m.prebid_report_sivt_impressions / m.prebid_report_ivt_measurable_impressions,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    // timeout
    prebid_report_timeouts: {
        label: "Timeouts",
        explanation: "Bid Requests that timed out",
        formula: m => m.prebid_report_timeouts,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Minimize,
    },
    prebid_report_timeout_rate: {
        label: "Timeout Rate",
        explanation: "Relative amount of bid requests that timed out",
        formula: m => m.prebid_report_timeouts / m.prebid_report_requests,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },

    // NoBid
    prebid_report_noBids: {
        label: "NoBids",
        explanation: "NoBid responses",
        formula: m => m.prebid_report_noBids,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Minimize,
    },
    prebid_report_noBid_rate: {
        label: "NoBid Rate",
        explanation: "Relative amount of NoBids from Requests",
        formula: m => m.prebid_report_noBids / m.prebid_report_requests,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },

    // Bid
    prebid_report_bids: {
        label: "Bids",
        explanation: "Bids received",
        formula: m => m.prebid_report_bids,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_bids_revenue: {
        label: "Bids Revenue",
        explanation: "The sum of all bids received, not just the highest ones",
        formula: m => m.prebid_report_bids_revenue / 1000,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_bid_cpm: {
        label: "Bid CPM",
        explanation: "Revenue per mille (thousand) bids",
        formula: m => (m.prebid_report_bids_revenue / m.prebid_report_bids) * 1000,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_bid_rate: {
        label: "Bid Rate",
        explanation: "Relative amount of Bids from Requests",
        formula: m => m.prebid_report_bids / m.prebid_report_requests,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_bid_position: {
        label: "Bid Position",
        explanation: "Average bid position for the placement in the auction.",
        formula: m => m.prebid_report_bid_position / m.prebid_report_bids,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.NoObjective,
    },
    prebid_report_setTargetingTime: {
        label: "Set Targeting Time",
        explanation: "Average time between bid response received and GAM targeting set in milliseconds.",
        formula: m => m.prebid_report_setTargetingTime / m.prebid_report_bids,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
    },

    // Win
    prebid_report_wins: {
        label: "Wins",
        explanation: "Amount of Bids rendered",
        formula: m => m.prebid_report_wins,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_wins_revenue: {
        label: "Wins Revenue",
        explanation: "Revenue generated from bids that were rendered",
        formula: m => m.prebid_report_wins_revenue / 1000,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_win_cpm: {
        label: "Win CPM",
        explanation: "Revenue per mille (thousand) rendered bids",
        formula: m => (m.prebid_report_wins_revenue / m.prebid_report_wins) * 1000,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_win_rate: {
        label: "Win Rate",
        explanation: "Relative amount of Wins from Bids (Wins / Bids)",
        formula: m => m.prebid_report_wins / m.prebid_report_bids,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_highest_bid_rate: {
        label: "Highest Bid Rate",
        explanation: "Rate of highest bids over all bids",
        formula: m => m.prebid_report_highest_bid / m.prebid_report_bids,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_request_win_rate: {
        label: "Request-Win Rate",
        explanation: "Relative amount of Wins from Requests (Wins / Requests)",
        formula: m => m.prebid_report_wins / m.prebid_report_requests,
        calculationType: CalculationType.ProportionRatio,
        decimalPlaces: 3,
        objective: OptimizationObjective.Maximize,
    },
    prebid_report_renderedTime: {
        label: "Rendered Time",
        explanation: "Average time between auction start and creative rendering.",
        formula: m => m.prebid_report_renderedTime / m.prebid_report_wins,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
    },
    prebid_report_floor_cpm: {
        label: "Floor CPM",
        explanation: "Average CPM of the floored value.",
        formula: m => m.prebid_report_floor_sum / m.prebid_report_floor_non_null,
        calculationType: CalculationType.Mean,
        issStats: manualISSStats.prebid_report_floor_cpm,
        objective: OptimizationObjective.NoObjective,
    },
    prebid_report_floor_rate: {
        label: "Floor Rate",
        explanation: "Rate of requests that had a floor price.",
        formula: m => m.prebid_report_floor_non_null / m.prebid_report_bids,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.NoObjective,
    },
});
