import type { BuildParserFunction } from "@beff/cli";
import { ValidateValidCurrency, type ValidCurrency } from "./codecs/valid-currency-codec.codec";
import { ValidateValidTimezone, type ValidTimezone } from "./codecs/valid-timezone.codec";
import type { RangeKeys } from "./constants/_constants";
import type { CustomAnnotationOwnershipType } from "./constants/custom-annotations-ownership-type.constants";
import type { DimensionKeys } from "./constants/dimension-keys.constants";
import type {
    AddRevenueIndexMetricsType,
    BuyReportMetricsType,
    DiscrepancyRevenueReportMetricsType,
    IndustryWideDataMetricsType,
    MainMetricsType,
    PrebidMetricsType,
    RawMetricsType,
    RawWebMetrics,
    RequestReductionClientMetricsType,
    SiteReportMetricsType,
    SpentBySectionReportMetricsType,
    WebMetrics,
    YieldMetricsType,
} from "./constants/metrics.constants";
import type { KvOptimizationTarget, KvTag, KvTimeFrame } from "./constants/organization-settings.constants";
import type { ReportPlacementKeyType } from "./constants/placement.constants";
import type { BasePlacementKeyType, ReportPlacementKeyTypeInput } from "./constants/reports.constants";
import type { TimeGranularity, ValidTimeGranularityExceptNo } from "./constants/time-granularity.constants";
import p from "./generated/parser";

// start workaround for tsc erasing types of internal .d.ts files
const buildParsers: BuildParserFunction = p.buildParsers;
// end workaround for tsc erasing types of internal .d.ts files

export const CoreCodecs = buildParsers<{
    RangeKeys: RangeKeys;
    CustomAnnotationOwnershipType: CustomAnnotationOwnershipType;
    DimensionKeys: DimensionKeys;
    ReportPlacementKeyType: ReportPlacementKeyType;
    ReportPlacementKeyTypeInput: ReportPlacementKeyTypeInput;
    BasePlacementKeyType: BasePlacementKeyType;
    KvTagCodec: KvTag;
    KvTimeFrameCodec: KvTimeFrame;
    KvOptimizationTargetCodec: KvOptimizationTarget;
    ValidTimeGranularityExceptNo: ValidTimeGranularityExceptNo;
    TimeGranularity: TimeGranularity;
    RawMetricsType: RawMetricsType;
    MainMetricsType: MainMetricsType;
    YieldMetrics: YieldMetricsType;
    IndustryWideDataMetrics: IndustryWideDataMetricsType;
    SpentBySectionReportMetrics: SpentBySectionReportMetricsType;
    AddRevenueIndexMetrics: AddRevenueIndexMetricsType;
    RequestReductionClientMetrics: RequestReductionClientMetricsType;
    BuyReportMetrics: BuyReportMetricsType;
    SiteReportMetrics: SiteReportMetricsType;
    PrebidMetrics: PrebidMetricsType;
    WebMetrics: WebMetrics;
    RawWebMetrics: RawWebMetrics;
    DiscrepancyRevenueReportMetrics: DiscrepancyRevenueReportMetricsType;
    ValidCurrency: ValidCurrency;
    ValidTimezone: ValidTimezone;
}>({
    customFormats: {
        ValidCurrency: ValidateValidCurrency,
        ValidTimezone: ValidateValidTimezone,
    },
});
