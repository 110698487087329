import { CalculationType, OptimizationObjective } from "../../calculated-metrics.types-input";
import { ErrorAnalyticsMetricMap } from "../calculated-metrics.types";

export const calculatedErrorAnalyticsMetrics = ErrorAnalyticsMetricMap({
    errors: {
        label: "Errors",
        explanation: "Sum of errors",
        formula: m => m.errors,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.NoObjective,
    },
    sessions: {
        label: "Sessions",
        explanation: "Sum of sessions",
        formula: m => m.sessions,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.NoObjective,
    },
});
