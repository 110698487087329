import type { z, ZodLiteralDef, ZodTypeAny, ZodUnion } from "zod";

const isLiteralType = <V extends string | number | boolean>(t: any): t is ZodLiteralDef<V> =>
    t?._def?.typeName === "ZodLiteral";

const isUnionType = <CS extends [ZodTypeAny, ZodTypeAny, ...Array<ZodTypeAny>]>(t: any): t is ZodUnion<CS> =>
    t?._def?.typeName === "ZodUnion";

export const allOfUnionOfLiterals = <CS extends [ZodTypeAny, ZodTypeAny, ...Array<ZodTypeAny>]>(
    _it: ZodUnion<CS>
): z.infer<ZodUnion<CS>>[] =>
    _it._def.options.flatMap(t => {
        if (isLiteralType(t)) {
            return [t._def.value];
        }
        if (isUnionType(t)) {
            return allOfUnionOfLiterals(t);
        }
        throw new Error("invalid type in allOfUnion: " + JSON.stringify(t));
    });
