import * as STD from "../utils/std";

export enum KvTag {
    absolute = "absolute",
    rate = "rate",
}
export const ALL_KV_TAGS = STD.getKeys(KvTag) as KvTag[];

export enum KvTimeFrame {
    one_second = "one_second",
    one_minute = "one_minute",
    one_hour = "one_hour",
    one_day = "one_day",
}
export const ALL_KV_TIMEFRAME = STD.getKeys(KvTimeFrame) as KvTimeFrame[];

export enum KvOptimizationTarget {
    reduction_rate = "reduction_rate",
    revenue_loss_rate = "revenue_loss_rate",
    reduction_efficiency = "reduction_efficiency",
}
export const ALL_KV_OPTIMIZATION_TARGETS = STD.getKeys(KvOptimizationTarget) as KvOptimizationTarget[];
