import type { StringFormat } from "@beff/cli";
import { timezones_source } from "../constants/timezones.constants";

export type ValidTimezone = StringFormat<"ValidTimezone">;

export const ValidateValidTimezone = (val: string): val is ValidTimezone =>
    typeof val === "string" && (timezones_source as readonly string[]).includes(val);

export const ALL_TIMEZONES = [...timezones_source] as ValidTimezone[];
export const UTC_TIMEZONE = "UTC" as ValidTimezone;

export type AllTimezonesUnion = (typeof timezones_source)[number];
