//@ts-nocheck
/* eslint-disable */




const customFormatters = {}

function registerCustomFormatter(name, validator) {
  customFormatters[name] = validator;
}

function pushPath(ctx, path) {
  if (ctx.paths == null) {
    ctx.paths = [];
  }
  ctx.paths.push(path);
}
function popPath(ctx) {
  if (ctx.paths == null) {
    throw new Error("popPath: no paths");
  }
  return ctx.paths.pop();
}
function buildError(received, ctx, message) {
  if (ctx.errors == null) {
    ctx.errors = [];
  }
  ctx.errors.push({
    message,
    path: [...(ctx.paths ?? [])],
    received,
  });
}
function buildUnionError(received, ctx, errors) {
  if (ctx.errors == null) {
    ctx.errors = [];
  }
  ctx.errors.push({
    message: "expected one of",
    isUnionError: true,
    errors,
    path: [...(ctx.paths ?? [])],
    received,
  });
}

function decodeObject(ctx, input, required, data, additionalPropsValidator = null) {
  if (!required && input == null) {
    return input;
  }

  const disallowExtraProperties = ctx?.disallowExtraProperties ?? false;

  const allowedExtraProperties = ctx.allowedExtraProperties__ ?? []

  if (typeof input === "object" && !Array.isArray(input) && input !== null) {
    const acc = {};
    for (const [k, v] of Object.entries(data)) {
      pushPath(ctx, k);
      acc[k] = v(ctx, input[k]);
      popPath(ctx);
    }

    if (additionalPropsValidator != null) {
      for (const [k, v] of Object.entries(input)) {
        if (acc[k] == null) {
          pushPath(ctx, k);
          
          acc[k] = additionalPropsValidator(ctx, v);
          popPath(ctx);
        }
      }
    }

    if (disallowExtraProperties) {
      for (const k of Object.keys(input)) {
        if (acc[k] == null && allowedExtraProperties.indexOf(k) == -1) {
          pushPath(ctx, k);
          buildError(input[k], ctx, "extra property");
          popPath(ctx);
        }
      }
    }

    return acc;
  }
  return buildError(input, ctx, "expected object");
}

function decodeArray(ctx, input, required, data) {
  if (!required && input == null) {
    return input;
  }
  if (Array.isArray(input)) {
    const acc = [];
    for (let i = 0; i < input.length; i++) {
      const v = input[i];
      pushPath(ctx, "[" + i + "]");
      acc.push(data(ctx, v));
      popPath(ctx);
    }
    return acc;
  }
  return buildError(input, ctx, "expected array");
}
function decodeString(ctx, input, required) {
  if (!required && input == null) {
    return input;
  }

  if (typeof input === "string") {
    return input;
  }

  return buildError(input, ctx, "expected string");
}

function decodeNumber(ctx, input, required) {
  if (!required && input == null) {
    return input;
  }
  if (typeof input === "number") {
    return input;
  }
  if (String(input).toLowerCase() == "nan") {
    return NaN;
  }

  return buildError(input, ctx, "expected number");
}

function decodeCodec(ctx, input, required, codec) {
  if (!required && input == null) {
    return input;
  }
  switch (codec) {
    case "Codec::ISO8061": {
      const d = new Date(input);
      if (isNaN(d.getTime())) {
        return buildError(input, ctx, "expected ISO8061 date");
      }
      return d;
    }
    case "Codec::BigInt": {
      if (typeof input === "bigint") {
        return input;
      }
      if (typeof input === "number") {
        return BigInt(input);
      }
      if (typeof input === "string") {
        try {
          return BigInt(input);
        } catch (e) {
          
        }
      }
      return buildError(input, ctx, "expected bigint");
    }
  }
  return buildError(input, ctx, "codec " + codec + " not implemented");
}

function decodeStringWithFormat(ctx, input, required, format) {
  if (!required && input == null) {
    return input;
  }
  if (typeof input !== "string") {
    return buildError(input, ctx, "expected string with format " + JSON.stringify(format));
  }

  const validator = customFormatters[format];

  if (validator == null) {
    return buildError(input, ctx, "format " + JSON.stringify(format) + " not implemented");
  }

  const isOk = validator(input);
  if (isOk) {
    return input;
  }
  return buildError(input, ctx, "expected string with format " + JSON.stringify(format));
}

function decodeAnyOfDiscriminated(ctx, input, required, discriminator, mapping) {
  if (!required && input == null) {
    return input;
  }
  const d = input[discriminator];
  if (d == null) {
    return buildError(input, ctx, "expected discriminator key " + JSON.stringify(discriminator))
  }
  const v = mapping[d];
  if (v == null) {
    pushPath(ctx, discriminator);
    const err = buildError(d, ctx, "expected one of " + Object.keys(mapping).map(it => JSON.stringify(it)).join(", "));
    popPath(ctx);
    return err;
  }
  const prevAllow = (ctx.allowedExtraProperties__ ?? []);
  ctx.allowedExtraProperties__ = [...prevAllow, discriminator]
  const out = v(ctx, input);
  ctx.allowedExtraProperties__ = prevAllow;
  return { ...out, [discriminator]: d };
}

function decodeAnyOfConsts(ctx, input, required, consts) {
  if (!required && input == null) {
    return input;
  }
  for (const c of consts) {
    if (input === c) {
      return c;
    }
  }
  return buildError(input, ctx, "expected one of " + consts.map(it => JSON.stringify(it)).join(", "));
}
function decodeAnyOf(ctx, input, required, vs) {
  if (!required && input == null) {
    return input;
  }

  let accErrors = [];
  for (const v of vs) {
    const validatorCtx = {};
    const newValue = v(validatorCtx, input);
    if (validatorCtx.errors == null) {
      return newValue;
    }
    accErrors.push(...(validatorCtx.errors ?? []));
  }
  return buildUnionError(input, ctx, accErrors);
}
function decodeAllOf(ctx, input, required, vs) {
  if (!required && input == null) {
    return input;
  }
  let acc = {};
  let foundOneObject = false;
  let allObjects = true;
  for (const v of vs) {
    const newValue = v(ctx, input);
    const isObj = typeof newValue === "object";
    allObjects = allObjects && isObj;
    if (isObj) {
      foundOneObject = true;
      acc = { ...acc, ...newValue };
    }
  }
  if (foundOneObject && allObjects) {
    return acc;
  }
  return input;
}
function decodeTuple(ctx, input, required, vs) {
  if (!required && input == null) {
    return input;
  }
  if (Array.isArray(input)) {
    const acc = [];
    let idx = 0;
    for (const v of vs.prefix) {
      pushPath(ctx, "[" + idx + "]");
      const newValue = v(ctx, input[idx]);
      popPath(ctx);
      acc.push(newValue);
      idx++;
    }
    if (vs.items != null) {
      for (let i = idx; i < input.length; i++) {
        const v = input[i];
        pushPath(ctx, "[" + i + "]");
        acc.push(vs.items(ctx, v));
        popPath(ctx);
      }
    } else {
      if (input.length > idx) {
        return buildError(input, ctx, "tuple has too many items");
      }
    }
    return acc;
  }
  return buildError(input, ctx, "expected tuple");
}
function decodeBoolean(ctx, input, required) {
  if (!required && input == null) {
    return input;
  }
  if (typeof input === "boolean") {
    return input;
  }
  return buildError(input, ctx, "expected boolean");
}
function decodeAny(ctx, input, required) {
  return input;
}
function decodeNull(ctx, input, required) {
  if (!required && input == null) {
    return input;
  }
  if (input == "null" || input == "undefined") {
    return null;
  }
  if (input == null) {
    return null;
  }
  return buildError(input, ctx, "expected null");
}
function decodeConst(ctx, input, required, constValue) {
  if (!required && input == null) {
    return input;
  }
  if (input == constValue) {
    return constValue;
  }
  return buildError(input, ctx, "expected " + JSON.stringify(constValue));
}

function decodeRegex(ctx, input, required, regex, description) {
  if (!required && input == null) {
    return input;
  }
  if (typeof input === "string") {
    if (regex.test(input)) {
      return input;
    }
  }
  return buildError(input, ctx, "expected string matching " + description);
}

function DecodeRangeKeys(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "Last 1 Hour",
        "Last 10 Minutes",
        "Last 12 Hours",
        "Last 24 Hours",
        "Last 3 Hours",
        "Last 30 Days",
        "Last 30 Minutes",
        "Last 5 Minutes",
        "Last 6 Hours",
        "Last 7 Days",
        "Last Month",
        "Last Quarter",
        "Last Year",
        "Maximum",
        "This Month",
        "Today",
        "Yesterday"
    ]);
}
function DecodeCustomAnnotationOwnershipType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "entity",
        "organization",
        "user"
    ]);
}
function DecodeDimensionKeys(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "acquisition_clickId",
        "acquisition_clickId_param",
        "adQualityBlockingType",
        "adQualityIsBlocked",
        "adUnit",
        "adUnitCode",
        "advertiserDomain",
        "assertiveVersion",
        "bid",
        "bidder",
        "bidder_prebid",
        "brand",
        "browser",
        "buy_report_ad_id",
        "buy_report_ad_name",
        "buy_report_adset_id",
        "buy_report_adset_name",
        "buy_report_campaign_id",
        "buy_report_campaign_name",
        "buy_report_platform",
        "buy_report_subAccountId",
        "buy_report_subAccountName",
        "buyer",
        "clientDepthSessions",
        "content",
        "content_author",
        "content_dateModified",
        "content_datePublished",
        "content_headline",
        "continent",
        "cookieDeprecationLabel",
        "country",
        "countryCode",
        "custom_1",
        "custom_10",
        "custom_11",
        "custom_12",
        "custom_13",
        "custom_14",
        "custom_15",
        "custom_2",
        "custom_3",
        "custom_4",
        "custom_5",
        "custom_6",
        "custom_7",
        "custom_8",
        "custom_9",
        "date",
        "dayOfMonth",
        "dayOfWeek",
        "deal",
        "device",
        "dfpAdvertiser",
        "dfpAdvertiserId",
        "dfpCreative",
        "dfpHash",
        "dfpHashRaw",
        "dfpIsBackfill",
        "dfpLineItem",
        "dfpLineItemCostType",
        "dfpLineItemId",
        "dfpLineItemType",
        "dfpOrder",
        "dfpOrderId",
        "discrepancy_revenue_bidder",
        "discrepancy_revenue_placement",
        "domain_and_content",
        "dsp",
        "entityId",
        "entityName",
        "entryPage",
        "error_analytics_meta_host",
        "error_analytics_meta_source",
        "error_analytics_meta_version",
        "externalId",
        "financial_bidder",
        "financial_currency",
        "financial_placement_id",
        "financial_timezone",
        "floor_amazon",
        "floor_gpt",
        "floor_group",
        "floor_pbo",
        "floor_prebid",
        "floor_status",
        "floor_threshold",
        "floor_threshold_raw",
        "fullDate",
        "highest_bidder",
        "host",
        "hourOfDay",
        "is_ad_unfilled",
        "ivt_category",
        "ivt_type",
        "language",
        "layout",
        "manager_deployId",
        "manager_deployedAt",
        "manager_status",
        "manager_versionId",
        "manager_versionName",
        "mapped_adUnit",
        "mapped_custom_1",
        "mapped_custom_2",
        "mapped_custom_3",
        "mapped_custom_4",
        "mapped_custom_5",
        "mapped_custom_6",
        "mapped_custom_7",
        "mapped_custom_8",
        "mapped_custom_9",
        "mapped_deal",
        "mapped_dfpAdvertiser",
        "mapped_dfpAdvertiserId",
        "mapped_dfpCreative",
        "mapped_dfpLineItem",
        "mapped_dfpLineItemId",
        "mapped_dfpOrder",
        "mapped_dfpOrderId",
        "mapped_layout",
        "mapped_userState",
        "mapped_utmCampaign",
        "mapped_utmContent",
        "mapped_utmMedium",
        "mapped_utmSource",
        "mapped_utmTerm",
        "mediaType",
        "monthOfYear",
        "network_downlink",
        "network_effectiveType",
        "network_rtt",
        "network_saveData",
        "network_type",
        "newVsReturning",
        "operatingSystem",
        "organizationName",
        "pageLevel1",
        "pageLevel1Inf",
        "pageLevel2",
        "pageLevel3",
        "pageLevel4",
        "pageViewHadAds",
        "placement",
        "placementRenderCount",
        "preBidWon",
        "prebidUserIdProviderConfigured",
        "prebidUserIdProviderUsed",
        "prebid_source",
        "prebid_version",
        "publisherName",
        "referrer",
        "referrerDomain",
        "referrerType",
        "refreshCount",
        "region",
        "request_reduction_client_status",
        "request_reduction_client_v2_bidder",
        "request_reduction_client_v2_country",
        "request_reduction_client_v2_device_name",
        "request_reduction_client_v2_host",
        "request_reduction_client_v2_os_name",
        "request_reduction_client_v2_reduction_group",
        "request_reduction_server_datacenter",
        "request_reduction_server_node",
        "request_reduction_server_samples_bid_confusion_matrix",
        "request_reduction_server_samples_bidder",
        "request_reduction_server_samples_bidder_mapped",
        "request_reduction_server_samples_browser",
        "request_reduction_server_samples_bundle",
        "request_reduction_server_samples_codebase_version",
        "request_reduction_server_samples_country",
        "request_reduction_server_samples_custom1",
        "request_reduction_server_samples_custom2",
        "request_reduction_server_samples_custom3",
        "request_reduction_server_samples_datacenter",
        "request_reduction_server_samples_device",
        "request_reduction_server_samples_device_language",
        "request_reduction_server_samples_domain",
        "request_reduction_server_samples_format",
        "request_reduction_server_samples_gdpr",
        "request_reduction_server_samples_has_age",
        "request_reduction_server_samples_has_consent",
        "request_reduction_server_samples_has_gender",
        "request_reduction_server_samples_has_gps",
        "request_reduction_server_samples_has_mraid",
        "request_reduction_server_samples_has_omsdk",
        "request_reduction_server_samples_has_vast",
        "request_reduction_server_samples_has_vpaid",
        "request_reduction_server_samples_is_qps",
        "request_reduction_server_samples_os",
        "request_reduction_server_samples_page_cat",
        "request_reduction_server_samples_placement_id",
        "request_reduction_server_samples_platform",
        "request_reduction_server_samples_publisher_id",
        "request_reduction_server_samples_request_type",
        "request_reduction_server_samples_site_cat",
        "request_reduction_server_samples_sizes",
        "request_reduction_server_samples_threshold",
        "request_reduction_server_samples_unit_types",
        "request_reduction_server_samples_url",
        "request_reduction_server_samples_us_privacy",
        "request_reduction_server_samples_utc_offset",
        "request_reduction_server_samples_version",
        "request_reduction_server_samples_video_playback_method",
        "request_reduction_server_samples_video_rewarded",
        "request_reduction_server_samples_video_skip",
        "request_reduction_server_samples_video_startdelay",
        "request_reduction_server_samples_win_confusion_matrix",
        "sessionDepthImpressions",
        "sessionDepthPageViews",
        "sessionHadAds",
        "sessionId",
        "site_report_campaign_id",
        "site_report_campaign_name",
        "site_report_platform",
        "site_report_site",
        "site_report_site_id",
        "site_report_site_name",
        "site_report_site_url",
        "site_report_subAccountId",
        "site_report_subAccountName",
        "size",
        "source",
        "spent_by_section_report_campaign_id",
        "spent_by_section_report_campaign_name",
        "spent_by_section_report_platform",
        "spent_by_section_report_section_id",
        "spent_by_section_report_section_name",
        "spent_by_section_report_section_url",
        "spent_by_section_report_subAccountId",
        "spent_by_section_report_subAccountName",
        "timeToRespond",
        "timeZone",
        "timeout",
        "topics",
        "unified",
        "userState",
        "utmCampaign",
        "utmContent",
        "utmMedium",
        "utmSource",
        "utmTerm",
        "window_innerSize",
        "window_scrollY",
        "winning_bidder"
    ]);
}
function DecodeReportPlacementKeyType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "ad_revenue",
        "buy_ad",
        "buy_adset",
        "buy_campaign",
        "discrepancy_revenue",
        "error_analytics",
        "error_log",
        "financial",
        "flooring",
        "industry",
        "prebid",
        "request_reduction_client_v2",
        "request_reduction_server_latency",
        "request_reduction_server_samples_predict",
        "request_reduction_server_samples_profile",
        "segments",
        "session_revenue",
        "session_time",
        "site",
        "spent_by_section",
        "time_series_forecast",
        "web",
        "yield"
    ]);
}
function DecodeReportPlacementKeyTypeInput(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "__no_placement__",
        "ad_revenue",
        "buy_ad",
        "buy_adset",
        "buy_campaign",
        "discrepancy_revenue",
        "error_analytics",
        "error_log",
        "financial",
        "flooring",
        "industry",
        "request_reduction_client_v2",
        "request_reduction_server_latency",
        "request_reduction_server_samples_predict",
        "request_reduction_server_samples_profile",
        "segments",
        "session_revenue",
        "session_time",
        "site",
        "spent_by_section",
        "time_series_forecast",
        "web",
        "yield_or_prebid_or_web"
    ]);
}
function DecodeBasePlacementKeyType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "ad_revenue",
        "buy_ad",
        "buy_adset",
        "buy_campaign",
        "discrepancy_revenue",
        "error_analytics",
        "error_log",
        "financial",
        "flooring",
        "industry",
        "request_reduction_client_v2",
        "request_reduction_server_latency",
        "request_reduction_server_samples_predict",
        "request_reduction_server_samples_profile",
        "segments",
        "session_revenue",
        "session_time",
        "site",
        "spent_by_section",
        "time_series_forecast",
        "web"
    ]);
}
function DecodeKvTag(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "absolute",
        "rate"
    ]);
}
function DecodeKvTimeFrame(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "one_day",
        "one_hour",
        "one_minute",
        "one_second"
    ]);
}
function DecodeKvOptimizationTarget(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "reduction_efficiency",
        "reduction_rate",
        "revenue_loss_rate"
    ]);
}
function DecodeValidTimeGranularityExceptNo(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "by_10_minutes",
        "by_day",
        "by_hour",
        "by_minute",
        "by_month",
        "by_week"
    ]);
}
function DecodeTimeGranularity(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "by_10_minutes",
        "by_day",
        "by_hour",
        "by_minute",
        "by_month",
        "by_week",
        "no_granularity"
    ]);
}
function DecodeDiscrepancyRevenueReportRawMetrics(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "actual_impressions",
        "actual_revenue",
        "assertive_impressions",
        "assertive_revenue"
    ]);
}
function DecodeRawMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "_count",
        "acquisition_cost",
        "acquisition_cost_measurable_session_starts",
        "actual_impressions",
        "actual_revenue",
        "adSense_impressions",
        "adSense_revenue",
        "ad_unfilled_count",
        "aps_impressions",
        "aps_revenue",
        "assertive_impressions",
        "assertive_revenue",
        "ayMediation_impressions",
        "ayMediation_revenue",
        "bias",
        "blocked_impressions",
        "clicks",
        "clicksBounced",
        "clicksReturned",
        "direct_impressions",
        "direct_impressions_legacy",
        "direct_revenue",
        "direct_revenue_legacy",
        "dynamicAllocation_confirmed_clicks",
        "dynamicAllocation_impressions",
        "dynamicAllocation_predicted_impressions",
        "dynamicAllocation_predicted_revenue",
        "dynamicAllocation_predicted_revenue_abs_difference",
        "dynamicAllocation_predicted_revenue_residual",
        "dynamicAllocation_predicted_revenue_server",
        "dynamicAllocation_predicted_revenue_server_abs_difference",
        "dynamicAllocation_predicted_revenue_server_residual",
        "dynamicAllocation_revenue",
        "dynamicAllocation_revenue_sum_of_squares",
        "dynamicAllocation_revenue_with_forecast",
        "dynamicAllocation_revenue_with_forecast_client",
        "first_five_indicator",
        "floor",
        "floor_empirical_revenue",
        "floor_measurable_impressions",
        "givt_impressions",
        "impressions",
        "impressions_filled",
        "impressions_lineitem_house",
        "impressions_unfilled",
        "ivt_measurable_impressions",
        "lineItem_revenue",
        "lineItem_revenue_legacy",
        "max_timestamp",
        "min_timestamp",
        "nativo_impressions",
        "nativo_revenue",
        "outbrain_impressions",
        "outbrain_revenue",
        "pageView_starts",
        "pageViews",
        "pageViewsExact",
        "prebid_cached_count",
        "prebid_incremental_value",
        "prebid_lost_impressions",
        "prebid_lost_revenue",
        "prebid_lost_revenue_hb",
        "prebid_report_auctions",
        "prebid_report_bid_position",
        "prebid_report_bids",
        "prebid_report_bids_revenue",
        "prebid_report_floor_non_null",
        "prebid_report_floor_sum",
        "prebid_report_givt_impressions",
        "prebid_report_highest_bid",
        "prebid_report_ivt_measurable_impressions",
        "prebid_report_noBids",
        "prebid_report_pageViews",
        "prebid_report_renderedTime",
        "prebid_report_requests",
        "prebid_report_sessions",
        "prebid_report_setTargetingTime",
        "prebid_report_sivt_impressions",
        "prebid_report_timeToRespond",
        "prebid_report_timeouts",
        "prebid_report_wins",
        "prebid_report_wins_revenue",
        "prebid_revenue",
        "prebid_user_id_providers_sum_uses",
        "prebid_user_id_providers_zero_usages",
        "prebid_won_impressions",
        "prebid_won_revenue",
        "request_reduction_server_samples_bid_false_negative",
        "request_reduction_server_samples_bid_false_positive",
        "request_reduction_server_samples_bid_revenue",
        "request_reduction_server_samples_bid_true_negative",
        "request_reduction_server_samples_bid_true_positive",
        "request_reduction_server_samples_bids",
        "request_reduction_server_samples_missed_revenue",
        "request_reduction_server_samples_predicts",
        "request_reduction_server_samples_requests",
        "request_reduction_server_samples_win_false_negative",
        "request_reduction_server_samples_win_false_positive",
        "request_reduction_server_samples_win_revenue",
        "request_reduction_server_samples_win_true_negative",
        "request_reduction_server_samples_win_true_positive",
        "request_reduction_server_samples_wins",
        "session_starts",
        "sessions",
        "sessions_with_bounced_clicks",
        "sivt_impressions",
        "taboola_impressions",
        "taboola_revenue",
        "uniqueExternalId",
        "viewable",
        "viewable_measurable_impressions",
        "vitals_cls_bad",
        "vitals_cls_good",
        "vitals_cls_measurable",
        "vitals_cls_medium",
        "vitals_cls_sum",
        "vitals_fcp_bad",
        "vitals_fcp_good",
        "vitals_fcp_measurable",
        "vitals_fcp_medium",
        "vitals_fcp_sum",
        "vitals_fid_bad",
        "vitals_fid_good",
        "vitals_fid_measurable",
        "vitals_fid_medium",
        "vitals_fid_sum",
        "vitals_inp_bad",
        "vitals_inp_good",
        "vitals_inp_measurable",
        "vitals_inp_medium",
        "vitals_inp_sum",
        "vitals_lcp_bad",
        "vitals_lcp_good",
        "vitals_lcp_measurable",
        "vitals_lcp_medium",
        "vitals_lcp_sum",
        "vitals_ttfb_bad",
        "vitals_ttfb_good",
        "vitals_ttfb_measurable",
        "vitals_ttfb_medium",
        "vitals_ttfb_sum",
        "yahooGemini_impressions",
        "yahooGemini_revenue"
    ]);
}
function DecodeRawPrebidMetrics(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "prebid_report_auctions",
        "prebid_report_bid_position",
        "prebid_report_bids",
        "prebid_report_bids_revenue",
        "prebid_report_floor_non_null",
        "prebid_report_floor_sum",
        "prebid_report_givt_impressions",
        "prebid_report_highest_bid",
        "prebid_report_ivt_measurable_impressions",
        "prebid_report_noBids",
        "prebid_report_pageViews",
        "prebid_report_renderedTime",
        "prebid_report_requests",
        "prebid_report_sessions",
        "prebid_report_setTargetingTime",
        "prebid_report_sivt_impressions",
        "prebid_report_timeToRespond",
        "prebid_report_timeouts",
        "prebid_report_wins",
        "prebid_report_wins_revenue"
    ]);
}
function DecodeRawRequestReductionServerSamplesMetrics(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "request_reduction_server_samples_bid_false_negative",
        "request_reduction_server_samples_bid_false_positive",
        "request_reduction_server_samples_bid_revenue",
        "request_reduction_server_samples_bid_true_negative",
        "request_reduction_server_samples_bid_true_positive",
        "request_reduction_server_samples_bids",
        "request_reduction_server_samples_missed_revenue",
        "request_reduction_server_samples_predicts",
        "request_reduction_server_samples_requests",
        "request_reduction_server_samples_win_false_negative",
        "request_reduction_server_samples_win_false_positive",
        "request_reduction_server_samples_win_revenue",
        "request_reduction_server_samples_win_true_negative",
        "request_reduction_server_samples_win_true_positive",
        "request_reduction_server_samples_wins"
    ]);
}
function DecodeRawYieldMetrics(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "_count",
        "acquisition_cost",
        "acquisition_cost_measurable_session_starts",
        "adSense_impressions",
        "adSense_revenue",
        "ad_unfilled_count",
        "aps_impressions",
        "aps_revenue",
        "ayMediation_impressions",
        "ayMediation_revenue",
        "bias",
        "blocked_impressions",
        "clicks",
        "clicksBounced",
        "clicksReturned",
        "direct_impressions",
        "direct_impressions_legacy",
        "direct_revenue",
        "direct_revenue_legacy",
        "dynamicAllocation_confirmed_clicks",
        "dynamicAllocation_impressions",
        "dynamicAllocation_predicted_impressions",
        "dynamicAllocation_predicted_revenue",
        "dynamicAllocation_predicted_revenue_abs_difference",
        "dynamicAllocation_predicted_revenue_residual",
        "dynamicAllocation_predicted_revenue_server",
        "dynamicAllocation_predicted_revenue_server_abs_difference",
        "dynamicAllocation_predicted_revenue_server_residual",
        "dynamicAllocation_revenue",
        "dynamicAllocation_revenue_sum_of_squares",
        "dynamicAllocation_revenue_with_forecast",
        "dynamicAllocation_revenue_with_forecast_client",
        "first_five_indicator",
        "floor",
        "floor_empirical_revenue",
        "floor_measurable_impressions",
        "givt_impressions",
        "impressions",
        "impressions_filled",
        "impressions_lineitem_house",
        "impressions_unfilled",
        "ivt_measurable_impressions",
        "lineItem_revenue",
        "lineItem_revenue_legacy",
        "max_timestamp",
        "min_timestamp",
        "nativo_impressions",
        "nativo_revenue",
        "outbrain_impressions",
        "outbrain_revenue",
        "pageView_starts",
        "pageViews",
        "pageViewsExact",
        "prebid_cached_count",
        "prebid_incremental_value",
        "prebid_lost_impressions",
        "prebid_lost_revenue",
        "prebid_lost_revenue_hb",
        "prebid_revenue",
        "prebid_user_id_providers_sum_uses",
        "prebid_user_id_providers_zero_usages",
        "prebid_won_impressions",
        "prebid_won_revenue",
        "session_starts",
        "sessions",
        "sessions_with_bounced_clicks",
        "sivt_impressions",
        "taboola_impressions",
        "taboola_revenue",
        "uniqueExternalId",
        "viewable",
        "viewable_measurable_impressions",
        "vitals_cls_bad",
        "vitals_cls_good",
        "vitals_cls_measurable",
        "vitals_cls_medium",
        "vitals_cls_sum",
        "vitals_fcp_bad",
        "vitals_fcp_good",
        "vitals_fcp_measurable",
        "vitals_fcp_medium",
        "vitals_fcp_sum",
        "vitals_fid_bad",
        "vitals_fid_good",
        "vitals_fid_measurable",
        "vitals_fid_medium",
        "vitals_fid_sum",
        "vitals_inp_bad",
        "vitals_inp_good",
        "vitals_inp_measurable",
        "vitals_inp_medium",
        "vitals_inp_sum",
        "vitals_lcp_bad",
        "vitals_lcp_good",
        "vitals_lcp_measurable",
        "vitals_lcp_medium",
        "vitals_lcp_sum",
        "vitals_ttfb_bad",
        "vitals_ttfb_good",
        "vitals_ttfb_measurable",
        "vitals_ttfb_medium",
        "vitals_ttfb_sum",
        "yahooGemini_impressions",
        "yahooGemini_revenue"
    ]);
}
function DecodeAddRevenueIndexMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "ad_revenue_index_impressions",
        "ad_revenue_index_percentage"
    ]);
}
function DecodeBuyReportMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "buy_report_click_revenue",
        "buy_report_click_revenue_with_forecast",
        "buy_report_click_rpm",
        "buy_report_click_rpm_with_forecast",
        "buy_report_clicks",
        "buy_report_cpai",
        "buy_report_cpc",
        "buy_report_cps",
        "buy_report_ffi",
        "buy_report_new_sessions",
        "buy_report_profit",
        "buy_report_profit_with_forecast",
        "buy_report_roi",
        "buy_report_roi_with_forecast",
        "buy_report_session_click_ratio",
        "buy_report_sessions",
        "buy_report_spent"
    ]);
}
function DecodeDiscrepancyRevenueReportMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "actual_impressions",
        "actual_revenue",
        "assertive_impressions",
        "assertive_revenue",
        "calculated_impressions_discrepancy",
        "calculated_impressions_discrepancy_percentage",
        "calculated_revenue_discrepancy",
        "calculated_revenue_discrepancy_percentage"
    ]);
}
function DecodeErrorAnalyticsMetrics(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "errors",
        "sessions"
    ]);
}
function DecodeFinancialReportMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "actual_impressions",
        "actual_revenue",
        "actual_revenue_ssp_currency"
    ]);
}
function DecodeIndustryWideDataMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "ind_fair_share_revenue",
        "ind_fair_share_win_rate",
        "ind_presence"
    ]);
}
function DecodeMainMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "acquisition_cost",
        "acquisition_cost_measurable_session_starts",
        "active_pageView_time_minutes",
        "active_session_time_minutes",
        "actual_impressions",
        "actual_revenue",
        "actual_revenue_ssp_currency",
        "adSense_impressions",
        "adSense_pageView_rpm",
        "adSense_revenue",
        "adSense_rpm",
        "adSense_session_rpm",
        "adSense_winRate",
        "ad_revenue_index_impressions",
        "ad_revenue_index_percentage",
        "ad_unfilled",
        "ads_per_pageView",
        "ads_per_pageViewExact",
        "ads_per_session",
        "aps_impressions",
        "aps_pageView_rpm",
        "aps_revenue",
        "aps_rpm",
        "aps_session_rpm",
        "aps_winRate",
        "assertive_impressions",
        "assertive_revenue",
        "avg_decoded_body_size",
        "avg_dom_completion_time",
        "avg_dom_processing_time",
        "avg_encoded_body_size",
        "avg_network_time",
        "avg_on_load_time",
        "avg_page_load_time",
        "avg_server_time",
        "avg_transfer_size",
        "avg_transfer_time",
        "ayMediation_impressions",
        "ayMediation_pageView_rpm",
        "ayMediation_revenue",
        "ayMediation_rpm",
        "ayMediation_session_rpm",
        "ayMediation_winRate",
        "bias",
        "blocked_impressions",
        "blocked_impressions_rate",
        "bounceRate",
        "buy_report_click_revenue",
        "buy_report_click_revenue_with_forecast",
        "buy_report_click_rpm",
        "buy_report_click_rpm_with_forecast",
        "buy_report_clicks",
        "buy_report_cpai",
        "buy_report_cpc",
        "buy_report_cps",
        "buy_report_ffi",
        "buy_report_new_sessions",
        "buy_report_profit",
        "buy_report_profit_with_forecast",
        "buy_report_roi",
        "buy_report_roi_with_forecast",
        "buy_report_session_click_ratio",
        "buy_report_sessions",
        "buy_report_spent",
        "calculated_impressions_discrepancy",
        "calculated_impressions_discrepancy_percentage",
        "calculated_revenue_discrepancy",
        "calculated_revenue_discrepancy_percentage",
        "cbr",
        "clicks",
        "clicks_bounce_session_rate",
        "clr",
        "content_recommendation_impressions",
        "content_recommendation_pageView_rpm",
        "content_recommendation_revenue",
        "content_recommendation_rpm",
        "content_recommendation_session_rpm",
        "content_recommendation_winRate",
        "ctr",
        "diff",
        "direct_impressions",
        "direct_pageView_rpm",
        "direct_pageView_valueRpm",
        "direct_revenue",
        "direct_rpm",
        "direct_session_rpm",
        "direct_session_valueRpm",
        "direct_valueRpm",
        "direct_winRate",
        "dynamicAllocation_confirmed_clicks_rate",
        "dynamicAllocation_impressions",
        "dynamicAllocation_pageViewExact_rpm",
        "dynamicAllocation_pageView_rpm",
        "dynamicAllocation_pageView_valueRpm",
        "dynamicAllocation_predicted_impressions",
        "dynamicAllocation_predicted_impressions_error",
        "dynamicAllocation_predicted_revenue",
        "dynamicAllocation_predicted_revenue_error",
        "dynamicAllocation_predicted_revenue_error_server",
        "dynamicAllocation_predicted_revenue_mae",
        "dynamicAllocation_predicted_revenue_mae_server",
        "dynamicAllocation_predicted_revenue_r2",
        "dynamicAllocation_predicted_revenue_r2_server",
        "dynamicAllocation_predicted_revenue_relative_error",
        "dynamicAllocation_predicted_revenue_relative_error_server",
        "dynamicAllocation_predicted_revenue_rmse",
        "dynamicAllocation_predicted_revenue_rmse_server",
        "dynamicAllocation_predicted_revenue_server",
        "dynamicAllocation_predicted_rpm",
        "dynamicAllocation_predicted_rpm_server",
        "dynamicAllocation_revenue",
        "dynamicAllocation_revenue_with_forecast",
        "dynamicAllocation_revenue_with_forecast_client",
        "dynamicAllocation_rpm",
        "dynamicAllocation_session_rpm",
        "dynamicAllocation_session_rpm_with_forecast",
        "dynamicAllocation_session_valueRpm",
        "dynamicAllocation_valueRpm",
        "dynamicAllocation_winRate",
        "errors",
        "errors_per_pageView",
        "errors_per_session",
        "floor_empirical_revenue_ratio",
        "floor_impressions",
        "floor_rpm",
        "givt_rate",
        "impressions",
        "impressions_filled",
        "impressions_filled_rate",
        "impressions_unfilled",
        "impressions_unfilled_n_house",
        "impressions_unfilled_n_house_rate",
        "impressions_unfilled_rate",
        "ind_fair_share_revenue",
        "ind_fair_share_win_rate",
        "ind_presence",
        "ivt_rate",
        "lineItem_revenue",
        "lost_rpm_hb",
        "mean_scrollY",
        "min_cpm",
        "miss_click_impression_pct",
        "nativo_impressions",
        "nativo_pageView_rpm",
        "nativo_revenue",
        "nativo_rpm",
        "nativo_session_rpm",
        "nativo_winRate",
        "pageView_rpm",
        "pageView_starts",
        "pageView_valueRpm",
        "pageViews",
        "pageViewsExact",
        "pageViewsExact_per_session",
        "pageViews_per_session",
        "pageViews_without_ads_ratio",
        "prebid_cache_rate",
        "prebid_incremental_value",
        "prebid_incremental_value_rpm",
        "prebid_lost_impressions",
        "prebid_lost_pageView_rpm",
        "prebid_lost_pageView_valueRpm",
        "prebid_lost_revenue",
        "prebid_lost_rpm",
        "prebid_lost_session_rpm",
        "prebid_lost_session_valueRpm",
        "prebid_lost_valueRpm",
        "prebid_pageView_rpm",
        "prebid_pageView_valueRpm",
        "prebid_report_auction_win_rpm",
        "prebid_report_auctions",
        "prebid_report_bidDensity_auction",
        "prebid_report_bidDensity_pageView",
        "prebid_report_bidDensity_session",
        "prebid_report_bidVolume_auction_cpm",
        "prebid_report_bidVolume_pageView_cpm",
        "prebid_report_bidVolume_session_cpm",
        "prebid_report_bid_cpm",
        "prebid_report_bid_position",
        "prebid_report_bid_rate",
        "prebid_report_bids",
        "prebid_report_bids_revenue",
        "prebid_report_floor_cpm",
        "prebid_report_floor_rate",
        "prebid_report_givt_rate",
        "prebid_report_highest_bid_rate",
        "prebid_report_ivt_rate",
        "prebid_report_noBid_rate",
        "prebid_report_noBids",
        "prebid_report_renderedTime",
        "prebid_report_request_cpm",
        "prebid_report_request_win_rate",
        "prebid_report_request_win_rpm",
        "prebid_report_requests",
        "prebid_report_requests_per_auction",
        "prebid_report_setTargetingTime",
        "prebid_report_sivt_rate",
        "prebid_report_timeToRespond",
        "prebid_report_timeout_rate",
        "prebid_report_timeouts",
        "prebid_report_win_cpm",
        "prebid_report_win_rate",
        "prebid_report_wins",
        "prebid_report_wins_revenue",
        "prebid_revenue",
        "prebid_rpm",
        "prebid_session_rpm",
        "prebid_session_valueRpm",
        "prebid_user_id_providers_avg",
        "prebid_user_id_providers_zero_usages",
        "prebid_user_id_providers_zero_usages_rate",
        "prebid_valueRpm",
        "prebid_winRate",
        "prebid_won_impressions",
        "prebid_won_pageViewExact_rpm",
        "prebid_won_pageView_rpm",
        "prebid_won_pageView_valueRpm",
        "prebid_won_revenue",
        "prebid_won_rpm",
        "prebid_won_session_rpm",
        "prebid_won_session_valueRpm",
        "prebid_won_valueRpm",
        "programmatic_impressions",
        "programmatic_pageView_rpm",
        "programmatic_pageView_valueRpm",
        "programmatic_revenue",
        "programmatic_revenue_with_forecast",
        "programmatic_rpm",
        "programmatic_session_rpm",
        "programmatic_session_rpm_with_forecast",
        "programmatic_session_valueRpm",
        "programmatic_valueRpm",
        "programmatic_winRate",
        "request_reduction_client_bid_cpm",
        "request_reduction_client_bid_volume_loss_ratio",
        "request_reduction_client_bid_volume_total",
        "request_reduction_client_bids",
        "request_reduction_client_left_waste",
        "request_reduction_client_lost_bids",
        "request_reduction_client_lost_bids_ratio",
        "request_reduction_client_lost_wins",
        "request_reduction_client_lost_wins_ratio",
        "request_reduction_client_requests",
        "request_reduction_client_saved_waste",
        "request_reduction_client_saved_waste_ratio",
        "request_reduction_client_untouched_bid_volume",
        "request_reduction_client_untouched_bids",
        "request_reduction_client_waste_ratio",
        "request_reduction_client_wins",
        "request_reduction_client_won_volume_loss_ratio",
        "request_reduction_server_latency_average_bidder_per_auctions",
        "request_reduction_server_latency_predict_bidders",
        "request_reduction_server_latency_predict_errors",
        "request_reduction_server_latency_predict_latencies_p50",
        "request_reduction_server_latency_predict_latencies_p95",
        "request_reduction_server_latency_predict_latencies_p99",
        "request_reduction_server_latency_predict_requests",
        "request_reduction_server_latency_predict_result_ratio",
        "request_reduction_server_latency_result_errors",
        "request_reduction_server_latency_result_latencies_p50",
        "request_reduction_server_latency_result_latencies_p95",
        "request_reduction_server_latency_result_latencies_p99",
        "request_reduction_server_latency_result_requests",
        "request_reduction_server_latency_sampled_traffic_pct",
        "request_reduction_server_samples_bid_accuracy",
        "request_reduction_server_samples_bid_cpm",
        "request_reduction_server_samples_bid_precision",
        "request_reduction_server_samples_bid_rate",
        "request_reduction_server_samples_bid_recall",
        "request_reduction_server_samples_bid_revenue",
        "request_reduction_server_samples_bid_specificity",
        "request_reduction_server_samples_bids",
        "request_reduction_server_samples_lost_bids_rate",
        "request_reduction_server_samples_lost_revenue_rate",
        "request_reduction_server_samples_lost_wins_rate",
        "request_reduction_server_samples_missed_revenue",
        "request_reduction_server_samples_predicts",
        "request_reduction_server_samples_reduction_efficiency",
        "request_reduction_server_samples_req_reduction_rate",
        "request_reduction_server_samples_requests",
        "request_reduction_server_samples_rpb",
        "request_reduction_server_samples_win_accuracy",
        "request_reduction_server_samples_win_cpm",
        "request_reduction_server_samples_win_precision",
        "request_reduction_server_samples_win_rate",
        "request_reduction_server_samples_win_recall",
        "request_reduction_server_samples_win_revenue",
        "request_reduction_server_samples_win_specificity",
        "request_reduction_server_samples_wins",
        "revenue",
        "revenue_with_forecast",
        "rpm",
        "rpm_with_forecast",
        "session_rpm",
        "session_rpm_with_forecast",
        "session_start_rpm",
        "session_starts",
        "session_valueRpm",
        "sessions",
        "sessions_with_bounced_clicks",
        "sessions_without_ads_ratio",
        "site_report_click_revenue",
        "site_report_click_revenue_with_forecast",
        "site_report_click_rpm",
        "site_report_click_rpm_with_forecast",
        "site_report_clicks",
        "site_report_cpai",
        "site_report_cpc",
        "site_report_cps",
        "site_report_ffi",
        "site_report_new_sessions",
        "site_report_profit",
        "site_report_profit_with_forecast",
        "site_report_roi",
        "site_report_roi_with_forecast",
        "site_report_session_click_ratio",
        "site_report_sessions",
        "site_report_spent",
        "sivt_rate",
        "spent_by_section_report_click_revenue",
        "spent_by_section_report_click_revenue_with_forecast",
        "spent_by_section_report_click_rpm",
        "spent_by_section_report_click_rpm_with_forecast",
        "spent_by_section_report_clicks",
        "spent_by_section_report_cpai",
        "spent_by_section_report_cpc",
        "spent_by_section_report_cps",
        "spent_by_section_report_ffi",
        "spent_by_section_report_new_sessions",
        "spent_by_section_report_profit",
        "spent_by_section_report_profit_with_forecast",
        "spent_by_section_report_roi",
        "spent_by_section_report_roi_with_forecast",
        "spent_by_section_report_session_click_ratio",
        "spent_by_section_report_sessions",
        "spent_by_section_report_spent",
        "uniqueExternalId",
        "valueCpm",
        "viewability",
        "vitals_cls",
        "vitals_cls_bad_rate",
        "vitals_cls_good_rate",
        "vitals_cls_medium_rate",
        "vitals_fcp",
        "vitals_fcp_bad_rate",
        "vitals_fcp_good_rate",
        "vitals_fcp_medium_rate",
        "vitals_fid",
        "vitals_fid_bad_rate",
        "vitals_fid_good_rate",
        "vitals_fid_medium_rate",
        "vitals_inp",
        "vitals_inp_bad_rate",
        "vitals_inp_good_rate",
        "vitals_inp_medium_rate",
        "vitals_lcp",
        "vitals_lcp_bad_rate",
        "vitals_lcp_good_rate",
        "vitals_lcp_medium_rate",
        "vitals_ttfb",
        "vitals_ttfb_bad_rate",
        "vitals_ttfb_good_rate",
        "vitals_ttfb_medium_rate",
        "won_lost_diff"
    ]);
}
function DecodePrebidMetrics(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "prebid_report_auction_win_rpm",
        "prebid_report_auctions",
        "prebid_report_bidDensity_auction",
        "prebid_report_bidDensity_pageView",
        "prebid_report_bidDensity_session",
        "prebid_report_bidVolume_auction_cpm",
        "prebid_report_bidVolume_pageView_cpm",
        "prebid_report_bidVolume_session_cpm",
        "prebid_report_bid_cpm",
        "prebid_report_bid_position",
        "prebid_report_bid_rate",
        "prebid_report_bids",
        "prebid_report_bids_revenue",
        "prebid_report_floor_cpm",
        "prebid_report_floor_rate",
        "prebid_report_givt_rate",
        "prebid_report_highest_bid_rate",
        "prebid_report_ivt_rate",
        "prebid_report_noBid_rate",
        "prebid_report_noBids",
        "prebid_report_renderedTime",
        "prebid_report_request_cpm",
        "prebid_report_request_win_rate",
        "prebid_report_request_win_rpm",
        "prebid_report_requests",
        "prebid_report_requests_per_auction",
        "prebid_report_setTargetingTime",
        "prebid_report_sivt_rate",
        "prebid_report_timeToRespond",
        "prebid_report_timeout_rate",
        "prebid_report_timeouts",
        "prebid_report_win_cpm",
        "prebid_report_win_rate",
        "prebid_report_wins",
        "prebid_report_wins_revenue"
    ]);
}
function DecodePrebidMetricsType(ctx, input, required = true) {
    return validators.PrebidMetrics(ctx, input, required);
}
function DecodeRequestReductionClientMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "request_reduction_client_bid_cpm",
        "request_reduction_client_bid_volume_loss_ratio",
        "request_reduction_client_bid_volume_total",
        "request_reduction_client_bids",
        "request_reduction_client_left_waste",
        "request_reduction_client_lost_bids",
        "request_reduction_client_lost_bids_ratio",
        "request_reduction_client_lost_wins",
        "request_reduction_client_lost_wins_ratio",
        "request_reduction_client_requests",
        "request_reduction_client_saved_waste",
        "request_reduction_client_saved_waste_ratio",
        "request_reduction_client_untouched_bid_volume",
        "request_reduction_client_untouched_bids",
        "request_reduction_client_waste_ratio",
        "request_reduction_client_wins",
        "request_reduction_client_won_volume_loss_ratio"
    ]);
}
function DecodeRequestReductionServerLatencyMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "request_reduction_server_latency_average_bidder_per_auctions",
        "request_reduction_server_latency_predict_bidders",
        "request_reduction_server_latency_predict_errors",
        "request_reduction_server_latency_predict_latencies_p50",
        "request_reduction_server_latency_predict_latencies_p95",
        "request_reduction_server_latency_predict_latencies_p99",
        "request_reduction_server_latency_predict_requests",
        "request_reduction_server_latency_predict_result_ratio",
        "request_reduction_server_latency_result_errors",
        "request_reduction_server_latency_result_latencies_p50",
        "request_reduction_server_latency_result_latencies_p95",
        "request_reduction_server_latency_result_latencies_p99",
        "request_reduction_server_latency_result_requests",
        "request_reduction_server_latency_sampled_traffic_pct"
    ]);
}
function DecodeRequestReductionServerSamplesPredictMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "request_reduction_server_samples_bid_accuracy",
        "request_reduction_server_samples_bid_cpm",
        "request_reduction_server_samples_bid_precision",
        "request_reduction_server_samples_bid_rate",
        "request_reduction_server_samples_bid_recall",
        "request_reduction_server_samples_bid_revenue",
        "request_reduction_server_samples_bid_specificity",
        "request_reduction_server_samples_bids",
        "request_reduction_server_samples_lost_bids_rate",
        "request_reduction_server_samples_lost_revenue_rate",
        "request_reduction_server_samples_lost_wins_rate",
        "request_reduction_server_samples_missed_revenue",
        "request_reduction_server_samples_predicts",
        "request_reduction_server_samples_reduction_efficiency",
        "request_reduction_server_samples_req_reduction_rate",
        "request_reduction_server_samples_requests",
        "request_reduction_server_samples_rpb",
        "request_reduction_server_samples_win_accuracy",
        "request_reduction_server_samples_win_cpm",
        "request_reduction_server_samples_win_precision",
        "request_reduction_server_samples_win_rate",
        "request_reduction_server_samples_win_recall",
        "request_reduction_server_samples_win_revenue",
        "request_reduction_server_samples_win_specificity",
        "request_reduction_server_samples_wins"
    ]);
}
function DecodeRequestReductionServerSamplesProfileMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "request_reduction_server_samples_bid_cpm",
        "request_reduction_server_samples_bid_rate",
        "request_reduction_server_samples_bid_revenue",
        "request_reduction_server_samples_bids",
        "request_reduction_server_samples_requests",
        "request_reduction_server_samples_win_cpm",
        "request_reduction_server_samples_win_rate",
        "request_reduction_server_samples_win_revenue",
        "request_reduction_server_samples_wins"
    ]);
}
function DecodeSiteReportMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "site_report_click_revenue",
        "site_report_click_revenue_with_forecast",
        "site_report_click_rpm",
        "site_report_click_rpm_with_forecast",
        "site_report_clicks",
        "site_report_cpai",
        "site_report_cpc",
        "site_report_cps",
        "site_report_ffi",
        "site_report_new_sessions",
        "site_report_profit",
        "site_report_profit_with_forecast",
        "site_report_roi",
        "site_report_roi_with_forecast",
        "site_report_session_click_ratio",
        "site_report_sessions",
        "site_report_spent"
    ]);
}
function DecodeSpentBySectionReportMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "spent_by_section_report_click_revenue",
        "spent_by_section_report_click_revenue_with_forecast",
        "spent_by_section_report_click_rpm",
        "spent_by_section_report_click_rpm_with_forecast",
        "spent_by_section_report_clicks",
        "spent_by_section_report_cpai",
        "spent_by_section_report_cpc",
        "spent_by_section_report_cps",
        "spent_by_section_report_ffi",
        "spent_by_section_report_new_sessions",
        "spent_by_section_report_profit",
        "spent_by_section_report_profit_with_forecast",
        "spent_by_section_report_roi",
        "spent_by_section_report_roi_with_forecast",
        "spent_by_section_report_session_click_ratio",
        "spent_by_section_report_sessions",
        "spent_by_section_report_spent"
    ]);
}
function DecodeWebMetrics(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "acquisition_cost",
        "acquisition_cost_measurable_session_starts",
        "active_pageView_time_minutes",
        "active_session_time_minutes",
        "ads_per_pageView",
        "ads_per_session",
        "avg_decoded_body_size",
        "avg_dom_completion_time",
        "avg_dom_processing_time",
        "avg_encoded_body_size",
        "avg_network_time",
        "avg_on_load_time",
        "avg_page_load_time",
        "avg_server_time",
        "avg_transfer_size",
        "avg_transfer_time",
        "bounceRate",
        "errors",
        "errors_per_pageView",
        "errors_per_session",
        "mean_scrollY",
        "pageView_starts",
        "pageViews",
        "pageViews_per_session",
        "pageViews_without_ads_ratio",
        "session_starts",
        "sessions",
        "sessions_without_ads_ratio",
        "vitals_cls",
        "vitals_cls_bad_rate",
        "vitals_cls_good_rate",
        "vitals_cls_medium_rate",
        "vitals_fcp",
        "vitals_fcp_bad_rate",
        "vitals_fcp_good_rate",
        "vitals_fcp_medium_rate",
        "vitals_fid",
        "vitals_fid_bad_rate",
        "vitals_fid_good_rate",
        "vitals_fid_medium_rate",
        "vitals_inp",
        "vitals_inp_bad_rate",
        "vitals_inp_good_rate",
        "vitals_inp_medium_rate",
        "vitals_lcp",
        "vitals_lcp_bad_rate",
        "vitals_lcp_good_rate",
        "vitals_lcp_medium_rate",
        "vitals_ttfb",
        "vitals_ttfb_bad_rate",
        "vitals_ttfb_good_rate",
        "vitals_ttfb_medium_rate"
    ]);
}
function DecodeYieldMetricsType(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "acquisition_cost",
        "acquisition_cost_measurable_session_starts",
        "adSense_impressions",
        "adSense_pageView_rpm",
        "adSense_revenue",
        "adSense_rpm",
        "adSense_session_rpm",
        "adSense_winRate",
        "ad_unfilled",
        "ads_per_pageView",
        "ads_per_pageViewExact",
        "ads_per_session",
        "aps_impressions",
        "aps_pageView_rpm",
        "aps_revenue",
        "aps_rpm",
        "aps_session_rpm",
        "aps_winRate",
        "ayMediation_impressions",
        "ayMediation_pageView_rpm",
        "ayMediation_revenue",
        "ayMediation_rpm",
        "ayMediation_session_rpm",
        "ayMediation_winRate",
        "bias",
        "blocked_impressions",
        "blocked_impressions_rate",
        "cbr",
        "clicks",
        "clicks_bounce_session_rate",
        "clr",
        "content_recommendation_impressions",
        "content_recommendation_pageView_rpm",
        "content_recommendation_revenue",
        "content_recommendation_rpm",
        "content_recommendation_session_rpm",
        "content_recommendation_winRate",
        "ctr",
        "diff",
        "direct_impressions",
        "direct_pageView_rpm",
        "direct_pageView_valueRpm",
        "direct_revenue",
        "direct_rpm",
        "direct_session_rpm",
        "direct_session_valueRpm",
        "direct_valueRpm",
        "direct_winRate",
        "dynamicAllocation_confirmed_clicks_rate",
        "dynamicAllocation_impressions",
        "dynamicAllocation_pageViewExact_rpm",
        "dynamicAllocation_pageView_rpm",
        "dynamicAllocation_pageView_valueRpm",
        "dynamicAllocation_predicted_impressions",
        "dynamicAllocation_predicted_impressions_error",
        "dynamicAllocation_predicted_revenue",
        "dynamicAllocation_predicted_revenue_error",
        "dynamicAllocation_predicted_revenue_error_server",
        "dynamicAllocation_predicted_revenue_mae",
        "dynamicAllocation_predicted_revenue_mae_server",
        "dynamicAllocation_predicted_revenue_r2",
        "dynamicAllocation_predicted_revenue_r2_server",
        "dynamicAllocation_predicted_revenue_relative_error",
        "dynamicAllocation_predicted_revenue_relative_error_server",
        "dynamicAllocation_predicted_revenue_rmse",
        "dynamicAllocation_predicted_revenue_rmse_server",
        "dynamicAllocation_predicted_revenue_server",
        "dynamicAllocation_predicted_rpm",
        "dynamicAllocation_predicted_rpm_server",
        "dynamicAllocation_revenue",
        "dynamicAllocation_revenue_with_forecast",
        "dynamicAllocation_revenue_with_forecast_client",
        "dynamicAllocation_rpm",
        "dynamicAllocation_session_rpm",
        "dynamicAllocation_session_rpm_with_forecast",
        "dynamicAllocation_session_valueRpm",
        "dynamicAllocation_valueRpm",
        "dynamicAllocation_winRate",
        "floor_empirical_revenue_ratio",
        "floor_impressions",
        "floor_rpm",
        "givt_rate",
        "impressions",
        "impressions_filled",
        "impressions_filled_rate",
        "impressions_unfilled",
        "impressions_unfilled_n_house",
        "impressions_unfilled_n_house_rate",
        "impressions_unfilled_rate",
        "ivt_rate",
        "lineItem_revenue",
        "lost_rpm_hb",
        "min_cpm",
        "miss_click_impression_pct",
        "nativo_impressions",
        "nativo_pageView_rpm",
        "nativo_revenue",
        "nativo_rpm",
        "nativo_session_rpm",
        "nativo_winRate",
        "pageView_rpm",
        "pageView_starts",
        "pageView_valueRpm",
        "pageViews",
        "pageViewsExact",
        "pageViewsExact_per_session",
        "pageViews_per_session",
        "prebid_cache_rate",
        "prebid_incremental_value",
        "prebid_incremental_value_rpm",
        "prebid_lost_impressions",
        "prebid_lost_pageView_rpm",
        "prebid_lost_pageView_valueRpm",
        "prebid_lost_revenue",
        "prebid_lost_rpm",
        "prebid_lost_session_rpm",
        "prebid_lost_session_valueRpm",
        "prebid_lost_valueRpm",
        "prebid_pageView_rpm",
        "prebid_pageView_valueRpm",
        "prebid_revenue",
        "prebid_rpm",
        "prebid_session_rpm",
        "prebid_session_valueRpm",
        "prebid_user_id_providers_avg",
        "prebid_user_id_providers_zero_usages",
        "prebid_user_id_providers_zero_usages_rate",
        "prebid_valueRpm",
        "prebid_winRate",
        "prebid_won_impressions",
        "prebid_won_pageViewExact_rpm",
        "prebid_won_pageView_rpm",
        "prebid_won_pageView_valueRpm",
        "prebid_won_revenue",
        "prebid_won_rpm",
        "prebid_won_session_rpm",
        "prebid_won_session_valueRpm",
        "prebid_won_valueRpm",
        "programmatic_impressions",
        "programmatic_pageView_rpm",
        "programmatic_pageView_valueRpm",
        "programmatic_revenue",
        "programmatic_revenue_with_forecast",
        "programmatic_rpm",
        "programmatic_session_rpm",
        "programmatic_session_rpm_with_forecast",
        "programmatic_session_valueRpm",
        "programmatic_valueRpm",
        "programmatic_winRate",
        "revenue",
        "revenue_with_forecast",
        "rpm",
        "rpm_with_forecast",
        "session_rpm",
        "session_rpm_with_forecast",
        "session_start_rpm",
        "session_starts",
        "session_valueRpm",
        "sessions",
        "sessions_with_bounced_clicks",
        "sivt_rate",
        "uniqueExternalId",
        "valueCpm",
        "viewability",
        "vitals_cls",
        "vitals_cls_bad_rate",
        "vitals_cls_good_rate",
        "vitals_cls_medium_rate",
        "vitals_fcp",
        "vitals_fcp_bad_rate",
        "vitals_fcp_good_rate",
        "vitals_fcp_medium_rate",
        "vitals_fid",
        "vitals_fid_bad_rate",
        "vitals_fid_good_rate",
        "vitals_fid_medium_rate",
        "vitals_inp",
        "vitals_inp_bad_rate",
        "vitals_inp_good_rate",
        "vitals_inp_medium_rate",
        "vitals_lcp",
        "vitals_lcp_bad_rate",
        "vitals_lcp_good_rate",
        "vitals_lcp_medium_rate",
        "vitals_ttfb",
        "vitals_ttfb_bad_rate",
        "vitals_ttfb_good_rate",
        "vitals_ttfb_medium_rate",
        "won_lost_diff"
    ]);
}
function DecodeRawWebMetrics(ctx, input, required = true) {
    return decodeAnyOfConsts(ctx, input, required, [
        "acquisition_cost",
        "acquisition_cost_measurable_session_starts",
        "count_event_end",
        "count_low_engagement",
        "errors",
        "impressions",
        "max_timestamp",
        "min_timestamp",
        "nav_decoded_body_size",
        "nav_dom_content_loading_time",
        "nav_dom_processing_time",
        "nav_domain_lookup_time",
        "nav_encoded_body_size",
        "nav_network_time",
        "nav_server_time",
        "nav_transfer_size",
        "nav_transfer_time",
        "nav_unload_event_time",
        "pageView_starts",
        "pageView_with_ads",
        "pageViews",
        "pageViews_ends",
        "scrollY",
        "session_starts",
        "session_with_ads",
        "sessions",
        "sessions_ends",
        "sum_pageView_activeEngagementTime",
        "vitals_cls_bad",
        "vitals_cls_good",
        "vitals_cls_measurable",
        "vitals_cls_medium",
        "vitals_cls_sum",
        "vitals_fcp_bad",
        "vitals_fcp_good",
        "vitals_fcp_measurable",
        "vitals_fcp_medium",
        "vitals_fcp_sum",
        "vitals_fid_bad",
        "vitals_fid_good",
        "vitals_fid_measurable",
        "vitals_fid_medium",
        "vitals_fid_sum",
        "vitals_inp_bad",
        "vitals_inp_good",
        "vitals_inp_measurable",
        "vitals_inp_medium",
        "vitals_inp_sum",
        "vitals_lcp_bad",
        "vitals_lcp_good",
        "vitals_lcp_measurable",
        "vitals_lcp_medium",
        "vitals_lcp_sum",
        "vitals_ttfb_bad",
        "vitals_ttfb_good",
        "vitals_ttfb_measurable",
        "vitals_ttfb_medium",
        "vitals_ttfb_sum"
    ]);
}
function DecodeValidCurrency(ctx, input, required = true) {
    return decodeStringWithFormat(ctx, input, required, "ValidCurrency");
}
function DecodeValidTimezone(ctx, input, required = true) {
    return decodeStringWithFormat(ctx, input, required, "ValidTimezone");
}
const validators = {
    RangeKeys: DecodeRangeKeys,
    CustomAnnotationOwnershipType: DecodeCustomAnnotationOwnershipType,
    DimensionKeys: DecodeDimensionKeys,
    ReportPlacementKeyType: DecodeReportPlacementKeyType,
    ReportPlacementKeyTypeInput: DecodeReportPlacementKeyTypeInput,
    BasePlacementKeyType: DecodeBasePlacementKeyType,
    KvTag: DecodeKvTag,
    KvTimeFrame: DecodeKvTimeFrame,
    KvOptimizationTarget: DecodeKvOptimizationTarget,
    ValidTimeGranularityExceptNo: DecodeValidTimeGranularityExceptNo,
    TimeGranularity: DecodeTimeGranularity,
    DiscrepancyRevenueReportRawMetrics: DecodeDiscrepancyRevenueReportRawMetrics,
    RawMetricsType: DecodeRawMetricsType,
    RawPrebidMetrics: DecodeRawPrebidMetrics,
    RawRequestReductionServerSamplesMetrics: DecodeRawRequestReductionServerSamplesMetrics,
    RawYieldMetrics: DecodeRawYieldMetrics,
    AddRevenueIndexMetricsType: DecodeAddRevenueIndexMetricsType,
    BuyReportMetricsType: DecodeBuyReportMetricsType,
    DiscrepancyRevenueReportMetricsType: DecodeDiscrepancyRevenueReportMetricsType,
    ErrorAnalyticsMetrics: DecodeErrorAnalyticsMetrics,
    FinancialReportMetricsType: DecodeFinancialReportMetricsType,
    IndustryWideDataMetricsType: DecodeIndustryWideDataMetricsType,
    MainMetricsType: DecodeMainMetricsType,
    PrebidMetrics: DecodePrebidMetrics,
    PrebidMetricsType: DecodePrebidMetricsType,
    RequestReductionClientMetricsType: DecodeRequestReductionClientMetricsType,
    RequestReductionServerLatencyMetricsType: DecodeRequestReductionServerLatencyMetricsType,
    RequestReductionServerSamplesPredictMetricsType: DecodeRequestReductionServerSamplesPredictMetricsType,
    RequestReductionServerSamplesProfileMetricsType: DecodeRequestReductionServerSamplesProfileMetricsType,
    SiteReportMetricsType: DecodeSiteReportMetricsType,
    SpentBySectionReportMetricsType: DecodeSpentBySectionReportMetricsType,
    WebMetrics: DecodeWebMetrics,
    YieldMetricsType: DecodeYieldMetricsType,
    RawWebMetrics: DecodeRawWebMetrics,
    ValidCurrency: DecodeValidCurrency,
    ValidTimezone: DecodeValidTimezone
};

export default { decodeObject, decodeArray, decodeString, decodeNumber, decodeCodec, decodeStringWithFormat, decodeAnyOf, decodeAllOf, decodeBoolean, decodeAny, decodeTuple, decodeNull, decodeConst, registerCustomFormatter, validators };