import { useEffect, useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import fifthLineMobile from "./images/fifth-mobile-line.svg";
import firstLineMobile from "./images/first-mobile-line.svg";
import fourthLineMobile from "./images/fourth-mobile-line.svg";
import secondLine from "./images/second-line.svg";
import seventhLine from "./images/seventh-line.svg";
import sixthLineMobile from "./images/sixth-mobile-line.svg";
import thirdLine from "./images/third-line.svg";
import timeLineMask from "./images/timeline-mask.svg";
import timeLineMaskMobile from "./images/timeline-mask-mobile.svg";
import * as styles from "./styles.module.scss";

const inputs = [] as const;

const AboutUsHistorySpiral = () => {
    const fadeRefs = useRef<HTMLDivElement[]>([]);
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 992);
    };

    useEffect(() => {
        handleResize();
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        fadeRefs.current.forEach(fadeRef => {
            observer.observe(fadeRef);
        });

        if (isVisible) {
            fadeRefs.current.forEach((fadeRef, index) => {
                setTimeout(
                    () => {
                        fadeRef.classList.add("show");
                    },
                    (index + 1) * 1000
                );
            });
        }
    }, [fadeRefs, isVisible, isMobile]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    return (
        <div className={styles.mainWrapper}>
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/about-us-history-spiral-first-glow.svg" alt="" />
            </div>
            <div className={styles.spiral}>
                {!isMobile && (
                    <>
                        <div
                            ref={(el: HTMLDivElement) => (fadeRefs.current[0] = el)}
                            className={`${styles.description} ${styles.firstPosition} fade`}
                        >
                            <div className="d-none d-lg-block">
                                <img loading="lazy" src={thirdLine} alt="" />
                            </div>
                            <h5 className={styles.descriptionTitle}>2012-2016</h5>
                            <p className={styles.descriptionText}>
                                The journey of Nils Lind in the gaming industry takes flight as a publisher. The Digital
                                Publishing Company is established, fostering a social network with a forum-style
                                structure that spans over ten communities, including one of the largest Minecraft forums
                                worldwide.
                            </p>
                        </div>

                        <div
                            ref={(el: HTMLDivElement) => (fadeRefs.current[1] = el)}
                            className={`${styles.description} ${styles.secondPosition} fade`}
                        >
                            <p className={styles.descriptionText}>
                                The inception of Assertive Yield paves the way for innovative Yield Analytics. Our first
                                employee joins the company, contributing to developing state-of-the-art AI products such
                                as Revenue Forecasting, Bid Request Optimization, and Dynamic Flooring.
                            </p>
                            <h5 className={styles.descriptionTitle}>2019-2020</h5>
                            <div className="d-none d-lg-block">
                                <img loading="lazy" src={secondLine} alt="" />
                            </div>
                        </div>

                        <div
                            ref={(el: HTMLDivElement) => (fadeRefs.current[2] = el)}
                            className={`${styles.description} ${styles.thirdPosition} fade`}
                        >
                            <div className="d-none d-lg-block">
                                <img loading="lazy" src={thirdLine} alt="" />
                            </div>
                            <h5 className={styles.descriptionTitle}>2021-2022</h5>
                            <p className={styles.descriptionText}>
                                Dashboards, Video Integrations, Traffic Acquisitions, and Traffic Shaping are
                                introduced; achieving high availability through multi-region/country. Revenue
                                skyrockets, tripling in size; This period also sees the introduction of Content
                                Recommendation Integrations and the game-changer Yield Manager.
                            </p>
                        </div>

                        <div
                            ref={(el: HTMLDivElement) => (fadeRefs.current[3] = el)}
                            className={`${styles.description} ${styles.fourthPosition} fade`}
                        >
                            <p className={styles.descriptionText}>
                                Our customer base and portfolio experiences a dynamic growth phase, as well as as well
                                as our team, expanding from 11 to 30 highly skilled seniors, experts, analysts, and
                                partners.
                            </p>
                            <h5 className={styles.descriptionTitle}>2023</h5>
                            <div className="d-none d-lg-block">
                                <img loading="lazy" src={seventhLine} alt="" />
                            </div>
                        </div>
                    </>
                )}

                {isMobile && (
                    <>
                        <div
                            ref={(el: HTMLDivElement) => (fadeRefs.current[0] = el)}
                            className={`${styles.description} ${styles.firstPosition} fade`}
                        >
                            <div className={[styles.mobileLine, styles.mobileLineFirst].join(" ")}>
                                <img loading="lazy" src={firstLineMobile} alt="" />
                            </div>
                            <div className={styles.mobileContentWrapper}>
                                <h5 className={styles.descriptionTitle}>2012-2016</h5>
                                <p className={styles.descriptionText}>
                                    The journey of Nils Lind in the gaming industry takes flight as a publisher. The
                                    Digital Publishing Company is established, fostering a social network with a
                                    forum-style structure that spans over ten communities, including one of the largest
                                    Minecraft forums worldwide.
                                </p>
                            </div>
                        </div>

                        <div
                            ref={(el: HTMLDivElement) => (fadeRefs.current[1] = el)}
                            className={`${styles.description} ${styles.secondPosition} fade`}
                        >
                            <div className={styles.mobileLine}>
                                <img loading="lazy" src={fourthLineMobile} alt="" />
                            </div>
                            <div className={styles.mobileContentWrapper}>
                                <h5 className={styles.descriptionTitle}>2019-2020</h5>
                                <p className={styles.descriptionText}>
                                    The inception of Assertive Yield paves the way for innovative Yield Analytics. Our
                                    first employee joins the company, contributing to developing state-of-the-art AI
                                    products such as Revenue Forecasting, Bid Request Optimization, and Dynamic
                                    Flooring.
                                </p>
                            </div>
                        </div>

                        <div
                            ref={(el: HTMLDivElement) => (fadeRefs.current[2] = el)}
                            className={`${styles.description} ${styles.thirdPosition} fade`}
                        >
                            <div className={styles.mobileLine}>
                                <img loading="lazy" src={fifthLineMobile} alt="" />
                            </div>
                            <div className={styles.mobileContentWrapper}>
                                <h5 className={styles.descriptionTitle}>2021-2022</h5>
                                <p className={styles.descriptionText}>
                                    Dashboards, Video Integrations, Traffic Acquisitions, and Traffic Shaping are
                                    introduced; achieving high availability through multi-region/country. Revenue
                                    skyrockets, tripling in size; This period also sees the introduction of Content
                                    Recommendation Integrations and the game-changer Yield Manager.
                                </p>
                            </div>
                        </div>

                        <div
                            ref={(el: HTMLDivElement) => (fadeRefs.current[3] = el)}
                            className={`${styles.description} ${styles.fourthPosition} fade`}
                        >
                            <div className={styles.mobileLine}>
                                <img loading="lazy" src={sixthLineMobile} alt="" />
                            </div>
                            <div className={styles.mobileContentWrapper}>
                                <h5 className={styles.descriptionTitle}>2023</h5>
                                <p className={styles.descriptionText}>
                                    Our customer base and portfolio experiences a dynamic growth phase, as well as as
                                    well as our team, expanding from 11 to 30 highly skilled seniors, experts, analysts,
                                    and partners.
                                </p>
                            </div>
                        </div>
                    </>
                )}

                <div className={`${styles.spiral} d-none d-lg-block`}>
                    <img loading="lazy" className={styles.spiralImage} src={timeLineMask} alt="" />
                </div>

                <div className={`${styles.mobileSpiral} d-block d-lg-none`}>
                    <img loading="lazy" src={timeLineMaskMobile} alt="" />
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AboutUsHistorySpiral, {
    name: "aboutUsHistorySpiral",
    inputs: toMutable(inputs),
});
