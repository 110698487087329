import { type AggOpNames, CalculationType, OptimizationObjective } from "../../calculated-metrics.types-input";
import { IndustryMetricMap, type RawIndustryMetrics } from "../calculated-metrics.types";

const industryCorrectionForIncompletePresence = (
    m: Record<"ind_fair_share_win_rate" | "ind_fair_share_revenue" | "ind_presence" | RawIndustryMetrics, number>,
    opname: AggOpNames
) => {
    if (opname === "groupTotal") {
        return m.avg_present_in_days_frac;
    }
    return 1;
};

export const calculatedIndustryMetrics = IndustryMetricMap({
    // end da server prediction
    // for industry panel
    ind_fair_share_win_rate: {
        label: "Normalized Win Rate",
        explanation: "Win Rate normalized by the amount of competition it had.",
        formula: (m, opname) =>
            (m.prebid_won_impressions / m.fair_available_impressions) *
            industryCorrectionForIncompletePresence(m, opname),
        calculationType: CalculationType.SpecialRate,
        objective: OptimizationObjective.NoObjective,
    },
    ind_fair_share_revenue: {
        label: "Relative Revenue",
        explanation: "Revenue from this bidder relative to the total prebid revenue.",
        formula: (m, opname) =>
            (m.prebid_won_revenue / 1_000 / m.fair_total_prebid_revenue) *
            industryCorrectionForIncompletePresence(m, opname) *
            1000 *
            1000 *
            1000,
        calculationType: CalculationType.SpecialRate,
        objective: OptimizationObjective.NoObjective,
    },
    ind_presence: {
        label: "Presence",
        explanation: "Average number of sites working with this category",
        formula: (m, opname) => (m.presence / m.entities_count) * industryCorrectionForIncompletePresence(m, opname),
        calculationType: CalculationType.SpecialRate,
        objective: OptimizationObjective.NoObjective,
    },
});
