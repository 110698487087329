export function cleanUpRouteString(route: string) {
    // Remove numbers
    const numbersRemoved = route.replace(/^\d+(\.\d+)?/, "");

    // Replace "&" with a single hyphen
    const ampersandReplaced = numbersRemoved.replace(/&/g, "-");

    // Remove any non-alphanumeric characters, except for hyphens, spaces, and slashes
    const cleanedRoute = ampersandReplaced.replace(/[^a-zA-Z0-9-/\s]/g, "");

    // Replace spaces with hyphens
    const hyphenatedSpaces = cleanedRoute.replace(/\s+/g, "-");

    // Replace single slash with double hyphens
    const hyphenatedRoute = hyphenatedSpaces.replace(/\/+/g, "--");

    // Remove any leading or trailing hyphens
    const trimmedRoute = hyphenatedRoute.replace(/^-+|-+$/g, "");

    // Remove any digits followed by a period at the beginning of the string
    const finalRoute = trimmedRoute.replace(/^\d+\./, "");

    // Replace four consecutive hyphens with two hyphens
    const correctedRoute = finalRoute.replace(/-{4}/g, "--");

    return correctedRoute.toLowerCase();
}
