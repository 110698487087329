import { absurd } from "fp-ts/lib/function";
//@ts-ignore
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import * as STD from "../utils/std";
import type { DimensionKeys } from "./dimension-keys.constants";
import type { MainMetricsType } from "./metrics.constants";
import type { ReportPlacementKeyType } from "./placement.constants";

export const warningBannerClass = "warning-banners";
export const warningBannerClassSelector = ".warning-banners";
export const appHeaderId = "app-header";
export const appHeaderClassSelector = "#" + appHeaderId;

export const DASHBOARD_TAG_NAME_MAX_LENGTH = 25;

export const defaultLastNDays = 7;

export const dfpAPIVersion = "v202402";
export const bullDfpAPIVersion = "v202402";

export const level = {
    "-1": "undefined",
    undefined: -1,
    info: 1,
    1: "Info",
    warning: 5,
    5: "Warning",
    critical: 9,
    9: "Critical",
};

/**
 * A list of zulu timezones
 */
export const zuluTimezones = [
    "UTC",
    "Zulu",
    "GMT",
    "GMT0",
    "GMT-0",
    "GMT+0",
    "Etc/UTC",
    "Etc/Zulu",
    "Etc/GMT",
    "Etc/GMT0",
    "Etc/GMT-0",
    "Etc/GMT+0",
];
export const ALL_DIMENSION_UI_GROUP_IN_ORDER = [
    "demand",
    "ad_server",
    "implementation",
    "custom",
    "user",
    "source",
    "content",
    "mapped",
    "time_based",
    "yield_manager",
    "specialized",
    "none",
] as const;
export type DimensionUiGroup = (typeof ALL_DIMENSION_UI_GROUP_IN_ORDER)[number];

export const DimensionUiGroupLabels: Record<DimensionUiGroup, string> = {
    none: "Other",
    demand: "Demand",
    ad_server: "Ad Server",
    implementation: "Implementation",
    custom: "Custom",
    user: "User",
    source: "Source",
    content: "Content",
    mapped: "Mapped",
    time_based: "Time Based",
    yield_manager: "Yield Manager",
    specialized: "Specialized",
};

export type IDimension = {
    id: DimensionKeys;
    label: string;
    description?: string;
    field?: string;
    where?: string;
    wherePrebid?: string;
    fieldPrebid?: string;
    type: string;
    row_type: "String" | "FixedString(2)" | "Number" | "Enum";
    analytics_reports: (
        | "yield"
        | "prebid"
        | "web"
        | "error_analytics"
        | "yield_api"
        | "web_api"
        | "request_reduction_client_v2"
    )[];
    shouldLimit?: boolean;
    enumOptions?: readonly string[];
    isMultiAttribution?: boolean;
    hideFromApiDocs?: boolean;
    isTombstone?: boolean;
    uiGroup: DimensionUiGroup;

    limitedToAdminOrDev?: boolean;
};
const mappingYieldDfp = ({
    fieldValue,
    fieldName,
    field,
}: {
    fieldValue: string;
    fieldName: string;

    field: (str: string) => string;
}) => {
    const ifUnknown = `concat('no mapping (', toString(toString(${fieldValue})), ')')`;
    const value = field(ifUnknown);
    return `dictGetStringOrDefault('dimensionMappings_dict', 'to', tuple(entityId, '${fieldName}', assumeNotNull(toString(${fieldValue})) ), ${value})`;
};

const mappingDimensions = (field: string, fieldValue = field) => {
    const stringNotNullField = `if(isNull(${fieldValue}), '', toString(${fieldValue}))`;
    const noMappingField = `if(${stringNotNullField} = '', '_undefined', ${stringNotNullField})`;
    return `dictGetStringOrDefault('dimensionMappings_dict', 'to', tuple(entityId, '${field}', assumeNotNull(${fieldValue}) ), concat('no mapping (', ${noMappingField}, ')'))`;
};

export type DimensionMap = Record<DimensionKeys, IDimension>;

export const trafficShapingMapping = (fieldValue: string, customerField = "customer") =>
    `concat(dictGetStringOrDefault('trafficShapingMappings_dict', 'to', tuple(${customerField}, 'bidder_id', ${fieldValue} ), 'Unknown'), ' (', ${fieldValue}, ')')`;

export type DimensionUnit = DimensionMap[DimensionKeys];
const REVENUE_SOURCE_OPTIONS = [
    "prebid",
    "dynamicAllocation",
    "lineitem",
    "video",
    "nativo",
    "taboola",
    "outbrain",
    "yahooGemini",
    "adSense",
    "aps",
    "ayMediation_cpa",
] as const;

const PREBID_SOURCE_OPTIONS = ["unknown", "client", "server"] as const;

const revenueLabels: Record<(typeof REVENUE_SOURCE_OPTIONS)[number], string> = {
    prebid: "Prebid",
    dynamicAllocation: "GAM - Dynamic Allocation",
    lineitem: "GAM - LineItem",
    video: "Video",
    nativo: "Nativo",
    taboola: "Taboola",
    outbrain: "Outbrain",
    yahooGemini: "Yahoo Gemini",
    adSense: "AdSense",
    aps: "Amazon",
    ayMediation_cpa: "Ay Mediation CPA",
};
const winningBidderMultiIfNoPrebid = STD.getEntries(revenueLabels)
    .filter(([key, _value]) => key !== "prebid")
    .map(([key, value]) => `source = '${key}', '${value}'`)
    .join(", ");
const createDimensions = (it: Record<DimensionKeys, Omit<IDimension, "id">>): DimensionMap =>
    STD.getEntries(it).reduce((acc, [key, value]) => {
        acc[key] = {
            id: key,
            ...value,
        };
        return acc;
    }, {} as DimensionMap);

const confusionMatrix = (field: string): string => {
    const pred = `(predicted > __threshold)`;
    return `multiIf(
        ${pred} AND ${field}, 'True Positive',
        NOT ${pred} AND NOT ${field}, 'True Negative',
        ${pred} AND NOT ${field}, 'False Positive',
        NOT ${pred} AND ${field}, 'False Negative',
        'No class'
    )`;
};

export const dimensions: DimensionMap = createDimensions({
    unified: {
        label: "unified",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    date: {
        label: "date",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_bid_confusion_matrix: {
        label: "Bid Confusion Matrix",
        type: "select",
        row_type: "String",
        field: confusionMatrix("did_bid"),
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_win_confusion_matrix: {
        label: "Win Confusion Matrix",
        type: "select",
        row_type: "String",
        field: confusionMatrix("did_win"),
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_threshold: {
        label: "Threshold",
        type: "select",
        row_type: "Number",
        field: "__threshold",
        analytics_reports: [],
        uiGroup: "none",
        isMultiAttribution: true,
    },
    request_reduction_server_samples_bidder: {
        label: "Bidder",
        type: "select",
        row_type: "String",
        field: "bidder_id",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_datacenter: {
        label: "Data Center",
        type: "select",
        row_type: "String",
        field: "datacenter",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_bidder_mapped: {
        label: "Bidder (mapped)",
        type: "select",
        row_type: "String",
        field: trafficShapingMapping(`assumeNotNull(toString(bidder_id))`),
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_country: {
        label: "Country",
        type: "select",
        row_type: "String",
        field: "country",
        analytics_reports: [],
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_publisher_id: {
        label: "Publisher ID",
        type: "select",
        row_type: "String",
        field: "publisher_id",
        analytics_reports: [],
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_placement_id: {
        label: "Placement ID",
        type: "select",
        row_type: "String",
        field: "placement_id",
        analytics_reports: [],
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_request_type: {
        label: "Request Type",
        type: "select",
        row_type: "String",
        field: "request_type",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_format: {
        label: "Format",
        type: "select",
        row_type: "String",
        field: "format",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_sizes: {
        label: "Sizes",
        type: "select",
        row_type: "String",
        field: "toString(sizes)",
        analytics_reports: [],
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_platform: {
        label: "Platform",
        type: "select",
        row_type: "String",
        field: "platform",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_bundle: {
        label: "Bundle",
        type: "select",
        row_type: "String",
        field: "bundle",
        analytics_reports: [],
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_os: {
        label: "Operating System",
        type: "select",
        row_type: "String",
        field: "os",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_browser: {
        label: "Browser",
        type: "select",
        row_type: "String",
        field: "browser",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_device: {
        label: "Device",
        type: "select",
        row_type: "String",
        field: "device",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_codebase_version: {
        label: "ML Codebase Version",
        type: "select",
        row_type: "String",
        field: "model_version",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_version: {
        label: "Version",
        type: "select",
        row_type: "String",
        field: "version",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_device_language: {
        label: "Device Language",
        type: "select",
        row_type: "String",
        field: "device_language",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_utc_offset: {
        label: "UTC Offset",
        type: "select",
        row_type: "Number",
        field: "utc_offset",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_gdpr: {
        label: "GDPR",
        type: "select",
        row_type: "Number",
        field: "gdpr",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_us_privacy: {
        label: "US Privacy",
        type: "select",
        row_type: "String",
        field: "us_privacy",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_video_startdelay: {
        label: "Video Start Delay",
        type: "select",
        row_type: "Number",
        field: "video_startdelay",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_video_skip: {
        label: "Video Skip",
        type: "select",
        row_type: "Number",
        field: "video_skip",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_video_rewarded: {
        label: "Video Rewarded",
        type: "select",
        row_type: "Number",
        field: "video_rewarded",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_url: {
        label: "URL",
        type: "select",
        row_type: "String",
        field: "url",
        analytics_reports: [],
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_domain: {
        label: "Domain",
        type: "select",
        row_type: "String",
        field: "domain(url)",
        analytics_reports: [],
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_custom1: {
        label: "Custom 1",
        type: "select",
        row_type: "String",
        field: "custom_1",
        analytics_reports: [],
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_custom2: {
        label: "Custom 2",
        type: "select",
        row_type: "String",
        field: "custom_2",
        analytics_reports: [],
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_custom3: {
        label: "Custom 3",
        type: "select",
        row_type: "String",
        field: "custom_3",
        analytics_reports: [],
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_video_playback_method: {
        label: "Video Playback Method",
        type: "select",
        row_type: "String",
        field: "toString(video_playback_method)",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_site_cat: {
        label: "Size Category",
        type: "select",
        row_type: "String",
        field: "toString(site_cat)",
        analytics_reports: [],
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_unit_types: {
        label: "Unit Types",
        type: "select",
        row_type: "String",
        field: "toString(unit_types)",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_page_cat: {
        label: "Page Category",
        type: "select",
        row_type: "String",
        field: "toString(page_cat)",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_has_gps: {
        label: "Has GPS",
        type: "select",
        row_type: "Number",
        field: "has_gps",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_has_omsdk: {
        label: "Has OMSDK",
        type: "select",
        row_type: "Number",
        field: "has_omsdk",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_has_age: {
        label: "Has Age",
        type: "select",
        row_type: "Number",
        field: "has_age",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_has_gender: {
        label: "Has Gender",
        type: "select",
        row_type: "Number",
        field: "has_gender",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_has_mraid: {
        label: "Has MRaid",
        type: "select",
        row_type: "Number",
        field: "has_mraid",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_has_vast: {
        label: "Has Vast",
        type: "select",
        row_type: "Number",
        field: "has_vast",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_has_vpaid: {
        label: "Has VPaid",
        type: "select",
        row_type: "Number",
        field: "has_vpaid",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_has_consent: {
        label: "Has Consent",
        type: "select",
        row_type: "Number",
        field: "has_consent",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_samples_is_qps: {
        label: "Is QPS",
        type: "select",
        row_type: "Number",
        field: "is_qps",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_datacenter: {
        label: "Data Center",
        type: "select",
        row_type: "String",
        field: "datacenter",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_server_node: {
        label: "Node",
        type: "select",
        row_type: "String",
        field: "deploy_id",
        analytics_reports: [],
        uiGroup: "none",
    },
    acquisition_clickId: {
        label: "Acquisition ClickId",
        field: "acquisition_clickId",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield_api"],
        uiGroup: "none",
    },
    acquisition_clickId_param: {
        label: "Acquisition ClickId Param",
        field: "acquisition_clickId_param",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    preBidWon: {
        label: "Won",
        field: "prebid_won",
        fieldPrebid: "ifNull(rendered=1, 0)",
        type: "select",
        row_type: "Number",
        analytics_reports: ["yield", "prebid"],
        hideFromApiDocs: true,
        uiGroup: "none",
    },

    // Demand
    bidder_prebid: {
        description: "The exchange/SSP partner.",
        label: "Bidder",
        fieldPrebid: "bidder",
        type: "select",
        row_type: "String",
        analytics_reports: ["prebid"],
        uiGroup: "demand",
    },
    bidder: {
        description: "The winning/rendered bidder.",

        label: "Winning Bidder",
        field: "multiIf(source = 'prebid', prebid_highestBidPartner, source = 'dynamicAllocation', 'GAM - Dynamic Allocation', 'GAM - Other')",
        fieldPrebid: "if(ifNull(rendered, 0) = 1, bidder, 'Request did not result in Impression')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        isTombstone: true, // moved to winning_bidder
        uiGroup: "demand",
    },
    winning_bidder: {
        description: "The winning/rendered bidder.",
        label: "Winning Bidder",
        field: `multiIf(ad_unfilled = 1, 'Unfilled', source = 'prebid', prebid_highestBidPartner, ${winningBidderMultiIfNoPrebid}, 'Other')`,
        fieldPrebid: "if(ifNull(rendered, 0) = 1, bidder, 'Request did not result in Impression')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "demand",
    },

    highest_bidder: {
        description: "The highest bidder from prebid for the given placement.",

        label: "Highest Bidder",
        field: "prebid_highestBidPartner",
        fieldPrebid: "if(bidPosition = 0, bidder, '')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "demand",
    },
    dsp: {
        label: "DSP",
        field: "dictGetStringOrDefault('dsp', 'name', tuple(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(prebid_highestBidPartner), prebid_highestBidPartner)), ad_buyerId), concat(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(prebid_highestBidPartner), prebid_highestBidPartner)), ' - ', toString(ad_buyerId)))",
        type: "select",
        fieldPrebid:
            "dictGetStringOrDefault('dsp', 'name', tuple(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(assumeNotNull(bidder)), bidder)), assumeNotNull(ad_buyerId)), concat(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(assumeNotNull(bidder)), bidder)), ' - ', toString(ad_buyerId)))",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "demand",
    },
    buyer: {
        label: "Buyer",
        field: "dictGetStringOrDefault('buyer', 'name', tuple(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(prebid_highestBidPartner), prebid_highestBidPartner)), ad_buyerId), concat(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(prebid_highestBidPartner), prebid_highestBidPartner)), ' - ', toString(ad_buyerId)))",
        fieldPrebid:
            "dictGetStringOrDefault('buyer', 'name', tuple(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(assumeNotNull(bidder)), bidder)), assumeNotNull(ad_buyerId)), concat(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(assumeNotNull(bidder)), bidder)), ' - ', toString(ad_buyerId)))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "demand",
    },
    advertiserDomain: {
        label: "Advertiser Domain",
        field: "ad_advertiserDomain",
        fieldPrebid: "ad_advertiserDomain",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "demand",
    },
    brand: {
        label: "Brand",
        field: "dictGetStringOrDefault('brand', 'name', tuple(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(prebid_highestBidPartner), prebid_highestBidPartner)), ad_brandId), concat(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(prebid_highestBidPartner), prebid_highestBidPartner)), ' - ', toString(ad_brandId)))",
        fieldPrebid:
            "dictGetStringOrDefault('brand', 'name', tuple(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(assumeNotNull(bidder)), bidder)), assumeNotNull(ad_brandId)), concat(assumeNotNull(dictGetStringOrDefault('bidder', 'name', tuple(assumeNotNull(bidder)), bidder)), ' - ', toString(ad_brandId)))",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "demand",
    },
    deal: {
        label: "Deal ID",
        field: "ad_dealId",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"], // TODO add to prebid
        uiGroup: "demand",
    },
    mapped_deal: {
        label: "Deal ID - Mapped",
        field: mappingDimensions("ad_dealId"),
        fieldPrebid: mappingDimensions("dealId"),
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"], // TODO add to prebid
        uiGroup: "mapped",
    },
    mediaType: {
        label: "Media Type",
        field: "ad_mediaType",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "demand",
    },
    bid: {
        label: "Bid Landscape",
        field: "FLOOR(prebid_highestBid / 1000000 * 20) / 20",
        fieldPrebid: "FLOOR(bid / 1000000 * 20) / 20",
        type: "select",
        row_type: "Number",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "demand",
    },
    timeToRespond: {
        label: "Time to Respond",
        field: "FLOOR(prebid_timeToRespond / 50) * 50",
        fieldPrebid: "if(prebid_timeToRespond = 0, NULL, FLOOR(prebid_timeToRespond / 50) * 50)",
        where: "_dimension <= 5000",
        type: "select",
        row_type: "Number",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "demand",
    },
    dfpAdvertiser: {
        label: "GAM Advertiser",
        field: "dictGetStringOrDefault('advertiser', 'name', toUInt64(dfp_advertiserId), CONCAT('Unknown - ', toString(dfp_advertiserId)))",
        type: "select",
        description: "The GAM Advertiser.",

        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "ad_server",
    },
    mapped_dfpAdvertiser: {
        description: "The GAM Advertiser.",

        label: "GAM Advertiser - Mapped",
        field: mappingYieldDfp({
            field: ifUnknown =>
                `dictGetStringOrDefault('advertiser', 'name', toUInt64(dfp_advertiserId), ${ifUnknown})`,
            fieldName: "dfpAdvertiser",
            fieldValue: "dfp_advertiserId",
        }),
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "mapped",
    },
    dfpAdvertiserId: {
        label: "GAM Advertiser Id",
        field: "toString(dfp_advertiserId)",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        hideFromApiDocs: true,
        uiGroup: "ad_server",
    },
    mapped_dfpAdvertiserId: {
        description: "The GAM Advertiser Id.",

        label: "GAM Advertiser Id - Mapped",
        field: mappingDimensions("dfpAdvertiserId", "toString(dfp_advertiserId)"),
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "mapped",
    },
    dfpHash: {
        label: "AY Hash K/V",
        field: "toString(dfp_hash)",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        description: "The custom key/value used to sync GAM and AY.",
        uiGroup: "ad_server",
    },
    dfpHashRaw: {
        label: "Raw AY Hash K/V",
        field: "dfp_hash_raw",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        hideFromApiDocs: true,
        uiGroup: "ad_server",
    },
    dfpOrder: {
        description: "The GAM Order.",

        label: "GAM Order",
        field: "dictGetStringOrDefault('campaign', 'name', toUInt64(dfp_campaignId), CONCAT('Unknown - ', toString(dfp_campaignId)))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "ad_server",
    },
    mapped_dfpOrder: {
        description: "The GAM Order.",

        label: "GAM Order - Mapped",
        field: mappingYieldDfp({
            field: ifUnknown => `dictGetStringOrDefault('campaign', 'name', toUInt64(dfp_campaignId), ${ifUnknown})`,
            fieldName: "dfpOrder",
            fieldValue: "dfp_campaignId",
        }),
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "mapped",
    },
    dfpOrderId: {
        label: "GAM Order Id",
        field: "toString(dfp_campaignId)",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        hideFromApiDocs: true,
        uiGroup: "ad_server",
    },
    mapped_dfpOrderId: {
        description: "The GAM Order Id.",

        label: "GAM Order Id - Mapped",
        field: mappingDimensions("dfpOrderId", "toString(dfp_campaignId)"),
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "mapped",
    },
    dfpLineItem: {
        description: "The GAM Line Item.",

        label: "GAM Line Item",
        field: "dictGetStringOrDefault('lineItem', 'name', toUInt64(dfp_lineItemId), CONCAT('Unknown - ', toString(dfp_lineItemId)))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "ad_server",
    },
    mapped_dfpLineItem: {
        description: "The GAM Line Item.",

        label: "GAM Line Item - Mapped",
        field: mappingYieldDfp({
            field: ifUnknown => `dictGetStringOrDefault('lineItem', 'name', toUInt64(dfp_lineItemId), ${ifUnknown})`,
            fieldName: "dfpLineItem",
            fieldValue: "dfp_lineItemId",
        }),
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "mapped",
    },
    dfpLineItemId: {
        label: "GAM Line Item Id",
        field: "toString(dfp_lineItemId)",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        hideFromApiDocs: true,
        uiGroup: "ad_server",
    },
    mapped_dfpLineItemId: {
        description: "The GAM Line Item Id.",

        label: "GAM Line Item Id - Mapped",
        field: mappingDimensions("dfpLineItemId", "toString(dfp_lineItemId)"),
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "mapped",
    },
    dfpLineItemType: {
        description: "The GAM Line Item Type.",

        label: "GAM Line Item Type",
        field: "dictGetStringOrDefault('lineItem', 'lineItemType', toUInt64(dfp_lineItemId), CONCAT('Unknown - ', toString(dfp_lineItemId)))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "ad_server",
    },
    dfpLineItemCostType: {
        label: "GAM Line Item Cost Type",
        field: "dictGetStringOrDefault('lineItem', 'costType', toUInt64(dfp_lineItemId), CONCAT('Unknown - ', toString(dfp_lineItemId)))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        hideFromApiDocs: true,
        uiGroup: "ad_server",
    },
    dfpCreative: {
        description: "The GAM Creative.",
        label: "GAM Creative",
        field: "toString(dfp_creativeId)",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "ad_server",
    },
    mapped_dfpCreative: {
        description: "The GAM Creative.",

        label: "GAM Creative - Mapped",
        field: mappingDimensions("dfp_creativeId", "toString(dfp_creativeId)"),
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "mapped",
    },
    dfpIsBackfill: {
        label: "Is Dynamic Allocation",
        field: "dfp_isBackfill",
        fieldPrebid: "0",
        type: "select",
        row_type: "Number",
        analytics_reports: ["yield"],
        hideFromApiDocs: true,
        uiGroup: "ad_server",
    },
    source: {
        hideFromApiDocs: true,
        label: "Revenue Source",
        field: "source",
        fieldPrebid: "'prebid'",
        type: "select",
        row_type: "Enum",
        analytics_reports: ["yield"],
        enumOptions: REVENUE_SOURCE_OPTIONS,
        uiGroup: "none",
    },

    // Implementation

    prebid_source: {
        label: "Prebid Source",
        field: "prebid_source",
        type: "select",
        row_type: "Enum",
        analytics_reports: ["yield", "prebid"],
        enumOptions: PREBID_SOURCE_OPTIONS,
        uiGroup: "implementation",
    },
    adUnitCode: {
        label: "Ad Unit Code",
        fieldPrebid: "dfp_adUnitPath",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["prebid"],
        uiGroup: "implementation",
    },
    placement: {
        label: "Placement",
        field: "ad_slotId",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "implementation",
    },
    adUnit: {
        label: "Ad Unit",
        field: "dfp_adUnitPath",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "implementation",
    },
    mapped_adUnit: {
        label: "Ad Unit - Mapped",
        field: mappingDimensions("adUnit", "toString(dfp_adUnitPath)"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "mapped",
    },
    size: {
        label: "Size",
        field: "ad_size",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "implementation",
    },
    adQualityIsBlocked: {
        label: "Ad Quality Is Blocked",
        field: "adQuality_blockingType > 0",
        type: "select",
        row_type: "Number",
        analytics_reports: ["yield"],
        uiGroup: "demand",
    },
    adQualityBlockingType: {
        label: "Ad Quality Blocking Type",
        field: "dictGetString('quality_blocking_type', 'type', tuple(assumeNotNull(adQuality_blockingType)))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        uiGroup: "demand",
    },
    prebid_version: {
        label: "Prebid Version",
        field: "prebid_version",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "implementation",
    },
    assertiveVersion: {
        label: "Assertive Version",
        field: "assertiveVersion",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "implementation",
    },
    prebidUserIdProviderUsed: {
        label: "PB UID Provider Provided",
        type: "select",
        row_type: "Number",
        // We check if it is empty and force it not to be otherwise to avoid empty arrays "zeroing" the entire query.
        // Check !4783 or #2741 for more details.
        field: "has(prebid_userIds,arrayJoin(if(empty(prebid_configUserIds), [''], prebid_configUserIds)))",
        analytics_reports: ["yield"],
        isMultiAttribution: true,
        uiGroup: "implementation",
    },
    prebidUserIdProviderConfigured: {
        label: "PB UID Provider",
        type: "select",
        row_type: "String",
        // We check if it is empty and force it not to be otherwise to avoid empty arrays "zeroing" the entire query.
        // Check !4783 or #2741 for more details.
        field: "arrayJoin(if(empty(prebid_configUserIds), [''], prebid_configUserIds))",
        analytics_reports: ["yield"],
        isMultiAttribution: true,
        uiGroup: "implementation",
    },
    // Custom
    timeout: {
        label: "Timeout",
        field: "prebid_timeout",
        type: "select",
        shouldLimit: true,
        row_type: "Number",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "implementation",
    },
    userState: {
        label: "User State",
        field: "custom_userState",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    layout: {
        label: "Layout",
        field: "custom_layout",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_1: {
        label: "Custom 1",
        field: "custom_1",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_2: {
        label: "Custom 2",
        field: "custom_2",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_3: {
        label: "Custom 3",
        field: "custom_3",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_4: {
        label: "Custom 4",
        field: "custom_4",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_5: {
        label: "Custom 5",
        field: "custom_5",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_6: {
        label: "Custom 6",
        field: "custom_6",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_7: {
        label: "Custom 7",
        field: "custom_7",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_8: {
        label: "Custom 8",
        field: "custom_8",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_9: {
        label: "Custom 9",
        field: "custom_9",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_10: {
        label: "Custom 10",
        field: "custom_10",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_11: {
        label: "Custom 11",
        field: "custom_11",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_12: {
        label: "Custom 12",
        field: "custom_12",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_13: {
        label: "Custom 13",
        field: "custom_13",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_14: {
        label: "Custom 14",
        field: "custom_14",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    custom_15: {
        label: "Custom 15",
        field: "custom_15",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "custom",
    },
    mapped_userState: {
        label: "User State - Mapped",
        field: mappingDimensions("custom_userState"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_layout: {
        label: "Layout - Mapped",
        field: mappingDimensions("custom_layout"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_custom_1: {
        label: "Custom 1 - Mapped",
        field: mappingDimensions("custom_1"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_custom_2: {
        label: "Custom 2 - Mapped",
        field: mappingDimensions("custom_2"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_custom_3: {
        label: "Custom 3 - Mapped",
        field: mappingDimensions("custom_3"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_custom_4: {
        label: "Custom 4 - Mapped",
        field: mappingDimensions("custom_4"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_custom_5: {
        label: "Custom 5 - Mapped",
        field: mappingDimensions("custom_5"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_custom_6: {
        label: "Custom 6 - Mapped",
        field: mappingDimensions("custom_6"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_custom_7: {
        label: "Custom 7 - Mapped",
        field: mappingDimensions("custom_7"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_custom_8: {
        label: "Custom 8 - Mapped",
        field: mappingDimensions("custom_8"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_custom_9: {
        label: "Custom 9 - Mapped",
        field: mappingDimensions("custom_9"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    is_ad_unfilled: {
        label: "Is Unfilled",
        field: "ad_unfilled",
        type: "select",
        row_type: "Number",
        analytics_reports: ["yield"],
        hideFromApiDocs: true,
        uiGroup: "none",
    },

    // User
    newVsReturning: {
        label: "New vs. Returning",
        field: "if(client_sessionCount = 1, 'New Visitor', 'Returning Visitor')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    clientDepthSessions: {
        label: "Device Depth Sessions",
        field: "if(client_sessionCount > 100, '100+', toString(client_sessionCount))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    sessionDepthPageViews: {
        description: "Number of PVs within a session",

        label: "Session Depth PV",
        field: "if(session_pageViewCount > 100, '100+', toString(session_pageViewCount))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    sessionDepthImpressions: {
        description: "Number of impressions within a session",

        label: "Session Depth Imps",
        field: "if(session_impressionCount > 200, '200+', toString(FLOOR(session_impressionCount / 5) * 5))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    device: {
        label: "Device",
        field: "if(isNull(device), 'Unknown', dictGetStringOrDefault('device', 'name', tuple(assumeNotNull(device)), 'Unknown'))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    window_innerSize: {
        label: "Window Size",
        field: "if(window_innerWidth != 0 AND window_innerHeight != 0, concat(toString(window_innerWidth), 'x', toString(window_innerHeight)), 'Unknown')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        shouldLimit: true,
        uiGroup: "user",
    },
    window_scrollY: {
        description: "How many pixels users are scrolling down the page",

        label: "Scroll Depth",
        field: "if(window_scrollY > 4096, '4096+', toString(FLOOR(window_scrollY/100)*100) )",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    network_type: {
        label: "Network Type",
        field: "network_type",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    network_effectiveType: {
        label: "Network Effective Type",
        field: "network_effectiveType",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    network_downlink: {
        label: "Network Downlink",
        field: "toString(network_downlink)",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    network_rtt: {
        label: "Network RTT (ping)",
        field: "multiIf(network_rtt < 0, 'Unknown', network_rtt > 1000, '1000+', toString(FLOOR(network_rtt/50)*50) )",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    network_saveData: {
        label: "Network Data-Saver",
        field: "multiIf(network_saveData < 0, 'Unknown', network_saveData > 0, 'On', 'Off')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    ivt_category: {
        label: "IVT Category",
        field: "dictGetStringOrDefault('ivt', 'name', tuple(ivt_category), 'Unknown')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "user",
    },
    ivt_type: {
        label: "IVT Type",
        field: "dictGetStringOrDefault('ivt', 'type', tuple(ivt_category), 'Unknown')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "user",
    },
    operatingSystem: {
        label: "Operating System",
        field: "os_name",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web", "error_analytics"],
        uiGroup: "user",
    },
    topics: {
        label: "Topics",
        field: "arrayStringConcat(arrayMap(it -> dictGetString('topics', 'topic', tuple(it)), arraySort(topics)), ', ')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "web"],
        uiGroup: "user",
    },
    cookieDeprecationLabel: {
        label: "Cookie Deprecation",
        field: "cookieDeprecationLabel",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "user",
    },
    browser: {
        label: "Browser",
        field: "browser_name",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web", "error_analytics"],
        uiGroup: "user",
    },
    country: {
        label: "Country",
        field: "country_name",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web", "error_analytics"],
        uiGroup: "user",
    },
    countryCode: {
        label: "Country Code",
        field: "country_code",
        type: "select",
        row_type: "FixedString(2)",
        analytics_reports: ["yield", "prebid", "web", "error_analytics"],
        uiGroup: "user",
    },
    region: {
        label: "Region",
        field: "region_name",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    continent: {
        label: "Continent",
        field: "continent_code",
        type: "select",
        row_type: "FixedString(2)",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },
    language: {
        label: "Language",
        field: "language",
        type: "select",
        row_type: "FixedString(2)",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "user",
    },

    sessionHadAds: {
        label: "Session Had Ads",
        field: "session_impressionCount > 0",
        type: "select",
        row_type: "Number",
        analytics_reports: ["web"],
        uiGroup: "implementation",
    },
    placementRenderCount: {
        label: "Placement Render Count",
        field: "(pageView_refreshCount+1)",
        type: "select",
        row_type: "Number",
        analytics_reports: ["yield"],
        uiGroup: "implementation",
    },
    refreshCount: {
        label: "Ad Refresh Count",
        field: "pageView_refreshCount",
        type: "select",
        row_type: "Number",
        analytics_reports: ["yield"],
        uiGroup: "implementation",
    },
    pageViewHadAds: {
        label: "Page View Had Ads",
        field: "pageView_impressionCount > 0",
        type: "select",
        row_type: "Number",
        analytics_reports: ["web"],
        uiGroup: "implementation",
    },
    // Source
    referrerType: {
        label: "Referrer Type",
        field: "dictGetStringOrDefault('referrer', 'type', tuple(assumeNotNull(cutToFirstSignificantSubdomain(referrer))), CASE WHEN cutToFirstSignificantSubdomain(referrer) = cutToFirstSignificantSubdomain(host) THEN 'Internal' WHEN referrer='' OR cutToFirstSignificantSubdomain(referrer) IS NULL THEN 'Direct' ELSE 'Referral' END)",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "source",
    },
    referrerDomain: {
        label: "Referrer Domain",
        field: "domainWithoutWWW(referrer)",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "source",
    },
    referrer: {
        label: "Referrer",
        field: "CONCAT(domainWithoutWWW(referrer), path(referrer))",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "source",
    },
    utmSource: {
        label: "UTM Source",
        field: "session_utm_source",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "source",
    },
    utmMedium: {
        label: "UTM Medium",
        field: "session_utm_medium",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "source",
    },
    utmCampaign: {
        label: "UTM Campaign",
        field: "session_utm_campaign",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "source",
    },
    utmTerm: {
        label: "UTM Term",
        field: "session_utm_term",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "source",
    },
    utmContent: {
        label: "UTM Content",
        field: "session_utm_content",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "source",
    },
    mapped_utmSource: {
        label: "UTM Source - Mapped",
        field: mappingDimensions("session_utm_source"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_utmMedium: {
        label: "UTM Medium - Mapped",
        field: mappingDimensions("session_utm_medium"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_utmCampaign: {
        label: "UTM Campaign - Mapped",
        field: mappingDimensions("session_utm_campaign"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_utmTerm: {
        label: "UTM Term - Mapped",
        field: mappingDimensions("session_utm_term"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    mapped_utmContent: {
        label: "UTM Content - Mapped",
        field: mappingDimensions("session_utm_content"),
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "mapped",
    },
    // Content
    host: {
        label: "Domain",
        field: "host",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "content",
    },
    domain_and_content: {
        label: "Page Domain Path",
        field: "concat(host, pathname)",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "content",
    },
    error_analytics_meta_host: {
        label: "Domain",
        field: "meta_host",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["error_analytics"],
        uiGroup: "none",
    },
    error_analytics_meta_source: {
        label: "Error Source",
        field: "meta_source",
        type: "select",
        row_type: "String",
        analytics_reports: ["error_analytics"],
        uiGroup: "none",
    },
    error_analytics_meta_version: {
        label: "Version (Error)",
        field: "meta_version",
        type: "select",
        row_type: "String",
        analytics_reports: ["error_analytics"],
        uiGroup: "none",
    },
    entryPage: {
        label: "Entry Page",
        field: "session_entryPage",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "content",
    },
    content: {
        label: "Content",
        field: "pathname",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "content",
    },
    pageLevel1: {
        label: "Page Level 1",
        field: "assumeNotNull(concat(pathname_1, '/'))",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "content",
    },
    pageLevel2: {
        label: "Page Level 2",
        field: "assumeNotNull(concat(pathname_1, '/', pathname_2))",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "content",
    },
    pageLevel3: {
        label: "Page Level 3",
        field: "assumeNotNull(concat(pathname_1, '/', pathname_2, '/', pathname_3))",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "content",
    },
    pageLevel4: {
        label: "Page Level 4",
        field: "assumeNotNull(concat(pathname_1, '/', pathname_2, '/', pathname_3, '/', pathname_4))",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "content",
    },
    content_headline: {
        label: "Content Headline",
        field: "content_headline",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "web"],
        uiGroup: "content",
    },
    content_author: {
        label: "Content Author",
        field: "content_author",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "web"],
        uiGroup: "content",
    },
    content_datePublished: {
        label: "Content Date Published",
        field: "toString(toDate(content_datePublished, %%TIMEZONE%%))",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "web"],
        uiGroup: "content",
    },
    content_dateModified: {
        label: "Content Date Modified",
        field: "toString(toDate(content_dateModified, %%TIMEZONE%%))",
        type: "select",
        shouldLimit: true,
        row_type: "String",
        analytics_reports: ["yield", "web"],
        uiGroup: "content",
    },
    pageLevel1Inf: {
        label: "Page Level 1 Inf",
        // Concat '' so that the formula is different from pageLevel1,
        // but results are the same.
        field: "assumeNotNull(concat(pathname_1, '/', ''))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        hideFromApiDocs: true,
        uiGroup: "content",
    },
    entityId: {
        label: "Entity Id",
        field: "entityId",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        hideFromApiDocs: true,
        uiGroup: "none",
    },
    entityName: {
        label: "AY Entity",
        field: "dictGetStringOrDefault('%%ENTITY_HIERARCHY_DICT%%', 'entity_name', tuple(entityId), 'Unknown')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        hideFromApiDocs: true,
        uiGroup: "none",
    },
    publisherName: {
        label: "AY Publisher",
        field: "dictGetStringOrDefault('%%ENTITY_HIERARCHY_DICT%%', 'publisher_name', tuple(entityId), 'Unknown')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        hideFromApiDocs: true,
        uiGroup: "none",
    },
    organizationName: {
        label: "AY Organization",
        field: "dictGetStringOrDefault('%%ENTITY_HIERARCHY_DICT%%', 'organization_name', tuple(entityId), 'Unknown')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        hideFromApiDocs: true,
        uiGroup: "none",
    },
    hourOfDay: {
        description: "Hour of the day, in the app's selected timezone.",

        label: "Hour of Day",
        field: "if(length(toString(toHour(timestamp, %%TIMEZONE%%))) = 1, concat('0', toString(toHour(timestamp, %%TIMEZONE%%)), 'h'), concat(toString(toHour(timestamp, %%TIMEZONE%%)), 'h'))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "time_based",
    },
    dayOfWeek: {
        description: "Day of the week, in the app's selected timezone.",

        label: "Day of the Week",
        field: "multiIf(toDayOfWeek(timestamp, %%TIMEZONE%%) = 1, 'Monday', toDayOfWeek(timestamp, %%TIMEZONE%%) = 2, 'Tuesday', toDayOfWeek(timestamp, %%TIMEZONE%%) = 3, 'Wednesday', toDayOfWeek(timestamp, %%TIMEZONE%%) = 4, 'Thursday', toDayOfWeek(timestamp, %%TIMEZONE%%) = 5, 'Friday', toDayOfWeek(timestamp, %%TIMEZONE%%) = 6, 'Saturday', toDayOfWeek(timestamp, %%TIMEZONE%%) = 7, 'Sunday', 'No day')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "time_based",
    },
    dayOfMonth: {
        description: "Day of the month, in the app's selected timezone.",

        label: "Day of the Month",
        field: "if(length(toString(toDayOfMonth(timestamp, %%TIMEZONE%%))) = 1, concat('0', toString(toDayOfMonth(timestamp, %%TIMEZONE%%))), toString(toDayOfMonth(timestamp, %%TIMEZONE%%)))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "time_based",
    },
    monthOfYear: {
        description: "Month of the year.",
        label: "Month on the year",
        field: "toString(toMonth(timestamp, %%TIMEZONE%%))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "time_based",
    },
    fullDate: {
        label: "Full Date",
        field: "toString(toDate(timestamp, %%TIMEZONE%%))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        hideFromApiDocs: true,
        uiGroup: "time_based",
    },
    timeZone: {
        description: "User time zone.",
        label: "Time Zone",
        field: "timeZone",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "time_based",
    },
    externalId: {
        label: "External Id",
        field: "externalId",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield"],
        hideFromApiDocs: true,
        uiGroup: "none",
    },
    sessionId: {
        label: "Session Id",
        field: "toString(sessionId)",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        hideFromApiDocs: true,
        uiGroup: "none",
    },
    // we define the following dimensions without analytics reports
    // because we only want to show it's label on the UI
    buy_report_platform: {
        label: "Platform",
        field: "platform",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    buy_report_campaign_name: {
        label: "Campaign Name",
        field: "campaign_name",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    buy_report_adset_name: {
        label: "Ad Group Name",
        field: "adset_name",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    buy_report_subAccountId: {
        label: "DSP Sub-Account Id",
        field: "subAccountId",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    buy_report_subAccountName: {
        label: "DSP Sub-Account",
        field: "subAccountName",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    buy_report_ad_name: {
        label: "Ad Name",
        field: "ad_name",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },

    buy_report_campaign_id: {
        label: "Campaign ID",
        field: "campaign_id",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    buy_report_adset_id: {
        label: "Ad Group ID",
        field: "adset_id",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    buy_report_ad_id: {
        label: "Ad ID",
        field: "ad_id",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },

    site_report_platform: {
        label: "Platform",
        field: "platform",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    site_report_site: {
        label: "Site",
        field: "site",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    site_report_site_name: {
        label: "Site Name",
        field: "site_name",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    site_report_site_url: {
        label: "Site URL",
        field: "site_url",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    site_report_site_id: {
        label: "Site ID",
        field: "site_id",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    site_report_campaign_name: {
        label: "Campaign Name",
        field: "campaign_name",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    site_report_campaign_id: {
        label: "Campaign ID",
        field: "campaign_id",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    site_report_subAccountId: {
        label: "Sub Account ID",
        field: "subAccountId",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    site_report_subAccountName: {
        label: "Sub Account Name",
        field: "subAccountName",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    spent_by_section_report_platform: {
        label: "Platform",
        field: "platform",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    spent_by_section_report_section_name: {
        label: "Section Name",
        field: "section_name",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    spent_by_section_report_section_url: {
        label: "Section URL",
        field: "section_url",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    spent_by_section_report_section_id: {
        label: "Section ID",
        field: "section_id",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    spent_by_section_report_campaign_name: {
        label: "Campaign Name",
        field: "campaign_name",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    spent_by_section_report_campaign_id: {
        label: "Campaign ID",
        field: "campaign_id",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    spent_by_section_report_subAccountId: {
        label: "Sub Account ID",
        field: "subAccountId",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    spent_by_section_report_subAccountName: {
        label: "Sub Account Name",
        field: "subAccountName",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    manager_status: {
        description: "Yield Manager's status.",

        label: "Yield Manager Status",
        field: "if(manager_versionId > -1, 'Active', 'Inactive')",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "yield_manager",
    },
    manager_deployId: {
        label: "Yield Manager Deploy ID",
        field: "manager_deployId",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "yield_manager",
    },
    manager_deployedAt: {
        label: "Yield Manager Deployed At",
        field: "toString(toDateTime(dictGetDateTime('%%DICT_YIELD_MANAGER_DEPLOYED_AT%%', 'deployed_at', tuple(entityId, manager_deployId)), %%TIMEZONE%%))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "yield_manager",
    },
    manager_versionId: {
        description: "Yield Manager's version id.",

        label: "Yield Manager Version ID",
        field: "toString(manager_versionId)",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "yield_manager",
    },
    manager_versionName: {
        description: "Yield Manager's version name.",

        label: "Yield Manager Version Name",
        field: "if(manager_versionId = -2, 'Working changes', dictGetString('%%DICT_YIELD_MANAGER_VERSION_NAME%%', 'version_name', tuple(entityId, manager_versionId)))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid", "web"],
        uiGroup: "yield_manager",
    },
    floor_prebid: {
        label: "Floor Prebid",
        field: "if(ad_floor_group = 'optimize', 'optimize', dictGetStringOrDefault('flooring_strategies', 'strategy', tuple(ad_floor_prebid), ad_floor_prebid))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_amazon: {
        label: "Floor Amazon",
        field: "if(ad_floor_group = 'optimize', 'optimize', dictGetStringOrDefault('flooring_strategies', 'strategy', tuple(ad_floor_amazon), ad_floor_amazon))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_gpt: {
        label: "Floor GPT",
        field: "ad_floor_gpt",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_group: {
        label: "Floor Group",
        field: "ad_floor_group",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        hideFromApiDocs: true,
        uiGroup: "none",
    },

    floor_threshold_raw: {
        label: "Floor Threshold Raw",
        field: "dictGetStringOrDefault('flooring_strategies', 'strategy', tuple(ad_floor_threshold), ad_floor_threshold)",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_threshold: {
        label: "Floor Threshold",
        field: "if(ad_floor_group = 'optimize', 'optimize', dictGetStringOrDefault('flooring_strategies', 'strategy', tuple(ad_floor_threshold), ad_floor_threshold))",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_pbo: {
        label: "Floor PBO",
        field: "dictGetStringOrDefault('flooring_strategies', 'strategy', tuple(ad_floor_pbo), ad_floor_pbo)",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_status: {
        label: "Floor Status",
        field: "ad_floor_status",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    request_reduction_client_status: {
        label: "Bid Req. Optimization Status",
        field: "reduction_group",
        type: "select",
        row_type: "String",
        analytics_reports: ["yield", "prebid"],
        uiGroup: "content",
    },
    discrepancy_revenue_bidder: {
        label: "SSP Name",
        field: "discrepancy_revenue_bidder",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    discrepancy_revenue_placement: {
        label: "Placement",
        field: "discrepancy_revenue_placement",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    financial_bidder: {
        label: "SSP Name",
        field: "financial_bidder",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    financial_placement_id: {
        label: "Placement",
        field: "financial_placement_id",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    financial_currency: {
        label: "SSP Currency",
        field: "financial_currency",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    financial_timezone: {
        label: "Timezone",
        field: "financial_timezone",
        type: "select",
        row_type: "String",
        analytics_reports: [],
        uiGroup: "none",
    },
    request_reduction_client_v2_country: {
        label: "Country",
        field: "country_name",
        type: "select",
        row_type: "String",
        analytics_reports: ["request_reduction_client_v2"],
        uiGroup: "none",
    },
    request_reduction_client_v2_bidder: {
        label: "Bidder",
        field: "bidder",
        type: "select",
        row_type: "String",
        analytics_reports: ["request_reduction_client_v2"],
        uiGroup: "none",
    },
    request_reduction_client_v2_host: {
        label: "Domain",
        field: "host",
        type: "select",
        row_type: "String",
        analytics_reports: ["request_reduction_client_v2"],
        uiGroup: "none",
    },
    request_reduction_client_v2_device_name: {
        label: "Device Name",
        field: "device_name",
        type: "select",
        row_type: "String",
        analytics_reports: ["request_reduction_client_v2"],
        uiGroup: "none",
    },
    request_reduction_client_v2_os_name: {
        label: "OS Name",
        field: "os_name",
        type: "select",
        row_type: "String",
        analytics_reports: ["request_reduction_client_v2"],
        uiGroup: "none",
    },
    request_reduction_client_v2_reduction_group: {
        label: "Reduction Group",
        field: "reduction_group",
        type: "select",
        row_type: "String",
        analytics_reports: ["request_reduction_client_v2"],
        uiGroup: "none",
    },
});

export const MAPPING_ALLOWED_ORIGINAL_DIMENSIONS = [
    // GAM dimensions
    dimensions.dfpAdvertiser,
    dimensions.dfpAdvertiserId,
    dimensions.dfpOrder,
    dimensions.dfpOrderId,
    dimensions.dfpLineItem,
    dimensions.dfpLineItemId,
    dimensions.dfpCreative,
    dimensions.adUnit,
    // UTM dimensions
    dimensions.utmCampaign,
    dimensions.utmContent,
    dimensions.utmMedium,
    dimensions.utmSource,
    dimensions.utmTerm,
    dimensions.deal,
    dimensions.layout,
    dimensions.userState,
    dimensions.custom_1,
    dimensions.custom_2,
    dimensions.custom_3,
    dimensions.custom_4,
    dimensions.custom_5,
    dimensions.custom_6,
    dimensions.custom_7,
    dimensions.custom_8,
    dimensions.custom_9,
];

export const MAPPING_IDS_RENAMED = {
    dfpAdvertiser: "dfpAdvertiser",
    dfpAdvertiserId: "dfpAdvertiserId",
    dfpOrder: "dfpOrder",
    dfpOrderId: "dfpOrderId",
    dfpLineItem: "dfpLineItem",
    dfpLineItemId: "dfpLineItemId",
    dfpCreative: "dfpCreative",
    adUnit: "adUnit",
    utmCampaign: "session_utm_campaign",
    utmContent: "session_utm_content",
    utmMedium: "session_utm_medium",
    utmSource: "session_utm_source",
    utmTerm: "session_utm_term",
    deal: "ad_dealId",
    layout: "custom_layout",
    userState: "custom_userState",
    custom_1: "custom_1",
    custom_2: "custom_2",
    custom_3: "custom_3",
    custom_4: "custom_4",
    custom_5: "custom_5",
    custom_6: "custom_6",
    custom_7: "custom_7",
    custom_8: "custom_8",
    custom_9: "custom_9",
};

export const MAPPING_IDS_RENAMED_REVERSED = STD.reverseKv(MAPPING_IDS_RENAMED);

export const yieldFilterIds: DimensionKeys[] = Object.values(dimensions)
    .filter(it => it.analytics_reports.includes("yield"))
    .map(it => it.id);

export const prebidFilterIds: DimensionKeys[] = Object.values(dimensions)
    .filter(it => it.analytics_reports.includes("prebid"))
    .map(it => it.id);

export const webFilterIds: DimensionKeys[] = Object.values(dimensions)
    .filter(it => it.analytics_reports.includes("web"))
    .map(it => it.id);

export const globalSegmentsFilterIds: DimensionKeys[] = Object.values(dimensions)
    .filter(
        it =>
            it.analytics_reports.includes("web") ||
            it.analytics_reports.includes("prebid") ||
            it.analytics_reports.includes("yield")
    )
    .map(it => it.id);

export const errorAnalyticsFilterIds: DimensionKeys[] = Object.values(dimensions)
    .filter(it => it.analytics_reports.includes("error_analytics"))
    .map(it => it.id);

export const spentCampaignFilterIds: DimensionKeys[] = [
    "buy_report_platform",
    "buy_report_campaign_name",
    "buy_report_campaign_id",
    "buy_report_subAccountId",
    "buy_report_subAccountName",
];

export const spentAdsetFilterIds: DimensionKeys[] = [
    "buy_report_platform",
    "buy_report_campaign_name",
    "buy_report_adset_name",
    "buy_report_campaign_id",
    "buy_report_adset_id",
    "buy_report_subAccountId",
    "buy_report_subAccountName",
];

export const spentAdsFilterIds: DimensionKeys[] = [
    "buy_report_platform",
    "buy_report_campaign_name",
    "buy_report_adset_name",
    "buy_report_ad_name",
    "buy_report_campaign_id",
    "buy_report_adset_id",
    "buy_report_ad_id",
    "buy_report_subAccountId",
    "buy_report_subAccountName",
];

export const siteReportFilterIds: DimensionKeys[] = STD.getKeys(dimensions).filter(it => it.startsWith("site_report_"));

export const spentBySectionReportFilterIds: DimensionKeys[] = STD.getKeys(dimensions).filter(it =>
    it.startsWith("spent_by_section_report_")
);

export const discrepancyRevenueReportFilterIds: DimensionKeys[] = STD.getKeys(dimensions).filter(it =>
    it.startsWith("discrepancy_revenue")
);

export const financialReportFilterIds: DimensionKeys[] = STD.getKeys(dimensions).filter(it =>
    it.startsWith("financial")
);

export type FilterKeys = DimensionKeys | "startDate" | "endDate";

// For industry report we show the filter component
// but for a restricted subset of dimensions.
export const industryReportFiltersIds: DimensionKeys[] = [
    "device",
    "bidder",
    "country",
    "browser",
    "referrerType",
    "mediaType",
    "size",
];

export const adRevenueReportFilterIds: DimensionKeys[] = [
    "device",
    "country",
    "browser",
    "referrerType",
    "mediaType",
    "size",
];

export const discrepancyRevenueFilterIds: DimensionKeys[] = [
    "discrepancy_revenue_bidder",
    "discrepancy_revenue_placement",
];

export const requestReductionServerLatencyFilterIds: DimensionKeys[] = [
    "request_reduction_server_node",
    "request_reduction_server_datacenter",
];

export const requestReductionServerSamplesProfileFilterIds: DimensionKeys[] = [
    "request_reduction_server_samples_bidder",
    "request_reduction_server_samples_bidder_mapped",
    "request_reduction_server_samples_country",
    "request_reduction_server_samples_publisher_id",
    "request_reduction_server_samples_placement_id",
    "request_reduction_server_samples_request_type",
    "request_reduction_server_samples_format",
    "request_reduction_server_samples_sizes",
    "request_reduction_server_samples_platform",
    "request_reduction_server_samples_bundle",
    "request_reduction_server_samples_os",
    "request_reduction_server_samples_browser",
    "request_reduction_server_samples_device",
    "request_reduction_server_samples_has_gps",
    "request_reduction_server_samples_codebase_version",
    "request_reduction_server_samples_version",
    "request_reduction_server_samples_device_language",
    "request_reduction_server_samples_utc_offset",
    "request_reduction_server_samples_gdpr",
    "request_reduction_server_samples_us_privacy",
    "request_reduction_server_samples_video_startdelay",
    "request_reduction_server_samples_video_skip",
    "request_reduction_server_samples_video_rewarded",
    "request_reduction_server_samples_url",
    "request_reduction_server_samples_video_playback_method",
    "request_reduction_server_samples_site_cat",
    "request_reduction_server_samples_unit_types",
    "request_reduction_server_samples_page_cat",
    "request_reduction_server_samples_has_omsdk",
    "request_reduction_server_samples_has_age",
    "request_reduction_server_samples_has_gender",
    "request_reduction_server_samples_has_mraid",
    "request_reduction_server_samples_has_vast",
    "request_reduction_server_samples_has_vpaid",
    "request_reduction_server_samples_has_consent",
    "request_reduction_server_samples_is_qps",
    "request_reduction_server_samples_datacenter",
    "request_reduction_server_samples_domain",
    "request_reduction_server_samples_custom1",
    "request_reduction_server_samples_custom2",
    "request_reduction_server_samples_custom3",
];

export const requestReductionServerSamplesPredictFilterIds: DimensionKeys[] = [
    "request_reduction_server_samples_threshold",
    ...requestReductionServerSamplesProfileFilterIds,
    "request_reduction_server_samples_bid_confusion_matrix",
    "request_reduction_server_samples_win_confusion_matrix",
];

export const requestReductionClientV2FilterIds: DimensionKeys[] = Object.values(dimensions)
    .filter(it => it.analytics_reports.includes("request_reduction_client_v2"))
    .map(it => it.id);

export const getDimensionIdsFromPlacement = (placement: ReportPlacementKeyType): DimensionKeys[] => {
    switch (placement) {
        case "industry": {
            return industryReportFiltersIds;
        }

        case "ad_revenue": {
            return adRevenueReportFilterIds;
        }

        case "site": {
            return siteReportFilterIds;
        }
        case "buy_ad": {
            return spentAdsFilterIds;
        }
        case "buy_adset": {
            return spentAdsetFilterIds;
        }
        case "buy_campaign": {
            return spentCampaignFilterIds;
        }
        case "spent_by_section": {
            return spentBySectionReportFilterIds;
        }
        case "flooring":
        case "time_series_forecast":
        case "session_revenue":
        case "session_time":
        case "yield": {
            return yieldFilterIds;
        }
        case "prebid": {
            return prebidFilterIds;
        }
        case "web": {
            return webFilterIds;
        }
        case "request_reduction_server_latency": {
            return requestReductionServerLatencyFilterIds;
        }
        case "request_reduction_server_samples_profile": {
            return requestReductionServerSamplesProfileFilterIds;
        }
        case "request_reduction_server_samples_predict": {
            return requestReductionServerSamplesPredictFilterIds;
        }

        case "request_reduction_client_v2": {
            return requestReductionClientV2FilterIds;
        }

        case "discrepancy_revenue": {
            return discrepancyRevenueReportFilterIds;
        }

        case "financial": {
            return financialReportFilterIds;
        }

        case "error_log": {
            return [];
        }
        case "error_analytics": {
            return errorAnalyticsFilterIds;
        }
        case "segments": {
            return globalSegmentsFilterIds;
        }

        default: {
            return absurd(placement);
        }
    }
};

export const GetDailyDetailsLimit = 2000;

export const prettyDSP = {
    taboola: "Taboola",
    zemanta: "Zemanta",
    twitter: "Twitter",
    facebook: "Facebook",
    yahoo: "Yahoo",
    snapchat: "Snapchat",
    outbrain: "Outbrain",
    revcontent: "Revcontent",
    baidu: "Baidu",
    google_ads: "Google Ads",
    tiktok: "TikTok",
    pinterest: "Pinterest",
};
export const prettySSP = {
    nativo_ssp: "Nativo",
    yahoo_ssp: "Yahoo SSP",
    outbrain_ssp: "Outbrain SSP (Beta)",
    taboola_realtime_ssp: "Taboola Realtime (Beta)",
    taboola: "Taboola (Beta)",
    google_adsense: "AdSense Analytics (Alpha)",
    google_adsense_direct: "AdSense Direct (Alpha)",
    ogury: "OGury",
    loopme: "LoopMe",
    seedtag: "SeedTag",
};

export const DEFAULT_DSP_SPEND_JOIN_KEY = "campaign_id";
export const DEFAULT_DSP_YIELD_JOIN_KEY = "utmCampaign";

export const DEFAULT_DSP_SITE_JOIN_KEY = "site_name";
export const DEFAULT_DSP_SITE_YIELD_JOIN_KEY = "utmSource";

export const DEFAULT_DSP_SPENT_BY_SECTION_JOIN_KEY = "section_name";
export const DEFAULT_DSP_SPENT_BY_SECTION_YIELD_JOIN_KEY = "utmSource";

export const QUALITATIVE_COLORS = [
    // chartjs colors
    // "255, 99, 132",
    // "255, 159, 64",
    // "255, 205, 86",
    // "75, 192, 192",
    // "54, 162, 235",
    // "153, 102, 255",
    // "201, 203, 207",
    // app default colors,
    "77, 201, 246",
    "246, 112, 25",
    "245, 55, 148",
    "83, 123, 196",
    "172, 194, 54",
    "133, 73, 186",
    "0, 169, 80",
    "22, 106, 143",
    "88, 89, 91",
    /* The ‘mpn65’ palette is designed for systems which show many graphs which
   don’t have custom color palettes chosen by humans or if number of necessary
   colors isn’t know a priori. */
    // from https://github.com/google/palette.js/blob/master/palette.js#L809
    // "55,126,184",
    // "102,166,30",
    // "152,78,163",
    // "0,210,213",
    // "255,127,0",
    "175,141,0",
    // "127,128,205",
    "179,233,0",
    "196,46,96",
    "166,86,40",
    "247,129,191",
    "141,211,199",
    // "190,186,218",
    "251,128,114",
    "128,177,211",
    // "253,180,98",
    // "252,205,229",
    "188,128,189",
    // "255,237,111",
    // "196,234,255",
    "207,140,0",
    "27,158,119",
    "217,95,2",
    "231,41,138",
    "230,171,2",
    "166,118,29",
    "0,151,255",
    "0,208,103",
    // "0,0,0",
    // "37,37,37",
    // "82,82,82",
    "115,115,115",
    // "150,150,150",
    "189,189,189",
    "244,54,0",
    "75,169,59",
    "87,121,187",
    "146,122,204",
    // "151,238,63",
    "191,57,71",
    "159,91,0",
    "244,135,88",
    "140,174,214",
    "242,185,79",
    // "239,242,110",
    // "228,56,114",
    "217,177,0",
    // "157,122,0",
    "105,140,255",
    // "217,217,217",
    // "0,210,126",
    "208,104,0",
    "0,159,130",
    "196,146,0",
    // "203,232,255",
    // "254,205,223",
    "194,126,182",
    "140,210,206",
    "196,184,217",
    "248,131,176",
    // "164,145,0",
    // "244,136,0",
    "39,208,223",
    // "160,74,155",
    "255,0,41",
];
export const FACEBOOK_API_VERSION = "v20.0";
export const YAHOO_API_URL = "https://api.gemini.yahoo.com/v3";
export const STATUSPAGE_SUMMARY_URL = "https://status.assertiveyield.com/api/v2/summary.json";
export const TWITTER_API_URL = "https://ads-api.twitter.com/11";
export const GOOGLE_ADS_API_URL = "https://googleads.googleapis.com/v16";
export const BAIDU_ADS_API_DATA_URL = "https://api.mediago.io/data/v1";
export const BAIDU_ADS_API_MANAGE_URL = "https://api.mediago.io/manage/v1";
export const NATIVO_API_URL = "https://api.nativo.com/v2";
export const OUTBRAIN_API_URL = "https://api.outbrain.com/amplify/v0.1";
export const OUTBRAIN_ENGAGE_API_URL = "https://api.outbrain.com/engage";
export const TIKTOK_ADS_API_URL = "https://business-api.tiktok.com/open_api/v1.3";
export const PINTEREST_API_URL = "https://api.pinterest.com/v5";

export const CUSTOM_LABELS = [
    "custom_1",
    "custom_2",
    "custom_3",
    "custom_4",
    "custom_5",
    "custom_6",
    "custom_7",
    "custom_8",
    "custom_9",

    "custom_10",
    "custom_11",
    "custom_12",
    "custom_13",
    "custom_14",
    "custom_15",
] as const;

export type CustomLabelIds = (typeof CUSTOM_LABELS)[number];
export type CustomLabelsStored = Partial<Record<CustomLabelIds, string>>;

// five minutes
export const NETWORK_TIMEOUT_FOR_DOWNLOADS = 1000 * 60 * 5;

export const EDA_REQUEST_TIMEOUT_MS = 1000 * 60 * 20;

export const DEFAULT_TRIAL_DAYS = 14;
export const hasInvalidFirstData = (firstData: string, now: Date) =>
    moment(firstData).isBefore(moment(now).subtract(10, "years"));

export const PRODUCTION_TABLE_NAMES = {
    pageviews: "pageviews",
    jobLogs: "jobLogs",
    jobLogsBuffer: "jobLogs_buffer",
    apiLogs: "apiLogs",
    apiLogsBuffer: "apiLogs_buffer",
    workspaceLogs: "workspaceLogs",
    errorLog: "errorLog",
    events: "events",
    auctions: "auctions",
    spent: "spent",
    spent_by_site: "spent_by_site",
    spent_by_section: "spent_by_section",
    revenueIndexWithDimensions: "revenueIndexWithDimensions",
    predictMetricsBidder: "predict_metrics_bidder",
    predictMetrics: "predict_metrics",
    predictSamples: "predict_samples",
    predict_logs: "predict_logs",
    dict_currencyConversion: "dict_currencyConversion",
    events_taboola: "events_taboola",
    events_outbrain: "events_outbrain",
    events_conversions_adgarden: "events_conversions_adgarden",
    dynamicAllocationSsp: "dynamicAllocationSsp",
    nativoSspAdvertiser: "nativoSspAdvertiser",
    dfpAdUnits: "dfpAdUnits",
    dfpData: "dfpData",
    gamCpdSsp: "gamCpdSsp",
    googleAdSenseSspReport: "googleAdSenseSspReport",
    googleAdSenseDirectSspReport: "googleAdSenseDirectSspReport",
    outbrainSspExternalId: "outbrainSspExternalId",
    dict_nativoSspAdvertiserAd: "dict_nativoSspAdvertiserAd",
    yahooSspMonetization: "yahooSspMonetization",
    sspConnectionsIntegrationTerceptData: "ssp_integration_tercept_data",
    sspConnectionsIntegrationTerceptRawData: "ssp_integration_tercept_raw_data",
    dimensionMappings: "dimensionMappings",
    trafficShapingMappings: "trafficShapingMappings",
    alert_triggered: "alert_triggered",
    confiantReport: "confiantReport",
    floor_gam_devices: "floor_gam_devices",
    floor_gam_country: "floor_gam_country",
    floor_gam_hash: "floor_gam_hash",
    events_impression_count: "events_impression_count",
    uniqueEntityStatistics: "uniqueEntityStatistics",
    bi_publisher: "bi_publisher",
    dynamicAllocationYieldGroup: "dynamicAllocationYieldGroup",
    dynamicAllocationAutoModeMerge: "dynamicAllocationAutoModeMerge",
    chatbot_reports_events: "chatbot_reports_events",
    taboola_ssp: "taboola_ssp",
    invoices: "invoices",
    s2sSubmittedConversionsLogs: "s2sSubmittedConversionsLogs",
} as const;
export type TableNames = Record<keyof typeof PRODUCTION_TABLE_NAMES, string>;

export const PRODUCTION_DICTIONARY_NAMES = {
    entityHierarchy: "entityHierarchy",
    entityRevenueBias: "entityRevenueBias",
    yieldManagerVersionName: "yieldManagerVersionName",
    yieldManagerDeployedAt: "yieldManagerDeployedAt",
    quality_blocking_type: "quality_blocking_type",
} as const;
export type DictionaryNames = Record<keyof typeof PRODUCTION_DICTIONARY_NAMES, string>;

export const EXPOSED_SPENT_API_AD_DIMENSION_IDS_MAPPING: Record<string, DimensionKeys> = {
    platform: "buy_report_platform",
    campaign_name: "buy_report_campaign_name",
    adset_name: "buy_report_adset_name",
    ad_name: "buy_report_ad_name",
    campaign_id: "buy_report_campaign_id",
    adset_id: "buy_report_adset_id",
    ad_id: "buy_report_ad_id",
    sub_account_id: "buy_report_subAccountId",
    sub_account_name: "buy_report_subAccountName",
};

export const EXPOSED_SPENT_API_PUBLISHER_DIMENSION_IDS_MAPPING: Record<string, DimensionKeys> = {
    site: "site_report_site",
    platform: "site_report_platform",
    site_name: "site_report_site_name",
    site_url: "site_report_site_url",
    site_id: "site_report_site_id",
    campaign_name: "site_report_campaign_name",
    campaign_id: "site_report_campaign_id",
    sub_account_id: "site_report_subAccountId",
    sub_account_name: "site_report_subAccountName",
};

export const EXPOSED_SPENT_API_SECTION_DIMENSION_IDS_MAPPING: Record<string, DimensionKeys> = {
    platform: "spent_by_section_report_platform",
    section_name: "spent_by_section_report_section_name",
    section_url: "spent_by_section_report_section_url",
    section_id: "spent_by_section_report_section_id",
    campaign_name: "spent_by_section_report_campaign_name",
    campaign_id: "spent_by_section_report_campaign_id",
    sub_account_id: "spent_by_section_report_subAccountId",
    sub_account_name: "spent_by_section_report_subAccountName",
};

export const EMPTY_FILTER_PLACEHOLDER = "empty_filter_placeholder";

export const WELCOME_METRICS = [
    //
    "session_starts",
    "impressions",
    "revenue",
    "session_start_rpm",
] as MainMetricsType[];
export const ORGANIZATION_BASED_URLS = [
    "/view/request-reduction/server/samples",
    "/view/request-reduction/server/threshold",
    "/view/request-reduction/server/health-report",
    "/view/request-reduction/server/rpb",
    "/view/request-reduction/server/uplift",
    "/request-reduction/server/settings",
    "/request-reduction/server/health",
    "/request-reduction/server/optimization",
    "/view/connected-ssps/discrepancy",
    "/view/connected-ssps/financial",
    "/connected-ssps/settings",
];

export const LATENCY_THRESHOLDS_TRAFFIC_SHAPING_SERVER = {
    p50: { yellow: 0.5, red: 0.75 },
    p95: { yellow: 0.75, red: 1.0 },
    p99: { yellow: 1.0, red: 1.5 },
} as const;

export const CH_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DOCS_HOST = "https://meek-toffee-74f088.netlify.app";
export const ALLOWED_TRAFFIC_SHAPING_MAPPINGS_DIMENSIONS_BIDDER_ID = "bidder_id";
export const ALLOWED_TRAFFIC_SHAPING_MAPPINGS_DIMENSIONS = [
    {
        id: ALLOWED_TRAFFIC_SHAPING_MAPPINGS_DIMENSIONS_BIDDER_ID,
        label: "Bidder",
    },
];

export const NOTIFICATION_GROUPS_EMAIL_KEY = "email" as const;
export const NOTIFICATION_GROUPS_SLACK_WEBHOOK_KEY = "slack_wh" as const;

export const API_UNSUPPORTED_YIELD_CALCULATED_METRICS = [
    "dynamicAllocation_predicted_revenue_r2_server",
    "dynamicAllocation_predicted_revenue_r2",
    "bias",
    "lost_rpm_hb",
    "min_cpm",
    "bias",
    "diff",
] as const;

export const DEFAULT_ISS_MOE_SIG = {
    moe: 0.02,
    significance: 0.95,
} as const;

export const RANGE_REALTIME_NOW = "RANGE_REALTIME_NOW" as const;
export type RANGE_REALTIME_NOW = typeof RANGE_REALTIME_NOW;

const ALL_RANGE_KEYS = [
    "Last 5 Minutes",
    "Last 10 Minutes",
    "Last 30 Minutes",
    "Last 1 Hour",
    "Last 3 Hours",
    "Last 6 Hours",
    "Last 12 Hours",
    "Last 24 Hours",
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "This Month",
    "Last Month",
    "Last Quarter",
    "Last Year",
    "Maximum",
] as const;

export type RangeKeys = (typeof ALL_RANGE_KEYS)[number];

export const RANGE_KEYS = {
    "Last 5 Minutes": "Last 5 Minutes",
    "Last 10 Minutes": "Last 10 Minutes",
    "Last 30 Minutes": "Last 30 Minutes",
    "Last 1 Hour": "Last 1 Hour",
    "Last 3 Hours": "Last 3 Hours",
    "Last 6 Hours": "Last 6 Hours",
    "Last 12 Hours": "Last 12 Hours",
    "Last 24 Hours": "Last 24 Hours",
    Today: "Today",
    Yesterday: "Yesterday",
    "Last 7 Days": "Last 7 Days",
    "Last 30 Days": "Last 30 Days",
    "This Month": "This Month",
    "Last Month": "Last Month",
    "Last Quarter": "Last Quarter",
    "Last Year": "Last Year",
    Maximum: "Maximum",
} as const;

export const SCHEDULE_FREQUENCY = {
    UNSCHEDULED: "UNSCHEDULED",
    DAILY: "DAILY",
    MONTHLY: "MONTHLY",
    WEEKLY: "WEEKLY",
} as const;

export type Frequency = keyof typeof SCHEDULE_FREQUENCY;
export const LAYOUTS_DOMAIN_REGEX = /^(?!:\/\/)([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}$/;
export const LAYOUTS_DOMAIN_REGEX_ERROR = "Domain should be in the format of 'example.com'";
export const LAYOUTS_PRODUCTION_URL = "https://assertive.page";

export const LAYOUTS_API_BASE_REGEX =
    /^(?:(?:https?):\/\/)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}(\/[^\s?#]*)?(\?[^\s#]*(?:&[^\s#]*)*)?(#[^\s]*)?$/;
export const LAYOUTS_API_BASE_REGEX_ERROR = "Api Url should be in the format of 'example.com/wp.json'";

export const BASE_JOB_PRIORITY = 2000;
export const SSP_WORKER_QUEUE_ATTEMPTS = 3;
export const SSP_BACKFILL_WORKER_QUEUE_NAME = "SspBackfillv8";
export const YM_DEPLOYMENT_SUMMARY_REPORT_QUEUE_NAME = "YMDeploymentSummaryReport";
export const YM_DEPLOYMENT_INTERNAL_NOTIFICATIONS_QUEUE_NAME = "YMDeploymentInternalNotifications";
export const NO_CUSTOMER_PRIORITY_PENALTY = 20000;
export const YYYY_MM_DD_FORMAT = "YYYY-MM-DD";
export const DFP_PRE_IMPORT_QUEUE_NAME = "DfpPreImportv8";
export const SUITE_AUTH_TOKEN = "suite_auth";

export const ADMIN_USER_ID = "p5T5CkFFhTwH9EbRf";
export const INTERNAL_API_USER_ID = "qo3bJfdoKnt8sSfeR";
export const INTERNAL_TEMPLATES_USER_ID = "dcrXp7sceturaTFxj";
export const INTERNAL_DEVELOPER_USER_ID = "7X9RhCCfesty2Erbp";

export const DEV_IDS = [INTERNAL_DEVELOPER_USER_ID, INTERNAL_API_USER_ID];
export const ADMIN_IDS = [ADMIN_USER_ID];
export const ADMIN_OR_DEV_IDS = [...DEV_IDS, ...ADMIN_IDS];

export const ROLLBAR_IGNORED_MESSAGES = [
    "Query was interrupted. Please refresh the report.",
    "The user aborted a request",
    "The operation was aborted",
    "AbortError",
    "ResizeObserver",
    "Fetch is aborted",
    "Request signal is aborted",
    "signal is aborted without reason",
    "Premature close",
    "missing devLockJWT",
    "Failed to fetch",
    "aborted",
];

export const FOOTER_HEIGHT = 50;
export const SPECIAL_SORT_BY_DIMENSIONS = [
    "hourOfDay",
    "dayOfWeek",
    "dayOfMonth",
    "request_reduction_server_samples_threshold",
    "fullDate",
];

export const sortBySpecialSortDimensions = (dimension: DimensionKeys, unsortedGroups: string[]): string[] => {
    switch (dimension) {
        case "request_reduction_server_samples_threshold": {
            return unsortedGroups.sort((a, b) => {
                const da = parseFloat(a as any);
                const db = parseFloat(b as any);
                return da - db;
            });
        }
        case "hourOfDay": {
            return unsortedGroups.sort((a, b) => {
                const ha = parseInt(String(a).replace("h", ""), 10);
                const hb = parseInt(String(b).replace("h", ""), 10);
                return ha - hb;
            });
        }

        case "dayOfWeek": {
            return unsortedGroups.sort((a, b) => {
                const dayLookup = {
                    Monday: 1,
                    Tuesday: 2,
                    Wednesday: 3,
                    Thursday: 4,
                    Friday: 5,
                    Saturday: 6,
                    Sunday: 7,
                };
                const va = (dayLookup as any)[a as any] ?? 0;
                const vb = (dayLookup as any)[b as any] ?? 0;
                return va - vb;
            });
        }

        case "dayOfMonth": {
            return unsortedGroups.sort((a, b) => {
                const da = parseInt(a as any, 10);
                const db = parseInt(b as any, 10);
                return da - db;
            });
        }
        case "fullDate": {
            return unsortedGroups.sort((a, b) => {
                const da = new Date(a as any).getTime();
                const db = new Date(b as any).getTime();
                return da - db;
            });
        }
        default: {
            throw new Error(`invalid dimension: ${dimension}`);
        }
    }
};
export const NOWRAP_CN = `tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap`;
