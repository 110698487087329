import { manualISSStats } from "../../../../utils/iss";
import { CalculationType, OptimizationObjective } from "../../calculated-metrics.types-input";
import { WebMetricMap } from "../calculated-metrics.types";
import { WebVitalsMetrics } from "../shared/web-vitals";

const sessionTimeSpan = 30;

export const calculatedWebMetrics = WebMetricMap({
    pageViews_per_session: {
        label: "Page Views per Session",
        explanation: "Average amount of monetized PageViews per Session",
        formula: m => m.pageViews / m.sessions,
        calculationType: CalculationType.Mean,
        timeSpan: sessionTimeSpan,
        objective: OptimizationObjective.Maximize,
    },
    pageViews_without_ads_ratio: {
        label: "Page Views without Ads",
        explanation: "Ratio of pageviews without ads",
        formula: m => (m.pageViews_ends - m.pageView_with_ads) / m.pageViews_ends,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    sessions_without_ads_ratio: {
        label: "Sessions without Ads",
        explanation: "Ratio of sessions without ads",
        formula: m => (m.sessions_ends - m.session_with_ads) / m.sessions_ends,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    ads_per_session: {
        label: "Ads per Session",
        explanation: "Average amount of ads per session",
        formula: m => m.impressions / m.sessions,
        calculationType: CalculationType.Mean,
        timeSpan: sessionTimeSpan,
        objective: OptimizationObjective.Maximize,
    },
    errors_per_session: {
        label: "Errors per Session",
        explanation: "Average amount of errors per session",
        formula: m => m.errors / m.sessions,
        calculationType: CalculationType.Mean,
        timeSpan: sessionTimeSpan,
        objective: OptimizationObjective.Minimize,
    },
    bounceRate: {
        label: "Bounce Rate",
        explanation: "Bounce Rate",
        formula: m => m.count_low_engagement / m.count_event_end,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    active_pageView_time_minutes: {
        label: "Active PV Minutes",
        explanation: "Average active page view time, in minutes",
        formula: m => m.sum_pageView_activeEngagementTime / m.pageViews / 1000 / 60,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Maximize,
    },
    active_session_time_minutes: {
        label: "Active Session Minutes",
        explanation: "Average active session time, in minutes",
        formula: m => m.sum_pageView_activeEngagementTime / m.sessions / 1000 / 60,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Maximize,
    },
    ads_per_pageView: {
        label: "Ads per Page View",
        explanation: "Ads per Page View",
        formula: m => m.impressions / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Maximize,
    },
    errors_per_pageView: {
        label: "Errors per Page View",
        explanation: "Errors per Page View",
        formula: m => m.errors / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
    },
    errors: {
        label: "Errors",
        explanation: "Count of errors",
        formula: m => m.errors,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Minimize,
    },
    mean_scrollY: {
        label: "Scroll Y",
        explanation: "Average vertical scroll.",
        formula: m => m.scrollY / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Maximize,
    },
    ...WebVitalsMetrics,
    pageViews: {
        label: "PageViews",
        explanation: "Page views",
        formula: m => m.pageViews,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Maximize,
    },
    sessions: {
        label: "Unique Sessions",
        explanation: "Unique sessions",
        formula: m => m.sessions,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Maximize,
    },
    session_starts: {
        label: "New Sessions",
        explanation: "New Session",
        formula: m => m.session_starts,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Maximize,
    },
    pageView_starts: {
        label: "New Page Views",
        explanation: "New Page Views",
        formula: m => m.pageView_starts,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Maximize,
    },
    acquisition_cost: {
        label: "Acquisition Cost",
        explanation: "Acquisition cost based on the configured URL parameters.",
        formula: m => m.acquisition_cost,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Minimize,
    },
    acquisition_cost_measurable_session_starts: {
        label: "Acquisition Cost Available",
        explanation: "Count of events where acquisition cost is available.",
        formula: m => m.acquisition_cost_measurable_session_starts,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.Minimize,
    },
    avg_network_time: {
        label: "Avg. Network Time",
        explanation:
            "The average time, measured in milliseconds, taken to establish a connection with the website server.",
        formula: m => m.nav_network_time / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
        issStats: manualISSStats.mock_web_avg,
    },
    avg_transfer_time: {
        label: "Avg. Transfer Time",
        explanation:
            "The average time, measured in milliseconds, that the browser takes to download the response from the server for each page view.",
        formula: m => m.nav_transfer_time / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
        issStats: manualISSStats.mock_web_avg,
    },
    avg_dom_completion_time: {
        label: "Avg. DOM Completion Time",
        explanation:
            "This measures the average time it takes for a webpage to load its main content and be ready for interaction, in milliseconds. It's about how quickly the text, images, and interactive elements are fully loaded and usable. A lower time is better, as it means the website becomes ready to use more quickly, leading to a smoother experience for the user.",
        formula: m => m.nav_dom_content_loading_time / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
        issStats: manualISSStats.mock_web_avg,
    },
    avg_page_load_time: {
        label: "Avg. Page Load Time",
        explanation:
            "This is the average time in milliseconds it takes for a full webpage to load and display. It includes everything from text and images to scripts and interactive features. A shorter load time is better, as it means the website loads faster, providing a quicker and more enjoyable experience for the user.",
        formula: m => m.nav_dom_content_loading_time / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
        issStats: manualISSStats.mock_web_avg,
    },
    avg_server_time: {
        label: "Avg. Server Time",
        explanation:
            "This measures the average time, in milliseconds, that the server needs to prepare and generate a webpage before it starts sending it to the user's browser. It's about how quickly the server can get the page ready. A lower time is better, as it means webpages start loading on the user's side more quickly, leading to a faster browsing experience.",
        formula: m => m.nav_server_time / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
        issStats: manualISSStats.mock_web_avg,
    },
    avg_dom_processing_time: {
        label: "Avg. DOM Processing Time",
        explanation:
            "This is the average processing time, in milliseconds, that the browser takes to process and display the webpage after getting all the necessary data from the server. It's about how quickly the browser turns this data into a visible and interactive webpage. A lower time is better as it means the website becomes fully functional and ready to use faster, improving the browsing experience.",
        formula: m => m.nav_dom_processing_time / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
        issStats: manualISSStats.mock_web_avg,
    },
    avg_on_load_time: {
        label: "Avg. On Load Time",
        explanation:
            "This measures the average processing time, in milliseconds, for the browser to fully render and display all the elements of the webpage. It's about how quickly everything on the page, like text, images, and buttons, becomes visible and ready to use after you open the page. A shorter time is better, as it means the webpage loads completely in less time, offering a quicker and more efficient browsing experience.",
        formula: m => m.nav_unload_event_time / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
        issStats: manualISSStats.mock_web_avg,
    },
    avg_encoded_body_size: {
        label: "Avg. Encoded Body Size",
        explanation:
            "This measures the average size, in kilobytes (kb), of all the content that is downloaded from a server to display a webpage. It includes everything like text, images, and videos in their compressed (encoded) form. A smaller size is better, as it means the webpage uses less data, which can lead to faster loading times and less strain on the user's internet connection.",
        formula: m => m.nav_encoded_body_size / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
        issStats: manualISSStats.mock_web_avg,
    },
    avg_decoded_body_size: {
        label: "Avg. Decoded Body Size",
        explanation:
            "This indicates the average size, in kilobytes (kb), of all the webpage content after it has been decompressed and is ready to be displayed. It encompasses all elements like text, images, and videos in their full, usable form. A lower size is preferable, as it means the webpage is lighter and can load faster, improving the user's experience by reducing loading times.",
        formula: m => m.nav_decoded_body_size / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
        issStats: manualISSStats.mock_web_avg,
    },
    avg_transfer_size: {
        label: "Avg. Transfer Size",
        explanation:
            "This measures the average size, in kilobytes (kb), of the HTTP headers for each webpage. HTTP headers are part of the data sent and received when a webpage is loaded, containing information like the type of browser used and the type of content expected. A smaller size is better, as it means less data is being sent and received, which can help the webpage load faster and more efficiently.",
        formula: m => m.nav_transfer_size / m.pageViews,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
        issStats: manualISSStats.mock_web_avg,
    },
});
