import type { DimensionKeys } from "../dimension-keys.constants";
import type { RequestReductionServerSamplesPredictMetricsType } from "../metrics.constants";

export const EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_DIMENSION_KEYS = [
    "bidder",
    "bidder_mapped",
    "country",
    "publisher_id",
    "placement_id",
    "request_type",
    "format",
    "sizes",
    "platform",
    "bundle",
    "os",
    "browser",
    "device",
    "has_gps",
    "codebase_version",
    "version",
    "device_language",
    "utc_offset",
    "gdpr",
    "us_privacy",
    "video_startdelay",
    "video_skip",
    "video_rewarded",
    "url",
    "video_playback_method",
    "site_cat",
    "unit_types",
    "page_cat",
    "has_omsdk",
    "has_age",
    "has_gender",
    "has_mraid",
    "has_vast",
    "has_vpaid",
    "has_consent",
    "is_qps",
    "datacenter",
    "domain",
    "custom1",
    "custom2",
    "custom3",
    "bid_confusion_matrix",
    "win_confusion_matrix",
] as const;

export type ExposedRequestReductionServerSamplesPredictDimensionKeys =
    (typeof EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_DIMENSION_KEYS)[number];

export const EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_DIMENSION_IDS_MAPPING: Record<
    ExposedRequestReductionServerSamplesPredictDimensionKeys,
    DimensionKeys
> = {
    bidder: "request_reduction_server_samples_bidder",
    bidder_mapped: "request_reduction_server_samples_bidder_mapped",
    country: "request_reduction_server_samples_country",
    publisher_id: "request_reduction_server_samples_publisher_id",
    placement_id: "request_reduction_server_samples_placement_id",
    request_type: "request_reduction_server_samples_request_type",
    format: "request_reduction_server_samples_format",
    sizes: "request_reduction_server_samples_sizes",
    platform: "request_reduction_server_samples_platform",
    bundle: "request_reduction_server_samples_bundle",
    os: "request_reduction_server_samples_os",
    browser: "request_reduction_server_samples_browser",
    device: "request_reduction_server_samples_device",
    has_gps: "request_reduction_server_samples_has_gps",
    codebase_version: "request_reduction_server_samples_codebase_version",
    version: "request_reduction_server_samples_version",
    device_language: "request_reduction_server_samples_device_language",
    utc_offset: "request_reduction_server_samples_utc_offset",
    gdpr: "request_reduction_server_samples_gdpr",
    us_privacy: "request_reduction_server_samples_us_privacy",
    video_startdelay: "request_reduction_server_samples_video_startdelay",
    video_skip: "request_reduction_server_samples_video_skip",
    video_rewarded: "request_reduction_server_samples_video_rewarded",
    url: "request_reduction_server_samples_url",
    video_playback_method: "request_reduction_server_samples_video_playback_method",
    site_cat: "request_reduction_server_samples_site_cat",
    unit_types: "request_reduction_server_samples_unit_types",
    page_cat: "request_reduction_server_samples_page_cat",
    has_omsdk: "request_reduction_server_samples_has_omsdk",
    has_age: "request_reduction_server_samples_has_age",
    has_gender: "request_reduction_server_samples_has_gender",
    has_mraid: "request_reduction_server_samples_has_mraid",
    has_vast: "request_reduction_server_samples_has_vast",
    has_vpaid: "request_reduction_server_samples_has_vpaid",
    has_consent: "request_reduction_server_samples_has_consent",
    is_qps: "request_reduction_server_samples_is_qps",
    datacenter: "request_reduction_server_samples_datacenter",
    domain: "request_reduction_server_samples_domain",
    custom1: "request_reduction_server_samples_custom1",
    custom2: "request_reduction_server_samples_custom2",
    custom3: "request_reduction_server_samples_custom3",
    bid_confusion_matrix: "request_reduction_server_samples_bid_confusion_matrix",
    win_confusion_matrix: "request_reduction_server_samples_win_confusion_matrix",
};

export const EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_METRICS_KEYS = [
    "requests",
    "win_rate",
    "bid_rate",
    "wins",
    "bids",
    "win_revenue",
    "bid_revenue",
    "win_cpm",
    "bid_cpm",
    "req_reduction_rate",
    "lost_bids_rate",
    "lost_wins_rate",
    "lost_revenue_rate",
    "missed_revenue",
    "predicts",
    "rpb",
    "reduction_efficiency",
    "bid_accuracy",
    "bid_precision",
    "bid_recall",
    "bid_specificity",
    "win_accuracy",
    "win_precision",
    "win_recall",
    "win_specificity",
] as const;

export type ExposedRequestReductionServerSamplesPredictMetricsKeys =
    (typeof EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_METRICS_KEYS)[number];

export const EXPOSED_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_METRICS_IDS_MAPPING: Record<
    ExposedRequestReductionServerSamplesPredictMetricsKeys,
    RequestReductionServerSamplesPredictMetricsType
> = {
    requests: "request_reduction_server_samples_requests",
    win_rate: "request_reduction_server_samples_win_rate",
    bid_rate: "request_reduction_server_samples_bid_rate",
    wins: "request_reduction_server_samples_wins",
    bids: "request_reduction_server_samples_bids",
    win_revenue: "request_reduction_server_samples_win_revenue",
    bid_revenue: "request_reduction_server_samples_bid_revenue",
    win_cpm: "request_reduction_server_samples_win_cpm",
    bid_cpm: "request_reduction_server_samples_bid_cpm",
    req_reduction_rate: "request_reduction_server_samples_req_reduction_rate",
    lost_bids_rate: "request_reduction_server_samples_lost_bids_rate",
    lost_wins_rate: "request_reduction_server_samples_lost_wins_rate",
    lost_revenue_rate: "request_reduction_server_samples_lost_revenue_rate",
    missed_revenue: "request_reduction_server_samples_missed_revenue",
    predicts: "request_reduction_server_samples_predicts",
    rpb: "request_reduction_server_samples_rpb",
    reduction_efficiency: "request_reduction_server_samples_reduction_efficiency",
    bid_accuracy: "request_reduction_server_samples_bid_accuracy",
    bid_precision: "request_reduction_server_samples_bid_precision",
    bid_recall: "request_reduction_server_samples_bid_recall",
    bid_specificity: "request_reduction_server_samples_bid_specificity",
    win_accuracy: "request_reduction_server_samples_win_accuracy",
    win_precision: "request_reduction_server_samples_win_precision",
    win_recall: "request_reduction_server_samples_win_recall",
    win_specificity: "request_reduction_server_samples_win_specificity",
};
