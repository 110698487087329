import { builder } from "@builder.io/react";
import "components/CustomButton.builder";
import "components/GatsbyLink.builder";
import "components/blocks/hero/Hero.builder";
import "components/blocks/allInOneSolutions/allInOneSolutions.builder";
import "components/blocks/yieldIntelligence/index.builder";
import "components/blocks/yieldOptimizer/index.builder";
import "components/blocks/yieldManager/index.builder";
import "components/blocks/yieldTraffic/index.builder";
import "components/blocks/trafficAcquisition/index.builder";
import "components/blocks/solutionType/index.builder";
import "components/blocks/statistics/index.builder";
import "components/blocks/clients/home-clients.builder";
import "components/blocks/clients/configurable-clients.builder";
import "components/blocks/allInOneSolutions/homeAllInOneSolutions.builder";
import "components/blocks/allInOneSolutions/yieldOptimizerAIO.builder";
import "components/blocks/stayTuned/stayTuned.builder";
import "components/blocks/banner/banner.builder";
import "components/yieldIntelligenceProductHero/yieldIntelligenceProductHero.builder";
import "components/oldNewGame/oldNewGame.builder";
import "components/yieldIntelligenceCards/yieldIntelligenceCards.builder";
import "components/yieldIntelligenceProductBlocks/yieldIntelligenceProductBlocks.builder";
import "components/blocks/nextGeneration/index.builder";
import "components/blocks/strategy/index.builder";
import "components/yieldIntelligenceWinGame/yieldIntelligenceWinGame.builder";
import "components/yieldOptimizerProductHero/yieldOptimizerProductHero.builder";
import "components/trafficAcquisitionAllAbout/trafficAcquisitionAllAbout.builder";
import "components/trafficAcquisitionWinGame/trafficAcquisitionWinGame.builder";
import "components/trafficAcquisitionSpendAttribution/trafficAcquisitionSpendAttribution.builder";
import "components/trafficAcquisitionExtraMile/trafficAcquisitionExtraMile.builder";
import "components/trafficAcquisionIntegration/trafficAcquisitionIntegration.builder";
import "components/blocks/optimizerCarousal/index.builder";
import "components/freeDemo/freeDemo.builder";
import "components/yieldOptimizerBidReduction/yieldOptimizerBidReduction.builder";
import "components/blocks/exclusiveAY/index.builder";
import "components/yieldOptimizerCTA/yieldOptimizerCTA.builder";
import "components/yieldManagerProductHero/yieldManagerProductHero.builder";
import "components/yieldManagerProductBlocks/yieldManagerProductBlocks.builder";
import "components/blocks/empowerBusiness/index.builder";
import "components/yieldManagerCTA/yieldManagerCTA.builder";
import "components/trafficShapingProductHero/trafficShapingProductHero.builder";
import "components/trafficShapingComponentBlocks/trafficShapingComponentBlocks.builder";
import "components/ContentDark.builder";
import "components/yieldIntelligenceRealTime/yieldIntelligenceRealTime.builder";
import "components/yieldOptimizerReadyToWin/yieldOptimizerReadyToWin.builder";
import "components/yieldOptimizerAICustomization/yieldOptimizerAICustomization.builder";
import "components/yieldOptimizerAccurateRevenuePredictions/yieldOptimizerAccurateRevenuePredictions.builder";
import "components/yieldOptimizerDynamicFloor/yieldOptimizerDynamicFloor.builder";
import "components/yieldManagerReadyToWin/yieldManagerReadyToWin.builder";
import "components/yieldManagerLifetimeValue/yieldManagerLifetimeValue.builder";
import "components/yieldManagerContinuousOptimization/yieldManagerContinuousOptimization.builder";
import "components/yieldManagerOptimalVendorPerformance/yieldManagerOptimalVendorPerformance.builder";
import "components/yieldManagerDataDrivenManagement/yieldManagerDataDrivenManagement.builder";
import "components/yieldManagerBidCaching/yieldManagerBidCaching.builder";
import "components/yieldManagerRealtimeExperiment/yieldManagerRealtimeExperiment.builder";
import "components/trafficShapingChart/trafficShapingChart.builder";
import "components/trafficShapingWinGame/trafficShapingWinGame.builder";
import "components/trafficShapingSspDspTable/trafficShapingSspDspTable.builder";
import "components/trafficShapingPlayNewGame/trafficShapingPlayNewGame.builder";
import "components/yieldManagerNoCode/yieldManagerNoCode.builder";
import "components/yieldManagerScalability/yieldManagerScalability.builder";
import "components/yieldManagerPersonalized/yieldManagerPersonalized.builder";
import "components/yieldManagerAI/yieldManagerAI.builder";
import "components/yieldManagerProgrammable/yieldManagerProgrammable.builder";
import "components/trafficAcquisitionProductHero/trafficAcquisitionPrductHero.builer";
import "components/appsAndIntegrationHeader/appsAndIntegrationHeader.builder";
import "components/appsAndIntegrationConnections/appsAndIntegrationConnections.builder";
import "components/aboutUsOurStory/aboutUsOurStory.builder";
import "components/aboutUsHistorySpiral/aboutUsHistorySpiral.builder";
import "components/aboutUsOurVision/aboutUsOurVision.builder";
import "components/aboutUsOurValues/aboutUsOurValues.builder";
import "components/aboutUsWhyName/aboutUsWhyName.builder";
import "components/aboutUsFromTheFounder/aboutUsFromTheFounder.builder";
import "components/aboutUsMakeDataWork/aboutUsMakeDataWork.builder";
import "components/aboutUsOurDreamTeam/aboutUsOurDreamTeam.builder";
import "components/aboutUsEmpoweredCustomers/aboutUsEmpoweredCustomers.builder";
import "components/allRevenueSourceHero/allRevenueSourceHero.builder";
import "components/allRevenueSourceMatchData/allRevenueSourceMatchData.builder";
import "components/allRevenueTimeIsMoney/allRevenueTimeIsMoney.builder";
import "components/blocks/testimonial/index.builder";
import "components/sliceAndDiceHero/sliceAndDiceHero.builder";
import "components/sliceAndDiceCrossCorrelating/sliceAndDiceCrossCorrelating.builder";
import "components/sliceAndDiceUnlimitedCrossAnalysis/sliceAndDiceUnlimitedCrossAnalysis.builder";
import "components/sliceAndDiceCustomFilters/sliceAndDiceCustomFilters.builder";
import "components/sliceAndDiceSharingEasy/sliceAndDiceSharingEasy.builder";
import "components/stillNotFindingWhatYouNeed/stillNotFindingWhatYouNeed.builder";
import "components/dashboardHero/dashboardHero.builder";
import "components/uniqueDataForUniqueTeam/uniqueDataForUniqueTeam.builder";
import "components/dashboardCustomizableDashboards/dashboardCustomizableDashboards.builder";
import "components/dashboardRealTime/dashboardRealTime.builder";
import "components/dashboardAccessManagement/dashboardAccessManagement.builder";
import "components/dashboardTemplates/dashboardTemplates.builder";
import "components/monitoringHero/monitoringHero.builder";
import "components/monitoringSayHello/monitoringSayHello.builder";
import "components/monitoringTraffic/monitoringTraffic.builder";
import "components/monitoringDetect/monitoringDetect.builder";
import "components/monitoringNotification/monitoringNotification.builder";
import "components/monitoringAlerts/monitoringAlerts.builder";
import "components/industryInsightsHero/industryInsightsHero.builder";
import "components/beSmartBeDataDriven/beSmartBeDataDriven.builder";
import "components/industryInsightsGainIntelligence/industryInsightsGainIntelligence.builder";
import "components/industryInsightsFindWorldwide/industryInsightsFindWorldwide.builder";
import "components/industryInsightsFocusEfforts/industryInsightsFocusEfforts.builder";
import "components/industryInsightsTemplates/industryInsightsTemplates.builder";
import "components/weAimToProvideQualityData/weAimToProvideQualityData.builder";
import "components/allRevenuePreBid/allRevenuePreBid.builder";
import "components/ayGlossaryKnowledgeDesk/ayGlossaryKnowledgeDesk.builder";
import "components/ayGlossaryHaventFound/ayGlossaryHaventFound.builder";
import "components/ayGlossaryAskedQuestions/ayGlossaryAskedQuestions.builder";
import "components/byCompanyTypeHero/byCompanyTypeHero.builder";
import "components/byCompanyTypeExploreSolutions/byCompanyTypeExploreSolutions.builder";
import "components/byCompanyTypeDataIsPower/byCompanyTypeDataIsPower.builder";
import "components/byCompanyTypeSingleSourceOfTruth/byCompanyTypeSingleSourceOfTruth.builder";
import "components/byCompanyTypeDynamicFlooring/byCompanyTypeDynamicFlooring.builder";
import "components/byCompanyTypeIntelligentBidReduction/byCompanyTypeIntelligentBidReduction.builder";
import "components/byCompanyTypeMaximizeRevenue/byCompanyTypeMaximizeRevenue.builder";
import "components/byCompanyTypeReliableTesting/byCompanyTypeReliableTesting.builder";
import "components/byCompanyTypeBidPooling/byCompanyTypeBidPooling.builder";
import "components/byCompanyTypeBidCaching/byCompanyTypeBidCaching.builder";
import "components/byCompanyTypeYieldSolution/byCompanyTypeYieldSolution.builder";
import "components/byCompanyTypeChangeTheGame/byCompanyTypeChangeTheGame.builder";
import "components/successStoriesPromote/successStoriesPromote.builder";
import "components/byCompanyTypeDataIsPowerSsp/byCompanyTypeDataIsPowerSsp.builder";
import "components/byCompanyTypeWeSupportGreenerTraffic/byCompanyTypeWeSupportGreenerTraffic.builder";
import "components/byCompanyTypeSoundsInteresting/byCompanyTypeSoundsInteresting.builder";
import "components/byCompanyTypeOurMissionSuccessful/byCompanyTypeOurMissionSuccessful.builder";
import "components/successStoriesHero/successStoriesHero.builder";
import "components/successStoriesCompanies/successStoriesCompanies.builder";
import "components/ayInTheNews/ayInTheNews.builder";
import "components/blocks/SubscribeToAyNews/SubscribeToAyNews.builder";
import "components/HelpCentreHero/HelpCentreHero.builder";
import "components/HelpCentreFAQ/HelpCentreFAQ.builder";
import "components/HelpCentreStillHaveQuestions/HelpCentreStillHaveQuestions.builder";
import "components/HelpCentreExploreFurther/HelpCentreExploreFurther.builder";
import "components/ayHelpCenterHero/ayHelpCenterHero.builder";
import "components/ayHelpCenterCategories/ayHelpCenterCategories.builder";
import "components/ayHelpCenterNeedMoreHelp/ayHelpCenterNeedMoreHelp.builder";
import "components/careersHero/careersHero.builder";
import "components/careersAvailableJobs/careersAvailableJobs.builder";
import "components/careersGeneralApplication/careersGeneralApplication.builder";
import "components/careerSuccessApplication/careerSuccessApplication.builder";
import "components/academyHero/academyHero.builder";
import "components/academyPosts/academyPosts.builder";
import "components/byTeamTypeHero/byTeamTypeHero.builder";
import "components/byTeamTypeSolutions/byTeamTypeSolutions.builder";
import "components/byTeamTypeAdOperations/byTeamTypeAdOperations.builder";
import "components/byTeamTypeIncreaseProductivity/byTeamTypeIncreaseProductivity.builder";
import "components/byTeamTypeEnhanceWorth/byTeamTypeEnhanceWorth.builder";
import "components/byTeamTypeSmartWay/byTeamTypeSmartWay.builder";
import "components/byTeamTypeGetMoreTraffic/byTeamTypeGetMoreTraffic.builder";
import "components/byTeamTypeControlYourAd/byTeamTypeControlYourAd.builder";
import "components/byTeamTypeProgramaticAdOptimization/byTeamTypeProgramaticAdOptimization.builder";
import "components/byTeamTypeSmarterChoice/byTeamTypeSmarterChoice.builder";
import "components/byTeamTypeMachineLearning/byTeamTypeMachineLearning.builder";
import "components/byTeamTypeAudienceDevelopmentMessages/byTeamTypeAudienceDevelopmentMessages.builder";
import "components/byTeamTypeMediaBuyingMessages/byTeamTypeMediaBuyingMessages.builder";
import "components/byTeamTypeProductTeamsMessages/byTeamTypeProductTeamsMessages.builder";
import "components/aboutUsJoinOurTeam/aboutUsJoinOurTeam.builder";
import "components/HelpfulResources/HelpfulResources.builder";
import "components/ayGlossaryKnowledgeDeskHero/ayGlossaryKnowledgeDeskHero.builder";
import "components/ayGlossaryKnowledgeDeskMetrics/ayGlossaryKnowledgeDeskMetrics.builder";
import "components/aySupportHero/aySupportHero.builder";
import "components/aySupportContent/aySupportContent.builder";
import "components/successStoriesCarousel/successStoriesCarousel.builder";
import "components/makeYourDataWorkForYou/makeYourDataWorkForYou.builder";
import "components/trafficShapingReadyToWin/trafficShapingReadyToWin.builder";
import "components/yieldIntelligenceExploreThePowerOfRealTimeData/yieldIntelligenceExploreThePowerOfRealTimeData.builder";
import "components/yieldOptimizerHandsFreeAi/yieldOptimizerHandsFreeAi.builder";
import "components/yieldManagerBoostAgility/yieldManagerBoostAgility.builder";
import "components/trafficShapingShapeAndOptimize/trafficShapingShapeAndOptimize.builder";
import "components/faq/faq.builder";
import "components/stillGotMoreQuestions/stillGotMoreQuestions.builder";
import "components/pricingHero/pricingHero.builder";
import "components/pricingProducts/pricingProducts.builder";
import "components/ayPrebidProductHero/ayPrebidProductHero.builder";
import "components/aiTrafficShapingForPublishers/aiTrafficShapingForPublishers.builder";
import "components/uniqueFeaturesAyPrebid/uniqueFeaturesAyPrebid.builder";
import "components/aiDynamicFlooring/aiDynamicFlooring.builder";
import "components/identifySolutionIntegration/identifySolutionIntegration.builder";
import "components/accessAdvancedYieldOptimizationFeatures/accessAdvancedYieldOptimizationFeatures.builder";
import "components/higherCPMRevenue/higherCPMRevenue.builder";
import "components/blocks/empowerBusinessAyPrebid/index.builder";
import "components/readyToPlayANewGame/readyToPlayANewGame.builder";
import "components/blocks/ayPrebid/index.builder";
import "components/trafficShapingForPubProductHero/trafficShapingForPubProductHero.builder";
import "components/trafficShapingForPubsWhy/trafficShapingForPubsWhy.builder";
import "components/trafficShapingForPubsPlayNewGame/trafficShapingForPubsPlayNewGame.builder";
builder.init("94c784e9b31a42ac9803370b618d221e");
