import type { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";
import "./clients.scss";
import nineGag from "./images/9gag.svg";
import apartmentTherapy from "./images/apartment-therapy.svg";
import boons from "./images/boons.svg";
import connatix from "./images/connatix.svg";
import contentIq from "./images/content-iq.svg";
import futbolSites from "./images/futbol-sites.svg";
import gosocial from "./images/gosocial.svg";
import gradient from "./images/gradient.svg";
import gumgum from "./images/gumgum.svg";
import heartAutos from "./images/heart-autos.svg";
import lad from "./images/lad.svg";
import leeEnterprise from "./images/lee-enterprise.svg";
import netpoint from "./images/netpoint.svg";
import shinez from "./images/shinez.svg";
import WHE from "./images/WHE.svg";

const Clients: FC<{
    title?: string;
    topmargin?: boolean;
    bg?: string;
    showLink?: boolean;
    linkText?: string;
    linkTo?: string;
    linkColor?: string;
}> = ({ title, topmargin, bg, showLink, linkText, linkTo, linkColor }) => {
    return (
        //top margin for new and old game for yield manager page
        <div className={`component-wrapper ${topmargin ? "mt-180" : ""} change-margin-to-padding ${bg} pt-5`}>
            <img src={gradient} className={`gradient right`} style={{ top: "-250px" }} alt="gradient" />
            <section className="enable-animation marquee-clients">
                {title ? <h2 className="text-center mb-5">{title}</h2> : ""}
                <div className="marquee ">
                    <ul className="marquee__group">
                        <li>
                            <img src={nineGag} alt="" />
                        </li>
                        <li>
                            <img src={apartmentTherapy} alt="" />
                        </li>
                        <li>
                            <img src={connatix} alt="" />
                        </li>
                        <li>
                            <img src={heartAutos} alt="" />
                        </li>
                        <li>
                            <img src={futbolSites} alt="" />
                        </li>
                        <li>
                            <img
                                style={{
                                    marginBottom: "17px",
                                }}
                                src={shinez}
                                alt=""
                            />
                        </li>
                        <li>
                            <StaticImage src="images/rough-maps.png" alt="" />
                        </li>
                        <li
                            style={{
                                marginBottom: "5px",
                            }}
                        >
                            <StaticImage src="images/factinate.png" alt="" />
                        </li>
                        <li>
                            <StaticImage src="images/blogic.png" alt="" />
                        </li>
                        <li
                            style={{
                                marginBottom: "15px",
                            }}
                        >
                            <img src={gumgum} alt="" />
                        </li>
                        <li>
                            <img src={WHE} alt="" />
                        </li>
                        <li>
                            <img src={lad} alt="" />
                        </li>
                        <li>
                            <img src={contentIq} alt="" />
                        </li>
                        <li
                            style={{
                                marginBottom: "8px",
                            }}
                        >
                            <img src={boons} alt="" />
                        </li>
                        <li>
                            <img src={gosocial} alt="" />
                        </li>
                        <li>
                            <img src={netpoint} alt="" />
                        </li>
                        <li>
                            <img src={leeEnterprise} alt="" />
                        </li>
                    </ul>
                    <ul className="marquee__group aria_hidden" aria-hidden="true">
                        <li>
                            <img src={nineGag} alt="" />
                        </li>
                        <li>
                            <img src={apartmentTherapy} alt="" />
                        </li>
                        <li>
                            <img src={connatix} alt="" />
                        </li>
                        <li>
                            <img src={heartAutos} alt="" />
                        </li>
                        <li>
                            <img src={futbolSites} alt="" />
                        </li>
                        <li>
                            <img
                                style={{
                                    marginBottom: "17px",
                                }}
                                src={shinez}
                                alt=""
                            />
                        </li>
                        <li>
                            <StaticImage src="images/rough-maps.png" alt="" />
                        </li>
                        <li
                            style={{
                                marginBottom: "5px",
                            }}
                        >
                            <StaticImage src="images/factinate.png" alt="" />
                        </li>
                        <li>
                            <StaticImage src="images/blogic.png" alt="" />
                        </li>
                        <li
                            style={{
                                marginBottom: "15px",
                            }}
                        >
                            <img src={gumgum} alt="" />
                        </li>
                        <li>
                            <img src={WHE} alt="" />
                        </li>
                        <li>
                            <img src={lad} alt="" />
                        </li>
                        <li>
                            <img src={contentIq} alt="" />
                        </li>
                        <li
                            style={{
                                marginBottom: "8px",
                            }}
                        >
                            <img src={boons} alt="" />
                        </li>
                        <li>
                            <img src={gosocial} alt="" />
                        </li>
                        <li>
                            <img src={netpoint} alt="" />
                        </li>
                        <li>
                            <img src={leeEnterprise} alt="" />
                        </li>
                    </ul>
                </div>
            </section>
            {showLink && linkColor === "green" && (
                <a href={linkTo} className="link-wrapper">
                    <span className="link-text">{linkText}</span>
                    <StaticImage src="./images/arrow.svg" alt="arrow" />
                </a>
            )}
            {showLink && linkColor === "yellow" && (
                <a href={linkTo} className="link-wrapper">
                    <span className="link-text-yellow">{linkText}</span>
                    <StaticImage src="./images/yellow-arrow.svg" alt="arrow" />
                </a>
            )}
        </div>
    );
};

export default Clients;
