import { graphql, Link, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import intercom from "images/intercom.svg";
import linkedin from "images/linkedin.svg";
import prebidPartner from "images/prebid-partner.svg";
import youtube from "images/youtube.svg";
import type { TFooter } from "types/common";
import "./Footer.scss";
import { AnimatedLogo } from "./AnimatedLogo";
import { openIntercomChat } from "./intercom";
import * as styles from "./footer.module.scss";

const Footer = () => {
    const data: {
        footer: TFooter;
    } = useStaticQuery(graphql`
        query footerQuery {
            footer {
                title
                description
                resources {
                    title
                    link
                }
                products {
                    title
                    link
                }
                assertiveYield {
                    title
                    link
                }
            }
        }
    `);

    const { footer: footerData } = data;

    const location = useLocation();

    const showHr = !["/success-stories/"].includes(location.pathname);

    const textColorClassName = "text-white";
    const linkColorClassName = styles.copy_links_hover_light;

    return (
        <>
            <div className={["footer", styles.dark_footer_bg].join(" ")}>
                <div className="container">
                    <footer>
                        <div className="container">
                            <div className="row ">
                                <div className="col-12  col-lg-4  mb-5 d-none d-lg-block">
                                    <h6 className={[textColorClassName, "mb-4"].join(" ")}>{footerData.title}</h6>

                                    <p className={textColorClassName}>{footerData.description}</p>
                                </div>

                                <div className="col-6 col-sm-4 col-lg-2 order-1 offset-xl-1 mb-md-0 mb-4">
                                    <ul className="list-group list-unstyled">
                                        <li className="mb-3">
                                            <h6 className={textColorClassName}>Assertive Yield</h6>
                                        </li>
                                        {footerData.assertiveYield.map(({ title, link }) => (
                                            <li key={title}>
                                                <Link to={link} className={textColorClassName}>
                                                    {title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className="col-6 col-sm-4 col-lg-2 order-3 order-sm-2 mb-4">
                                    <ul className="list-group list-unstyled">
                                        <li className="mb-3">
                                            <h6 className={textColorClassName}>Resources</h6>
                                        </li>

                                        {footerData.resources.map(({ title, link }) => {
                                            const isSupportLink = link === "SUPPORT SCRIPT(do not touch this please)";

                                            return (
                                                <li key={title}>
                                                    <Link
                                                        onClick={ev => {
                                                            if (isSupportLink) {
                                                                ev.preventDefault();
                                                                openIntercomChat();
                                                            }
                                                        }}
                                                        to={!isSupportLink ? link : "#"}
                                                        className={textColorClassName}
                                                    >
                                                        {title}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>

                                <div className="col-6 col-sm-4 col-lg-2 order-2 order-sm-3 mb-md-0 mb-4">
                                    <ul className="list-group list-unstyled mb-4">
                                        <li className="mb-3">
                                            <h6 className={textColorClassName}>Products</h6>
                                        </li>
                                        {footerData.products.map(({ title, link }) => (
                                            <li key={title}>
                                                <Link to={link} className={textColorClassName}>
                                                    {title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                    <img src={prebidPartner} alt="" />
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>

                <div className="container copyright">
                    <div className="row ">
                        <div className="col-12 py-3 d-flex align-items-center social-icons">
                            {showHr && <hr className={["mt-4 flex-grow-1", textColorClassName].join(" ")} />}
                            <div className="badge ">
                                <span role="button" onClick={openIntercomChat}>
                                    <img width="24" height="24" src={intercom} alt="intercom logo" />
                                </span>
                                <a
                                    href="https://www.linkedin.com/company/assertive-yield/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img width="24" height="24" src={linkedin} alt="linkedin logo" />
                                </a>
                                <a href="https://youtube.com/@assertiveyield" target="_blank" rel="noreferrer">
                                    <img width="29" height="22" src={youtube} alt="youtube logo" />
                                </a>
                            </div>
                            {showHr && <hr className={["flex-grow-1", textColorClassName].join(" ")} />}
                        </div>
                        <div className="col-12 mt-4 text-center">
                            <Link to="/" aria-label="Assertive Yield Logo" className="footer-logo">
                                <AnimatedLogo type="light" />
                            </Link>
                            <div className={["mt-3", styles.grey_text].join(" ")}>
                                Copyright © 2023 Assertive Yield B.V
                            </div>
                            <div className="copy-links mt-4 ">
                                <Link className={linkColorClassName} to="/terms" rel="nofollow">
                                    Terms
                                </Link>
                                <Link className={linkColorClassName} to="/privacy" rel="nofollow">
                                    Privacy
                                </Link>
                                <Link className={linkColorClassName} to="/disclaimer" rel="nofollow">
                                    Disclaimer
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
