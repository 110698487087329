import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import axios from "axios";
import { useFormik } from "formik";
import { graphql, Link, navigate, PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// eslint-disable-next-line import/no-unresolved
import { JobApplySchema } from "src/schemas";
import { HUBSPOT_FORM_ID, HUBSPOT_PORTAL_ID, RECAPTCHA_KEY } from "components/constants";
import CustomButton from "components/CustomButton";
import ErrorMessage from "components/errorMessage";
import GoBackLink from "components/GoBackLink";
import Layout from "components/Layout";
import Seo from "components/seo";
import type { TCareers } from "types/common";
import "react-circular-progressbar/dist/styles.css";
import FileIcon from "./images/careers-template-file.svg";
import TrashIcon from "./images/careers-template-trash.svg";
import * as styles from "./apply-for-job.styles.module.scss";

const colorStyles = {
    control: (styles: any) => ({
        ...styles,
        padding: "4px 0px",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        borderRadius: "8px",
    }),

    option: (styles: any, { isFocused }: { isFocused: boolean }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#F9FAFB" : null,
            color: "#000",
        };
    },
};

const ApplyTemplate: FC<{ data: { careers: TCareers } }> = ({ data }) => {
    const careers = data?.careers;
    const title = careers?.title;
    const category = careers?.category;
    const emailReceiver = careers?.emailReceiver;
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [isValidFile, setIsValidFile] = useState(true);
    const [isMaxFileSize, setIsMaxFileSize] = useState(false);
    const [isError, setIsError] = useState(false);
    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [countries, setCountries] = useState<{ value: string; label: string }[]>([]);
    const [teamTypes, setTeamTypes] = useState<{ value: string; label: string }[]>([]);

    const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
    const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB

    type TSplitIntoChunks = (file: File, chunkSize: number) => Blob[];

    const splitIntoChunks: TSplitIntoChunks = (file, chunkSize) => {
        const chunks = [];
        let currentChunk = 0;
        while (currentChunk < file.size) {
            const chunk = file.slice(currentChunk, currentChunk + chunkSize);
            chunks.push(chunk);
            currentChunk += chunkSize;
        }
        return chunks;
    };

    type TUploadInChunks = (
        file: File,
        formData: FormData,
        setProgress: (progress: number) => void,
        onComplete?: (fileDataUrl: string) => Promise<void>
    ) => Promise<void>;

    const uploadInChunks: TUploadInChunks = async (file, formData, setProgress, onComplete) => {
        const chunks = splitIntoChunks(file, CHUNK_SIZE);
        let fileDataUrl = "";

        for (let i = 0; i < chunks.length; i++) {
            const chunkFormData = new FormData();
            chunkFormData.append("resume", chunks[i], file.name);

            for (const [key, value] of (formData as any).entries()) {
                if (key !== "resume") {
                    chunkFormData.append(key, value);
                }
            }

            chunkFormData.append("chunkNumber", (i + 1).toString());
            chunkFormData.append("totalChunks", chunks.length.toString());

            await new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", "/.netlify/functions/applyJob");
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        setProgress(Math.round(((i + 1) / chunks.length) * 100));
                        if (i === chunks.length - 1) {
                            fileDataUrl = xhr.response;
                        }
                        resolve("Success");
                    } else {
                        reject();
                    }
                };
                xhr.onerror = () => {
                    reject();
                };
                xhr.send(chunkFormData);
            });
        }

        if (onComplete) {
            await onComplete(fileDataUrl);
        }
    };

    useEffect(() => {
        axios
            .get("/.netlify/functions/hubSpotProperties", {
                params: {
                    propertyName: "job_country",
                },
            })
            .then(response => {
                const { data } = response;
                const { options } = data;
                const countries = options.map((item: any) => ({
                    value: item.value,
                    label: item.label,
                }));

                setCountries(countries);
            });

        axios
            .get("/.netlify/functions/hubSpotProperties", {
                params: {
                    propertyName: "ay_team___career",
                },
            })
            .then(response => {
                const { data } = response;
                const { options } = data;
                const teamTypes = options.map((item: any) => ({
                    value: item.value,
                    label: item.label,
                }));

                setTeamTypes(teamTypes);
            });
    }, []);

    const getCookie = (cookiename: string) => {
        // Get name followed by anything except a semicolon
        const cookiestring = RegExp(cookiename + "=[^;]+").exec(document.cookie);
        // Return everything after the equal sign, or an empty string if the cookie name not found
        return decodeURIComponent(cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
    };

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            city: "",
            country: "",
            resume: null,
            linkedin: "www.linkedin.com/in/",
            website: "",
            message: "",
            referral_e_mail: "",
            applyPolicy: false,
            category: category,
            captcha: "",
        },
        onSubmit: async values => {
            const formData = new FormData();

            formData.append("resume", uploadedFile!);
            formData.append("firstName", values.firstName);
            formData.append("lastName", values.lastName);
            formData.append("email", values.email);
            formData.append("city", values.city);
            formData.append("country", values.country);
            formData.append("linkedin", values.linkedin);
            formData.append("website", values.website);
            formData.append("message", values.message);
            formData.append("referral_e_mail", values.referral_e_mail);
            formData.append("job_position", values.category);
            formData.append("job_title", title ?? "General application");
            formData.append("emailReceiver", emailReceiver ?? "katia@assertive.ai");

            try {
                if (((uploadedFile || {}).size || 1) > MAX_FILE_SIZE) {
                    setIsMaxFileSize(true);
                    return;
                }

                const hutk = getCookie("hubspotutk");
                const pageUri = String(document.location);
                const pageName = String(document.title);

                await uploadInChunks(uploadedFile!, formData, setFileUploadProgress, async fileDataUrl => {
                    const body = {
                        fields: [
                            { objectTypeId: "0-1", name: "email", value: values.email, required: true },
                            { objectTypeId: "0-1", name: "firstname", value: values.firstName, required: true },
                            { objectTypeId: "0-1", name: "lastname", value: values.lastName, required: true },
                            { objectTypeId: "0-1", name: "city", value: values.city, required: true },
                            { objectTypeId: "0-1", name: "country", value: values.country, required: true },
                            { objectTypeId: "0-1", name: "linkedin", value: values.linkedin, required: true },
                            { objectTypeId: "0-1", name: "website", value: values.website, required: true },
                            { objectTypeId: "0-1", name: "message", value: values.message },
                            { objectTypeId: "0-1", name: "cv", value: fileDataUrl, required: true },
                            { objectTypeId: "0-1", name: "referral_e_mail", value: values.referral_e_mail },
                            { objectTypeId: "0-1", name: "jobtitle", value: title ?? "General application" },
                            { objectTypeId: "0-1", name: "job_position_career", value: values.category },
                        ],
                        context: {
                            hutk,
                            pageUri,
                            pageName,
                        },
                    };

                    await fetch(
                        `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${HUBSPOT_FORM_ID}`,
                        {
                            method: "POST",
                            body: JSON.stringify(body),
                            headers: { "Content-Type": "application/json" },
                        }
                    );
                });
                navigate("/career-success/");
            } catch (error) {
                setIsError(true);
            }
        },
        validationSchema: JobApplySchema,
    });

    const uploadInputRef = useRef<HTMLInputElement>(null);
    const [dragActive, setDragActive] = useState(false);

    const handleDrag = function (e: React.DragEvent<HTMLFormElement | HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e: React.DragEvent<HTMLDivElement | HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (!e.dataTransfer || !e.dataTransfer.files) {
            return;
        }

        if (formik.errors.resume) {
            formik.setTouched({ resume: false }, false);
            formik.setFieldError("resume", "");
        }

        if (
            !e.dataTransfer.files[0].name.includes(".pdf") &&
            !e.dataTransfer.files[0].name.includes(".doc") &&
            !e.dataTransfer.files[0].name.includes(".docx")
        ) {
            setIsMaxFileSize(false);
            setIsValidFile(false);
            setUploadedFile(null);

            return;
        }

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setIsValidFile(true);

            const formData = new FormData();

            formData.append("resume", e.dataTransfer.files[0]);

            setUploadedFile(e.dataTransfer.files[0]);

            formik.setFieldValue("resume", formData);
        }
    };

    const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const formData = new FormData();

            formData.append("resume", e.target.files[0]);

            setUploadedFile(e.target.files[0]);

            formik.setFieldValue("resume", formData);
        }
    };

    function humanFileSize(size: number) {
        if (size < 1024) return size + " B";
        const i = Math.floor(Math.log(size) / Math.log(1024));
        let num = size / Math.pow(1024, i);
        const round = Math.round(num);
        num = (round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round) as number;
        return `${num} ${"KMGTPEZY"[i - 1]}B`;
    }

    const onButtonClick = () => {
        uploadInputRef.current!.click();
    };

    const removeFile = () => {
        setUploadedFile(null);
        setIsMaxFileSize(false);
        formik.setFieldValue("resume", "");
    };

    const handleLinkedInLink = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("linkedin", e.target.value);
        if (formik.errors.linkedin) {
            formik.setTouched({ linkedin: false }, false);
            formik.setFieldError("linkedin", "");
        }

        if (e.target.value.includes("https://")) {
            formik.setFieldValue("linkedin", e.target.value.replace("https://", ""));
        }
    };

    const handleWebsiteLink = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("website", e.target.value);
        if (formik.errors.website) {
            formik.setTouched({ website: false }, false);
            formik.setFieldError("website", "");
        }

        if (e.target.value.includes("https://")) {
            formik.setFieldValue("website", e.target.value.replace("https://", ""));
        }
    };

    const handleCaptcha = (token: string | null) => {
        formik.setFieldValue("captcha", token);
    };

    return (
        <Layout bg="light">
            <div className="container">
                <div className={styles.wrapper}>
                    <div className={`${styles.firstGlow} gradient`}>
                        <StaticImage src="./images/apply-for-job-first-glow.svg" alt="" />
                    </div>
                    <div className={`${styles.secondGlow} gradient`}>
                        <StaticImage src="./images/apply-for-job-second-glow.svg" alt="" />
                    </div>
                    <div className={`${styles.thirdGlow} gradient`}>
                        <StaticImage src="./images/apply-for-job-third-glow.svg" alt="" />
                    </div>
                    <div className={`${styles.fourthGlow} gradient`}>
                        <StaticImage src="./images/apply-for-job-fourth-glow.svg" alt="" />
                    </div>
                    <GoBackLink />
                    <h1 className={styles.title}>{title}</h1>
                    <div className={styles.jobWrapper}>
                        <h2 className={styles.jobTitle}>Application</h2>
                        <p className={styles.jobSubtitle}>Personal Information</p>
                        <form className={styles.jobForm}>
                            <div className={styles.jobContentWrapper}>
                                <div className={styles.jobContent}>
                                    <label htmlFor="firstName" className={styles.jobLabel}>
                                        First Name
                                    </label>
                                    <input
                                        className={styles.jobInput}
                                        type="text"
                                        id="firstName"
                                        onChange={formik.handleChange}
                                        value={formik.values.firstName}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <div className={styles.errorMessage}>{formik.errors.firstName}</div>
                                    ) : null}
                                </div>
                                <div className={styles.jobContent}>
                                    <label htmlFor="lastName" className={styles.jobLabel}>
                                        Last name
                                    </label>
                                    <input
                                        className={styles.jobInput}
                                        type="text"
                                        id="lastName"
                                        onChange={formik.handleChange}
                                        value={formik.values.lastName}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <div className={styles.errorMessage}>{formik.errors.lastName}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.jobContentWrapper}>
                                <div style={{ width: "100%" }} className={styles.jobContent}>
                                    <label htmlFor="email" className={styles.jobLabel}>
                                        Email
                                    </label>
                                    <input
                                        className={styles.jobInput}
                                        type="text"
                                        id="email"
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className={styles.errorMessage}>{formik.errors.email}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.jobContentWrapper}>
                                <div className={styles.jobContent}>
                                    <label htmlFor="city" className={styles.jobLabel}>
                                        City
                                    </label>
                                    <input
                                        className={styles.jobInput}
                                        type="text"
                                        id="city"
                                        placeholder="Enter your city"
                                        onChange={formik.handleChange}
                                        value={formik.values.city}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.city && formik.errors.city ? (
                                        <div className={styles.errorMessage}>{formik.errors.city}</div>
                                    ) : null}
                                </div>
                                <div className={styles.jobContent}>
                                    <label htmlFor="country" className={styles.jobLabel}>
                                        Country
                                    </label>
                                    <div
                                        style={{
                                            width: "100%",
                                            zIndex: "2",
                                        }}
                                    >
                                        <Select
                                            options={countries}
                                            id="country"
                                            onChange={option => {
                                                formik.setFieldValue("country", option?.value);
                                            }}
                                            styles={colorStyles}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </div>
                                    {formik.touched.country && formik.errors.country ? (
                                        <div className={styles.errorMessage}>{formik.errors.country}</div>
                                    ) : null}
                                </div>
                            </div>
                        </form>
                        <p className={styles.jobSubtitle}>Experience</p>
                        <div className={styles.jobContentWrapper}>
                            <div
                                style={{
                                    width: "100%",
                                }}
                                className={styles.jobContent}
                            >
                                <form
                                    style={{
                                        width: "100%",
                                    }}
                                    className={styles.formFileUpload}
                                    onDragEnter={handleDrag}
                                    onSubmit={e => e.preventDefault()}
                                >
                                    <p className={styles.jobLabel}>Resume</p>
                                    <label
                                        htmlFor="resume"
                                        className={[
                                            styles.jobLabel,
                                            styles.jobLabelUpload,
                                            dragActive ? styles.dragActive : "",
                                        ].join(" ")}
                                    >
                                        <div className={styles.jobLabelUploadContent}>
                                            <button onClick={onButtonClick} className={styles.jobLabelUploadButton}>
                                                Click to upload
                                            </button>
                                            <span>or drag and drop</span>
                                        </div>
                                        <div className={styles.jobLabelUploadDescription}>
                                            <p className={styles.jobLabelUploadDescriptionTitle}>
                                                PDF or DOC (max. 20mb)
                                            </p>
                                        </div>
                                    </label>
                                    <input
                                        ref={uploadInputRef}
                                        onChange={handleChange}
                                        className={styles.jobInput}
                                        type="file"
                                        id="resume"
                                        accept="application/pdf,application/msword,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    />
                                    {dragActive && (
                                        <div
                                            className={styles.dragFileElement}
                                            onDragEnter={handleDrag}
                                            onDragLeave={handleDrag}
                                            onDragOver={handleDrag}
                                            onDrop={handleDrop}
                                        />
                                    )}
                                    {formik.touched.resume && formik.errors.resume ? (
                                        <div className={styles.errorMessageResume}>{formik.errors.resume}</div>
                                    ) : null}
                                    {!isValidFile ? (
                                        <div className={styles.errorMessageResume}>File must be PDF or DOC</div>
                                    ) : null}
                                    {isMaxFileSize ? (
                                        <div className={styles.errorMessageResume}>
                                            File size must be less than 20MB
                                        </div>
                                    ) : null}
                                </form>
                            </div>
                        </div>
                        {uploadedFile && (
                            <div className={styles.jobUploadedFileWrapper}>
                                <div className={styles.jobUploadedFile}>
                                    <div className={styles.jobUploadedFileContent}>
                                        <div className={styles.jobUploadedFileIcon}>
                                            <img src={FileIcon} alt="file icon" />
                                        </div>
                                        <div className={styles.jobUploadedFileText}>
                                            <p className={styles.jobUploadedFileTitle}>{uploadedFile?.name}</p>
                                            <p className={styles.jobUploadedFileSize}>
                                                {humanFileSize(uploadedFile?.size)}
                                            </p>
                                        </div>
                                    </div>
                                    <img
                                        className={styles.trashIcon}
                                        onClick={removeFile}
                                        src={TrashIcon}
                                        alt="trash icon"
                                    />
                                </div>
                            </div>
                        )}
                        <form className={styles.jobForm}>
                            <div className={styles.jobContentWrapper}>
                                <div className={[styles.jobContent, styles.jobContentLink].join(" ")}>
                                    <label htmlFor="linkedin" className={styles.jobLabel}>
                                        LinkedIn
                                    </label>
                                    <input
                                        className={[styles.jobInput, styles.jobInputLink].join(" ")}
                                        type="text"
                                        id="linkedin"
                                        placeholder="www.linkedin.com/in/"
                                        onChange={handleLinkedInLink}
                                        value={formik.values.linkedin}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.linkedin && formik.errors.linkedin ? (
                                        <div className={styles.errorMessage}>{formik.errors.linkedin}</div>
                                    ) : null}
                                </div>
                                <div className={[styles.jobContent, styles.jobContentLink].join(" ")}>
                                    <label htmlFor="website" className={styles.jobLabel}>
                                        Website
                                    </label>
                                    <input
                                        className={[styles.jobInput, styles.jobInputLink].join(" ")}
                                        type="text"
                                        id="website"
                                        placeholder="www.your-website.com"
                                        onChange={handleWebsiteLink}
                                        value={formik.values.website}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.website && formik.errors.website ? (
                                        <div className={styles.errorMessage}>{formik.errors.website}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.jobContentWrapper}>
                                <div
                                    style={{
                                        width: "100%",
                                    }}
                                    className={styles.jobContent}
                                >
                                    <label htmlFor="message" className={styles.jobLabel}>
                                        Message to hiring manager
                                    </label>
                                    <textarea
                                        style={{
                                            height: "130px",
                                        }}
                                        placeholder="Leave us a message..."
                                        className={styles.jobInput}
                                        id="message"
                                        onChange={formik.handleChange}
                                        value={formik.values.message}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.message && formik.errors.message ? (
                                        <div className={styles.errorMessageTextarea}>{formik.errors.message}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.jobContentWrapper}>
                                <div
                                    style={{
                                        width: "100%",
                                    }}
                                    className={styles.jobContent}
                                >
                                    <label htmlFor="referral_e_mail" className={styles.jobLabel}>
                                        Do you know someone who works at the company?
                                    </label>
                                    <input
                                        className={styles.jobInput}
                                        type="text"
                                        id="referral_e_mail"
                                        onChange={formik.handleChange}
                                        value={formik.values.referral_e_mail}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            {!data?.careers ? (
                                <div className={styles.jobContentWrapper}>
                                    <div
                                        style={{
                                            width: "100%",
                                            marginBottom: "20px",
                                        }}
                                        className={styles.jobContent}
                                    >
                                        <label htmlFor="category" className={styles.jobLabel}>
                                            What type of team are you looking for?
                                        </label>
                                        <div
                                            style={{
                                                width: "100%",
                                                zIndex: "2",
                                            }}
                                        >
                                            <Select
                                                id="category"
                                                options={teamTypes}
                                                defaultValue={{
                                                    value: category,
                                                    label: category,
                                                }}
                                                onChange={option => {
                                                    formik.setFieldValue("category", option?.label);
                                                }}
                                                styles={colorStyles}
                                                components={{
                                                    IndicatorSeparator: () => null,
                                                }}
                                                placeholder="Select team type"
                                            />
                                        </div>

                                        {formik.touched.category && formik.errors.category ? (
                                            <div className={styles.errorMessageSelect}>{formik.errors.category}</div>
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}
                            <div className={styles.jobContentWrapperCheckbox}>
                                <div
                                    style={{
                                        width: "100%",
                                    }}
                                    className={styles.jobContentTerms}
                                >
                                    <input
                                        className={styles.jobCheckbox}
                                        type="checkbox"
                                        id="applyPolicy"
                                        onChange={formik.handleChange}
                                        checked={formik.values.applyPolicy}
                                        onBlur={formik.handleBlur}
                                    />
                                    <label htmlFor="applyPolicy" className={styles.jobLabelTerms}>
                                        By checking this box, you will declare that you read and understand the{" "}
                                        <Link to="/privacy">privacy policy</Link> of Assertive Yield.*
                                    </label>
                                    {formik.touched.applyPolicy && formik.errors.applyPolicy ? (
                                        <div className={styles.errorMessageApplyPolicy}>
                                            {formik.errors.applyPolicy}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div
                                className={styles.jobContentWrapper}
                                style={{
                                    marginBottom: "30px",
                                }}
                            >
                                <div className={styles.jobContent}>
                                    <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={handleCaptcha} />
                                    {formik.touched.captcha && formik.errors.captcha ? (
                                        <div className={styles.errorMessageCaptcha}>{formik.errors.captcha}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.loadingWrapper}>
                                {!isError && (
                                    <div className={styles.jobButtonWrapper}>
                                        <CustomButton type="submit" onClick={formik.handleSubmit}>
                                            Submit
                                        </CustomButton>
                                    </div>
                                )}
                                {fileUploadProgress && !isError ? (
                                    <div style={{ width: "58px", height: "58px", display: "flex" }}>
                                        <CircularProgressbar
                                            styles={buildStyles({
                                                pathColor: "rgba(104, 0, 255, 1)",
                                                textColor: "rgba(52, 64, 84, 1)",
                                            })}
                                            value={fileUploadProgress}
                                            text={`${fileUploadProgress}%`}
                                            strokeWidth={10}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <ErrorMessage setShowErrorMessage={setIsError} isShowErrorMessage={isError} />
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const Head: FC<PageProps<any>> = ({ location, data: { careers: post } }) => {
    return (
        <Seo title={post?.title} description={post?.description} canonical={location.pathname} titleSuffixed={false}>
            <meta property="og:type" content="article" />
        </Seo>
    );
};

export default ApplyTemplate;

export const pageQuery = graphql`
    query career($id: String) {
        careers(id: { eq: $id }) {
            id
            title
            description
            body
            category
            location
            emailReceiver
            isFullTime
            fields {
                slug
            }
        }
    }
`;
