export const uppercaseFirstLetter = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);

export const uppercaseFirstLetterLowerRest = (s: string): string =>
    s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export const getKeys = <T extends object>(obj: T) => Object.keys(obj) as Array<keyof T>;
export const getValues = <T extends object>(obj: T) => Object.values(obj) as Array<T[keyof T]>;

export const fromEntries = <K extends string | number | symbol, V>(entries: Array<[K, V]>) =>
    Object.fromEntries(entries) as Record<K, V>;

export const getEntries = <K extends string | number | symbol, V>(obj: Record<K, V>) =>
    Object.entries(obj) as Array<[K, V]>;

export const getEntriesPartial = <K extends string | number | symbol, V>(obj: Partial<Record<K, V>>) =>
    Object.entries(obj) as Array<[K, V]>;

export const makeArrayNonNull = <T>(it: T | T[]): T[] => {
    if (Array.isArray(it)) {
        return it;
    }
    return [it];
};

export const makeArray = <T>(it: T | T[] | null | undefined): T[] => {
    if (it == null) {
        return [];
    }
    return makeArrayNonNull(it);
};

export const removeNullishFromList = <T>(list: readonly (T | null | undefined)[]): readonly T[] =>
    list.reduce((p, c) => (c != null ? [...p, c] : p), [] as T[]);

export type Updater<A> = (v: A) => A;
export const chainUpdater =
    <A>(mod2: Updater<A>) =>
    //
    (mod1: Updater<A>): Updater<A> =>
    //
    (value: A) =>
        mod2(mod1(value));

export const memoizer = <I extends any[], O>(fn: (...a: I) => O) => {
    const _cache: { [k: string]: O } = {};

    return (...args: I) => {
        let key = "";
        try {
            if (args.length === 1) {
                const singleArg = args[0];
                if (typeof singleArg === "string") {
                    key = singleArg;
                } else if (typeof singleArg === "number" || typeof singleArg === "boolean") {
                    key = String(singleArg);
                } else {
                    key = JSON.stringify(singleArg);
                }
            } else {
                key = JSON.stringify(args);
            }
        } catch (e) {
            key = args.join("");
        }
        const cached = _cache[key];
        if (cached != null) {
            return cached;
        }

        const val = fn(...args);
        _cache[key] = val;
        return val;
    };
};

export const promiseOrTimeout = async <T>(name: string, promise: Promise<T>, timeoutMs: number): Promise<T> => {
    let timeoutId: any;
    const timeoutPromise = new Promise<T>((_, reject) => {
        timeoutId = setTimeout(() => {
            reject(new Error(`${name} - Timeout of ${timeoutMs / 1000 / 60} minutes reached`));
        }, timeoutMs);
    });

    try {
        const result = await Promise.race([promise, timeoutPromise]);
        clearTimeout(timeoutId);
        return result;
    } catch (e) {
        clearTimeout(timeoutId);
        throw e;
    }
};

export const reverseKv = (it: Record<string, string>): Record<string, string> => {
    const result: Record<string, string> = {};
    for (const [k, v] of Object.entries(it)) {
        result[v] = k;
    }
    return result;
};
