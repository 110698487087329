import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import { calculatedYieldMetrics, dimensions, yieldConfig } from "ay-core";
import { Builder } from "@builder.io/react";
import DebouncedInput from "components/debounceInput";
import Pagination from "components/Pagination";
import { toMutable } from "types/common";
import filterIcon from "./images/ay-glossary-knowledge-desk-filter-icon.svg";
import smallRefreshIcon from "./images/ay-glossary-knowledge-desk-filter-small-icon.svg";
import refreshIcon from "./images/ay-glossary-knowledge-desk-refresh-icon.svg";
import smallSortIcon from "./images/ay-glossary-knowledge-desk-sort-small-icon.svg";
import * as styles from "./styles.module.scss";

const InfoIcon = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_9566_17544)">
            <path
                d="M4.545 4.5C4.66255 4.16583 4.89458 3.88405 5.19998 3.70457C5.50538 3.52508 5.86445 3.45947 6.21359 3.51936C6.56273 3.57924 6.87941 3.76076 7.10754 4.03176C7.33567 4.30277 7.46053 4.64576 7.46 5C7.46 6 5.96 6.5 5.96 6.5M6 8.5H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
                className={styles.infoIcon}
            />
        </g>
        <defs>
            <clipPath id="clip0_9566_17544">
                <rect width="12" height="12" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const inputs = [] as const;

type TFiltersItems = {
    items: {
        name: string;
        explanation: string;
        tag: string;
    }[];
    description: string;
    title: string;
    item: {
        name: string;
    };
    path: string;
    hideOnMainView: boolean;
};

type TData = {
    metrics: any;
    dimensions: TFiltersItems[];
};

export const AyGlossaryKnowledgeDeskMetrics = () => {
    const switcherPoints = ["Metrics", "Dimensions"];

    const [activePoint, setActivePoint] = useState("Metrics");

    const tableRef = useRef<any>(null);
    const filtersTableRef = useRef<any>(null);
    const moreDropDownRef = useRef<any>(null);
    const sortDropDownRef = useRef<any>(null);
    const searchResultsRef = useRef<any>(null);
    const [data, setData] = useState<TData>({
        metrics: [],
        dimensions: [],
    });
    const [selectedCategory, setSelectedCategory] = useState<string>("Total");
    const [moreDropDown, toggleMoreDropDown] = useState(false);
    const [filterMetricsDropDown, toggleFilterMetricsDropDown] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [filteredMetrics, setFilteredMetrics] = useState<TFiltersItems[]>({} as TFiltersItems[]);
    const [filteredDimensions, setFilteredDimensions] = useState<TFiltersItems[]>([]);
    const [searchOpen, setSearchOpen] = useState(false);

    type TCategories = [string, { items: TItem[]; hideOnMainView: boolean; itemExplanation: string }][];

    const categories = Object.entries(data.metrics) as TCategories;

    type TItem = {
        hideOnMainView: boolean;
        name: string;
        explanation: string;
        tag: string;
    };

    const handleCloseDropDowns = (e: MouseEvent) => {
        if (moreDropDownRef.current && !moreDropDownRef.current.contains(e.target)) {
            toggleMoreDropDown(false);
        }

        if (sortDropDownRef.current && !sortDropDownRef.current.contains(e.target)) {
            toggleFilterMetricsDropDown(false);
        }

        if (searchResultsRef.current && !searchResultsRef.current.contains(e.target)) {
            setSearchOpen(false);
        }
    };

    const itemsPerPage = 20;

    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const visiblePosts = Object.entries(data.metrics).flatMap(([category, data]) => {
        if (category === selectedCategory) {
            return (data as any).items;
        }

        return [];
    });

    const totalItems = visiblePosts.length;

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        if (searchValue.length === 0) {
            setCurrentPage(1);
            setSelectedCategory("Total");
            setFilteredMetrics([]);
            setFilteredDimensions(dimensions as any);
        }
    }, [searchValue]);

    useEffect(() => {
        async function getData() {
            const constants = {
                yieldConfig,
                dimensions,
                calculatedYieldMetrics,
            } as any;

            function createMapping(constants: any) {
                const result: {
                    [key: string]: {
                        hideOnMainView: boolean;
                        items: {
                            name: string;
                            explanation: string;
                            tag: string;
                        }[];
                        itemExplanation?: string;
                    };
                } = {};

                result["Total"] = {
                    hideOnMainView: false,
                    items: [],
                };

                for (const mainCategory in constants.yieldConfig.setup.tableMapping) {
                    const mainCategoryName = constants.yieldConfig.setup.tabMeta[mainCategory].name;
                    result[mainCategoryName] = {
                        hideOnMainView: constants.yieldConfig.setup.tabMeta[mainCategory].hideOnMainView,
                        items: [],
                        itemExplanation: constants.yieldConfig.setup.tabMeta[mainCategory].explanation,
                    };

                    for (const subCategory in constants.yieldConfig.setup.tableMapping[mainCategory]) {
                        const subCategoryName = constants.yieldConfig.setup.columnNames[subCategory];

                        for (const metric of constants.yieldConfig.setup.tableMapping[mainCategory][subCategory]) {
                            const metricInfo = constants.calculatedYieldMetrics[metric];
                            const item = {
                                name: metricInfo.label,
                                explanation: metricInfo.explanation,
                                tag: subCategoryName,
                            };

                            result[mainCategoryName].items.push(item);
                            result["Total"].items.push(item);
                        }
                    }

                    result[mainCategoryName].items.sort((a, b) => a.tag.localeCompare(b.tag));
                }

                return result;
            }

            setData(data => ({
                ...data,
            }));

            setData({
                metrics: createMapping(constants),
                dimensions: Object.values(constants.dimensions)
                    .map((it: any) => {
                        if (it.description) {
                            return {
                                title: it.label,
                                description: it.description,
                            };
                        }

                        return null;
                    })
                    .filter(Boolean) as TFiltersItems[],
            });
        }

        getData();

        document.addEventListener("mousedown", handleCloseDropDowns);

        return () => {
            document.removeEventListener("mousedown", handleCloseDropDowns);
        };
    }, []);

    if (!categories.length) {
        return;
    }

    const hasResults = categories.some(([, { items }]) => items.length > 0 && !items[0].hideOnMainView);

    function sortMetrics(filter: "Word" | "Tag" | "Description") {
        const filterMap = {
            Word: "name",
            Tag: "tag",
            Description: "explanation",
        };

        if (filterMap[filter]) {
            setData(data => {
                const metricsCopy = { ...data.metrics };

                for (const mainCategory in metricsCopy) {
                    for (const subCategory in metricsCopy[mainCategory]) {
                        if (subCategory === "hideOnMainView" || subCategory === "itemExplanation") {
                            continue;
                        }

                        metricsCopy[mainCategory][subCategory] = Object.values(
                            metricsCopy[mainCategory][subCategory]
                        ).sort((a: any, b: any) => {
                            return a[filterMap[filter]].localeCompare(b[filterMap[filter]]);
                        });
                    }
                }

                return {
                    ...data,
                    metrics: metricsCopy,
                };
            });
        }
    }

    function searchItems(mapping: any, searchTerm: string, dimensionsState: TFiltersItems[]) {
        const nameResults = [];
        const explanationResults = [];
        const dimensionResults = [];

        for (const category in mapping) {
            if (category === "Total") {
                continue;
            }

            for (const item of mapping[category].items) {
                if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    nameResults.push({
                        path: `${category} > ${item.tag} > ${item.name}`,
                        item: item,
                    });
                } else if (item.explanation.toLowerCase().includes(searchTerm.toLowerCase())) {
                    explanationResults.push({
                        path: `${category} > ${item.tag} > ${item.name}`,
                        item: item,
                    });
                }
            }
        }

        for (const dimension of dimensionsState) {
            if (
                dimension.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                dimension.description.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
                dimensionResults.push(dimension);
            }
        }

        const results = [...nameResults, ...explanationResults, ...dimensionResults];

        return results;
    }

    function changeCurrentCategory(path: string) {
        const [category, _, itemName] = path.split(" > ");

        setSelectedCategory(category);

        const metricsCopy = JSON.parse(JSON.stringify(data.metrics));

        for (const mainCategory in metricsCopy) {
            for (const subCategory in metricsCopy[mainCategory]) {
                if (subCategory === "hideOnMainView" || subCategory === "itemExplanation") {
                    continue;
                }

                for (const metric of metricsCopy[mainCategory][subCategory]) {
                    if (metric.name === itemName) {
                        metricsCopy[mainCategory][subCategory] = [];

                        metricsCopy[mainCategory][subCategory].push(metric);
                    }
                }
            }
        }

        setFilteredMetrics(metricsCopy);
    }

    const searchDimensions = (value: string) => {
        const filteredDimensions = data.dimensions.filter(dimension => {
            return dimension.title.toLowerCase().includes(value.toLowerCase());
        });

        setFilteredDimensions(filteredDimensions);
    };

    return (
        <div className="container">
            <div className={styles.interactionsWrapper}>
                <div className={styles.searchBarContainer}>
                    <div className={styles.searchBarWrapper}>
                        <DebouncedInput
                            closeIcon={true}
                            placeholder="Search"
                            className={styles.searchBar}
                            debounceDelay={500}
                            value={searchValue}
                            onChange={value => {
                                setSearchValue(String(value));
                            }}
                            onClick={() => setSearchOpen(true)}
                            onCloseIconClick={() => {
                                setSearchValue("");
                                setFilteredMetrics([]);
                            }}
                        />
                    </div>
                    {searchValue.length > 0 && searchOpen && (
                        <div className={styles.dropDownWrapper} ref={searchResultsRef}>
                            {searchItems(data.metrics, searchValue, data.dimensions).map((result, index) => {
                                if (result.item && activePoint === "Metrics") {
                                    return (
                                        <div
                                            onClick={() => {
                                                changeCurrentCategory(result.path);
                                                tableRef.current?.scrollIntoView({
                                                    behavior: "smooth",
                                                    block: "center",
                                                });
                                                setSearchOpen(false);
                                            }}
                                            key={index}
                                            className={styles.dropDownItem}
                                        >
                                            <span className={styles.dropDownTitle}>{result.item.name}</span>
                                            <span className={styles.dropDownCategory}>{result.path}</span>
                                        </div>
                                    );
                                }

                                if (activePoint === "Dimensions" && !result.item) {
                                    return (
                                        <div
                                            onClick={() => {
                                                searchDimensions((result as TFiltersItems).title);
                                                filtersTableRef.current?.scrollIntoView({
                                                    behavior: "smooth",
                                                    block: "center",
                                                });
                                                setSearchOpen(false);
                                            }}
                                            key={index}
                                            className={styles.dropDownItem}
                                        >
                                            <span className={styles.dropDownTitle}>
                                                {(result as TFiltersItems).title}
                                            </span>
                                            <span className={styles.dropDownCategory}>
                                                {(result as TFiltersItems).title}
                                            </span>
                                        </div>
                                    );
                                }

                                return null;
                            })}
                        </div>
                    )}
                    {!hasResults && searchValue.length > 0 && (
                        <div className={styles.dropDownItem}>
                            <span className={styles.dropDownTitle}>No result found</span>
                            <span className={styles.dropDownCategory}>
                                Try a different search term or select any of the options below other resources
                            </span>
                        </div>
                    )}
                </div>

                <img
                    src={smallSortIcon}
                    alt=""
                    className={styles.smallRefreshIcon}
                    onClick={() => {
                        setSelectedCategory("Total");
                        setFilteredMetrics(data.metrics);
                        setSearchValue("");
                        sortMetrics("Word");
                    }}
                />

                {activePoint === "Metrics" ? (
                    <img
                        onClick={() => toggleFilterMetricsDropDown(!filterMetricsDropDown)}
                        src={smallRefreshIcon}
                        alt=""
                        className={`${filterMetricsDropDown ? styles.filterIconActive : ""} ${styles.smallFilterIcon}`}
                    />
                ) : null}
            </div>

            <div className={styles.switcherWrapper}>
                <ul className={styles.switcher}>
                    {switcherPoints.map(point => (
                        <li
                            key={point}
                            className={`${styles.switcherPoint} ${activePoint === point ? styles.active : ""}`}
                            onClick={() => setActivePoint(point)}
                        >
                            {point}
                        </li>
                    ))}
                </ul>
            </div>

            {activePoint === "Metrics" && (
                <>
                    <div className={styles.metricsInteractions}>
                        <Select
                            options={categories.map(([category]) => ({ value: category, label: category }))}
                            className={styles.metricsSelect}
                            value={{ value: selectedCategory, label: selectedCategory }}
                            onChange={option => {
                                setSelectedCategory(option?.value ?? "");
                            }}
                            defaultValue={{ value: "Total", label: "Total" }}
                            styles={{
                                control: provided => ({
                                    ...provided,
                                    borderRadius: "8px",
                                    height: "44px",
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected ? "#e0e0e0" : "#fff",
                                    color: "#000",
                                    "&:hover": {
                                        backgroundColor: "#F9FAFB",
                                    },
                                }),
                                indicatorSeparator: () => ({
                                    display: "none",
                                }),
                            }}
                        />

                        <img
                            src={refreshIcon}
                            alt=""
                            className={styles.refreshIcon}
                            onClick={() => {
                                setSelectedCategory("Total");
                                setFilteredMetrics(data.metrics);
                                setSearchValue("");
                                sortMetrics("Word");
                            }}
                        />

                        <div className={styles.metricsFilter} ref={sortDropDownRef}>
                            <img
                                onClick={() => toggleFilterMetricsDropDown(!filterMetricsDropDown)}
                                src={filterIcon}
                                alt=""
                                className={`${filterMetricsDropDown ? styles.filterIconActive : ""} ${
                                    styles.filterIcon
                                }`}
                            />
                            {filterMetricsDropDown ? (
                                <ul className={styles.metricsFilterDropDown}>
                                    <li className={styles.item} onClick={() => sortMetrics("Word")}>
                                        <span className={styles.tag}>Word</span>
                                    </li>
                                    <li className={styles.item} onClick={() => sortMetrics("Tag")}>
                                        <span className={styles.tag}>Tag</span>
                                    </li>
                                    <li className={styles.item} onClick={() => sortMetrics("Description")}>
                                        <span className={styles.tag}>Description</span>
                                    </li>
                                </ul>
                            ) : null}
                        </div>
                    </div>

                    <div className={styles.table} ref={tableRef}>
                        <div className={styles.filters}>
                            {categories.map(([category, { hideOnMainView, itemExplanation }], idx) => {
                                if (hideOnMainView) {
                                    return;
                                }
                                return (
                                    <div
                                        key={category + idx}
                                        className={`${styles.filterWrapper} ${
                                            selectedCategory === category ? styles.filterActive : ""
                                        }`}
                                        onClick={() => {
                                            setSelectedCategory(category);

                                            setCurrentPage(1);
                                        }}
                                    >
                                        <p key={category} className={`${styles.filter}`}>
                                            {category}
                                        </p>
                                        {itemExplanation ? (
                                            <>
                                                <div
                                                    data-tooltip-content={itemExplanation}
                                                    data-tooltip-id={category}
                                                    className={styles.infoIconWrapper}
                                                >
                                                    <InfoIcon />
                                                </div>

                                                <Tooltip id={category} />
                                            </>
                                        ) : null}
                                    </div>
                                );
                            })}
                            <div ref={moreDropDownRef}>
                                <p
                                    className={`${styles.filterWrapper} ${moreDropDown ? styles.moreActive : ""}`}
                                    onClick={() => {
                                        toggleMoreDropDown(!moreDropDown);
                                    }}
                                >
                                    More...
                                </p>

                                {moreDropDown ? (
                                    <ul className={styles.categoriesDropDown}>
                                        {categories.map(([category, { hideOnMainView, itemExplanation }]) => {
                                            if (!hideOnMainView) {
                                                return;
                                            }
                                            return (
                                                <li
                                                    key={category}
                                                    className={`${styles.filterWrapperCustom} ${
                                                        selectedCategory === category ? styles.filterActive : ""
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedCategory(category);

                                                        setCurrentPage(1);
                                                    }}
                                                >
                                                    <span className={styles.text}>{category}</span>
                                                    {itemExplanation ? (
                                                        <>
                                                            <div
                                                                data-tooltip-content={itemExplanation}
                                                                data-tooltip-id={category}
                                                                className={styles.infoIconWrapper}
                                                            >
                                                                <InfoIcon />
                                                            </div>

                                                            <Tooltip id={category} />
                                                        </>
                                                    ) : null}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <table className={styles.tableData}>
                        <thead>
                            <tr>
                                <th style={{ backgroundColor: "#F9FAFB", width: "40%" }}>Word</th>
                                <th style={{ backgroundColor: "#F9FAFB", width: "20%" }}>Tags</th>
                                <th style={{ backgroundColor: "#F9FAFB", width: "40%" }}>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!filteredMetrics[selectedCategory as any]?.items?.length
                                ? data.metrics[selectedCategory] &&
                                  Object.entries(data.metrics[selectedCategory]).map(
                                      ([subCategory, subCategoryData]) => {
                                          if (!subCategoryData || subCategory === "itemExplanation") {
                                              return;
                                          }

                                          return (
                                              <React.Fragment key={subCategory}>
                                                  {Object.entries(subCategoryData)
                                                      .slice(startIndex, endIndex)
                                                      .map(([metricName, metricInfo]) => {
                                                          return (
                                                              <tr key={metricName}>
                                                                  <td>{metricInfo.name}</td>
                                                                  <td>
                                                                      <span className={styles.tag}>
                                                                          {metricInfo.tag}
                                                                      </span>
                                                                  </td>
                                                                  <td>{metricInfo.explanation}</td>
                                                              </tr>
                                                          );
                                                      })}
                                              </React.Fragment>
                                          );
                                      }
                                  )
                                : Object.entries(filteredMetrics[selectedCategory as any]).map(
                                      ([subCategory, subCategoryData]) => {
                                          if (!subCategoryData || subCategory === "itemExplanation") {
                                              return;
                                          }

                                          return (
                                              <React.Fragment key={subCategory}>
                                                  {Object.entries(subCategoryData).map(
                                                      ([metricName, metricInfo]: [string, any]) => {
                                                          return (
                                                              <tr key={metricName}>
                                                                  <td>{metricInfo.name}</td>
                                                                  <td>
                                                                      <span className={styles.tag}>
                                                                          {metricInfo.tag}
                                                                      </span>
                                                                  </td>
                                                                  <td>{metricInfo.explanation}</td>
                                                              </tr>
                                                          );
                                                      }
                                                  )}
                                              </React.Fragment>
                                          );
                                      }
                                  )}
                        </tbody>
                    </table>

                    <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                </>
            )}

            {activePoint === "Dimensions" && (
                <>
                    <div className="d-flex justify-content-end mb-4">
                        <img
                            src={refreshIcon}
                            alt=""
                            className={styles.refreshIcon}
                            onClick={() => {
                                setSelectedCategory("Total");
                                setFilteredMetrics(data.metrics);
                                setSearchValue("");
                                sortMetrics("Word");
                            }}
                        />
                    </div>

                    <table className={styles.tableData}>
                        <thead ref={filtersTableRef}>
                            <tr>
                                <th style={{ backgroundColor: "#F9FAFB" }}>Word</th>
                                <th style={{ backgroundColor: "#F9FAFB" }}>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredDimensions.length > 0
                                ? filteredDimensions.map((item, idx) => (
                                      <tr key={item.description + idx}>
                                          <td>{item.title}</td>
                                          <td>{item.description}</td>
                                      </tr>
                                  ))
                                : data.dimensions.map((item, idx) => (
                                      <tr key={item.description + idx}>
                                          <td>{item.title}</td>
                                          <td>{item.description}</td>
                                      </tr>
                                  ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};

Builder.registerComponent(AyGlossaryKnowledgeDeskMetrics, {
    name: "ayGlossaryKnowledgeDeskMetrics",
    inputs: toMutable(inputs),
});
