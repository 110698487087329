export const VitalsRawMetrics = [
    "vitals_lcp_measurable",
    "vitals_lcp_sum",
    "vitals_lcp_good",
    "vitals_lcp_medium",
    "vitals_lcp_bad",
    "vitals_fid_measurable",
    "vitals_fid_sum",
    "vitals_fid_good",
    "vitals_fid_medium",
    "vitals_fid_bad",
    "vitals_cls_measurable",
    "vitals_cls_sum",
    "vitals_cls_good",
    "vitals_cls_medium",
    "vitals_cls_bad",
    "vitals_inp_measurable",
    "vitals_inp_sum",
    "vitals_inp_good",
    "vitals_inp_medium",
    "vitals_inp_bad",
    "vitals_ttfb_measurable",
    "vitals_ttfb_sum",
    "vitals_ttfb_good",
    "vitals_ttfb_medium",
    "vitals_ttfb_bad",
    "vitals_fcp_measurable",
    "vitals_fcp_sum",
    "vitals_fcp_good",
    "vitals_fcp_medium",
    "vitals_fcp_bad",
] as const;

export type VitalsRawMetrics = (typeof VitalsRawMetrics)[number];

const VitalsCalculatedMetrics = [
    "vitals_lcp",
    "vitals_lcp_good_rate",
    "vitals_lcp_medium_rate",
    "vitals_lcp_bad_rate",
    "vitals_fid",
    "vitals_fid_good_rate",
    "vitals_fid_medium_rate",
    "vitals_fid_bad_rate",
    "vitals_cls",
    "vitals_cls_good_rate",
    "vitals_cls_medium_rate",
    "vitals_cls_bad_rate",
    "vitals_inp",
    "vitals_inp_good_rate",
    "vitals_inp_medium_rate",
    "vitals_inp_bad_rate",
    "vitals_ttfb",
    "vitals_ttfb_good_rate",
    "vitals_ttfb_medium_rate",
    "vitals_ttfb_bad_rate",
    "vitals_fcp",
    "vitals_fcp_good_rate",
    "vitals_fcp_medium_rate",
    "vitals_fcp_bad_rate",
] as const;
export type VitalsCalculatedMetrics = (typeof VitalsCalculatedMetrics)[number];

export const ALL_RAW_ERROR_ANALYTICS_METRICS = ["errors", "sessions"] as const;

export type RawErrorAnalyticsMetrics = (typeof ALL_RAW_ERROR_ANALYTICS_METRICS)[number];

export const ALL_ERROR_ANALYTICS_METRICS = ["errors", "sessions"] as const;

export type ErrorAnalyticsMetrics = (typeof ALL_ERROR_ANALYTICS_METRICS)[number];

export const ALL_RAW_WEB_METRICS = [
    "scrollY",
    "impressions",
    "errors",
    "sum_pageView_activeEngagementTime",
    "count_event_end",
    "count_low_engagement",
    "sessions",
    "pageViews",
    "pageViews_ends",
    "sessions_ends",
    "session_starts",
    "pageView_starts",
    "pageView_with_ads",
    "session_with_ads",
    ...VitalsRawMetrics,
    "min_timestamp",
    "max_timestamp",
    "acquisition_cost",
    "acquisition_cost_measurable_session_starts",
    "nav_domain_lookup_time",
    "nav_network_time",
    "nav_server_time",
    "nav_transfer_time",
    "nav_dom_processing_time",
    "nav_dom_content_loading_time",
    "nav_unload_event_time",
    "nav_transfer_size",
    "nav_encoded_body_size",
    "nav_decoded_body_size",
] as const;

export type RawWebMetrics = (typeof ALL_RAW_WEB_METRICS)[number];

export const ALL_WEB_METRICS = [
    ...VitalsCalculatedMetrics,
    "pageViews",
    "sessions",
    "session_starts",
    "pageView_starts",
    "mean_scrollY",
    "ads_per_pageView",
    "active_pageView_time_minutes",
    "bounceRate",
    "pageViews_per_session",
    "ads_per_session",
    "errors_per_pageView",
    "errors_per_session",
    "active_session_time_minutes",
    "pageViews_without_ads_ratio",
    "sessions_without_ads_ratio",
    "errors",
    "acquisition_cost",
    "acquisition_cost_measurable_session_starts",
    "avg_network_time",
    "avg_transfer_time",
    "avg_dom_completion_time",
    "avg_page_load_time",
    "avg_server_time",
    "avg_dom_processing_time",
    "avg_on_load_time",
    "avg_encoded_body_size",
    "avg_decoded_body_size",
    "avg_transfer_size",
] as const;
export type WebMetrics = (typeof ALL_WEB_METRICS)[number];

export const ALL_RAW_PREBID_METRICS = [
    "prebid_report_ivt_measurable_impressions",
    "prebid_report_givt_impressions",
    "prebid_report_sivt_impressions",
    "prebid_report_sessions",
    "prebid_report_pageViews",
    "prebid_report_auctions",
    "prebid_report_requests",
    "prebid_report_bids",
    "prebid_report_noBids",
    "prebid_report_wins",
    "prebid_report_timeouts",
    "prebid_report_bid_position",
    "prebid_report_bids_revenue",
    "prebid_report_wins_revenue",
    "prebid_report_timeToRespond",
    "prebid_report_setTargetingTime",
    "prebid_report_renderedTime",
    "prebid_report_floor_sum",
    "prebid_report_floor_non_null",
    "prebid_report_highest_bid",
] as const;

export type RawPrebidMetrics = (typeof ALL_RAW_PREBID_METRICS)[number];

export const ALL_PREBID_METRICS = [
    "prebid_report_ivt_rate",
    "prebid_report_givt_rate",
    "prebid_report_sivt_rate",
    "prebid_report_requests",
    "prebid_report_auctions",
    "prebid_report_requests_per_auction",
    "prebid_report_auction_win_rpm",
    "prebid_report_request_win_rpm",
    "prebid_report_request_cpm",
    "prebid_report_bidVolume_auction_cpm",
    "prebid_report_bidVolume_pageView_cpm",
    "prebid_report_bidVolume_session_cpm",
    "prebid_report_bidDensity_auction",
    "prebid_report_bidDensity_pageView",
    "prebid_report_bidDensity_session",
    "prebid_report_timeToRespond",
    "prebid_report_timeouts",
    "prebid_report_timeout_rate",
    "prebid_report_noBids",
    "prebid_report_noBid_rate",
    "prebid_report_bids",
    "prebid_report_bids_revenue",
    "prebid_report_bid_cpm",
    "prebid_report_bid_rate",
    "prebid_report_bid_position",
    "prebid_report_setTargetingTime",
    "prebid_report_wins",
    "prebid_report_wins_revenue",
    "prebid_report_win_cpm",
    "prebid_report_win_rate",
    "prebid_report_request_win_rate",
    "prebid_report_renderedTime",
    "prebid_report_floor_cpm",
    "prebid_report_floor_rate",
    "prebid_report_highest_bid_rate",
] as const;
export type PrebidMetrics = (typeof ALL_PREBID_METRICS)[number];

export const ALL_REQUEST_REDUCTION_SERVER_SAMPLES_RAW_METRICS = [
    "request_reduction_server_samples_wins",
    "request_reduction_server_samples_bids",
    "request_reduction_server_samples_requests",
    "request_reduction_server_samples_win_revenue",
    "request_reduction_server_samples_bid_revenue",
    "request_reduction_server_samples_predicts",
    "request_reduction_server_samples_bid_true_positive",
    "request_reduction_server_samples_bid_true_negative",
    "request_reduction_server_samples_bid_false_positive",
    "request_reduction_server_samples_bid_false_negative",
    "request_reduction_server_samples_win_true_positive",
    "request_reduction_server_samples_win_true_negative",
    "request_reduction_server_samples_win_false_positive",
    "request_reduction_server_samples_win_false_negative",
    "request_reduction_server_samples_missed_revenue",
] as const;

export type RawRequestReductionServerSamplesMetrics = (typeof ALL_REQUEST_REDUCTION_SERVER_SAMPLES_RAW_METRICS)[number];

export const ALL_REQUEST_REDUCTION_CLIENT_V2_RAW_METRICS = [
    "requests",
    "waste",
    "bids",
    "wins",
    "saved_waste",
    "untouched_bids",
    "lost_bids",
    "lost_wins",
    "lost_bid_volume",
    "bid_volume",
    "lost_won_volume",
    "won_volume",
    "untouched_bid_volume",
    "left_waste",
    "auctions",
] as const;

export type RawRequestReductionClientV2Metrics = (typeof ALL_REQUEST_REDUCTION_CLIENT_V2_RAW_METRICS)[number];

export const ALL_RAW_YIELD_METRICS = [
    "impressions_filled",
    "ad_unfilled_count",
    "max_timestamp",
    "min_timestamp",
    "impressions",
    "impressions_filled",
    "impressions_unfilled",
    "impressions_lineitem_house",
    "pageViews",
    "pageViewsExact",
    "sessions",
    "sessions_with_bounced_clicks",
    "session_starts",
    "pageView_starts",
    "viewable",
    "viewable_measurable_impressions",
    "clicks",
    "clicksBounced",
    "clicksReturned",
    "prebid_revenue",
    "prebid_won_impressions",
    "prebid_won_revenue",
    "prebid_lost_impressions",
    "prebid_lost_revenue",
    "prebid_cached_count",
    "prebid_incremental_value",
    "nativo_impressions",
    "nativo_revenue",
    "taboola_impressions",
    "taboola_revenue",
    "yahooGemini_impressions",
    "yahooGemini_revenue",
    "aps_revenue",
    "aps_impressions",
    "adSense_impressions",
    "adSense_revenue",
    "ayMediation_impressions",
    "ayMediation_revenue",
    "outbrain_impressions",
    "outbrain_revenue",
    "dynamicAllocation_impressions",
    "dynamicAllocation_predicted_impressions",
    "dynamicAllocation_revenue",
    "dynamicAllocation_revenue_sum_of_squares",
    "dynamicAllocation_revenue_with_forecast",
    "dynamicAllocation_revenue_with_forecast_client",
    "dynamicAllocation_predicted_revenue",
    "dynamicAllocation_predicted_revenue_residual",
    "dynamicAllocation_predicted_revenue_abs_difference",
    "dynamicAllocation_predicted_revenue_server",
    "dynamicAllocation_predicted_revenue_server_residual",
    "dynamicAllocation_predicted_revenue_server_abs_difference",
    "dynamicAllocation_confirmed_clicks",
    "acquisition_cost",
    "acquisition_cost_measurable_session_starts",
    "direct_impressions",
    "direct_revenue",
    "lineItem_revenue",
    "direct_impressions_legacy",
    "direct_revenue_legacy",
    "lineItem_revenue_legacy",
    "bias",
    "_count",
    "prebid_lost_revenue_hb",
    "ivt_measurable_impressions",
    "givt_impressions",
    "sivt_impressions",
    ...VitalsRawMetrics,
    "uniqueExternalId",
    "first_five_indicator",
    "floor",
    "floor_measurable_impressions",
    "floor_empirical_revenue",
    "blocked_impressions",
    "prebid_user_id_providers_sum_uses",
    "prebid_user_id_providers_zero_usages",
] as const;

export type RawYieldMetrics = (typeof ALL_RAW_YIELD_METRICS)[number];

export const ALL_YIELD_METRICS = [
    "ad_unfilled",
    "ivt_rate",
    "givt_rate",
    "sivt_rate",
    "impressions",
    "impressions_filled",
    "impressions_filled_rate",
    "impressions_unfilled",
    "impressions_unfilled_rate",
    "impressions_unfilled_n_house",
    "impressions_unfilled_n_house_rate",
    "pageViews",
    "pageViewsExact",
    "sessions",
    "session_starts",
    "pageView_starts",
    "ads_per_pageView",
    "ads_per_pageViewExact",
    "ads_per_session",
    "revenue",
    "revenue_with_forecast",
    "clicks",
    "rpm",
    "rpm_with_forecast",
    "valueCpm",
    "pageView_rpm",
    "pageView_valueRpm",
    "session_rpm",
    "session_start_rpm",
    "session_rpm_with_forecast",
    "session_valueRpm",
    "pageViews_per_session",
    "pageViewsExact_per_session",
    "viewability",
    "ctr",
    "cbr",
    "clr",
    "direct_impressions",
    "direct_revenue",
    "direct_rpm",
    "direct_valueRpm",
    "direct_winRate",
    "direct_pageView_rpm",
    "direct_pageView_valueRpm",
    "direct_session_rpm",
    "direct_session_valueRpm",
    "programmatic_impressions",
    "programmatic_revenue",
    "programmatic_revenue_with_forecast",
    "programmatic_rpm",
    "programmatic_valueRpm",
    "programmatic_winRate",
    "programmatic_pageView_rpm",
    "programmatic_pageView_valueRpm",
    "programmatic_session_rpm",
    "programmatic_session_rpm_with_forecast",
    "programmatic_session_valueRpm",
    "nativo_impressions",
    "nativo_revenue",
    "nativo_rpm",
    "nativo_pageView_rpm",
    "nativo_session_rpm",
    "nativo_winRate",
    "content_recommendation_impressions",
    "content_recommendation_revenue",
    "content_recommendation_rpm",
    "content_recommendation_pageView_rpm",
    "content_recommendation_session_rpm",
    "content_recommendation_winRate",
    "dynamicAllocation_impressions",
    "dynamicAllocation_revenue",
    "dynamicAllocation_revenue_with_forecast",
    "dynamicAllocation_revenue_with_forecast_client",
    "dynamicAllocation_rpm",
    "dynamicAllocation_valueRpm",
    "dynamicAllocation_winRate",
    "dynamicAllocation_pageView_rpm",
    "dynamicAllocation_pageViewExact_rpm",
    "dynamicAllocation_pageView_valueRpm",
    "dynamicAllocation_session_rpm",
    "dynamicAllocation_session_rpm_with_forecast",
    "dynamicAllocation_session_valueRpm",
    "acquisition_cost",
    "acquisition_cost_measurable_session_starts",
    "prebid_rpm",
    "prebid_cache_rate",
    "prebid_valueRpm",
    "prebid_winRate",
    "prebid_revenue",
    "prebid_pageView_rpm",
    "prebid_pageView_valueRpm",
    "prebid_session_rpm",
    "prebid_session_valueRpm",
    "prebid_won_impressions",
    "prebid_won_rpm",
    "prebid_won_valueRpm",
    "prebid_won_revenue",
    "prebid_won_pageView_rpm",
    "prebid_won_pageViewExact_rpm",
    "prebid_won_pageView_valueRpm",
    "prebid_won_session_rpm",
    "prebid_won_session_valueRpm",
    "prebid_lost_impressions",
    "prebid_lost_rpm",
    "prebid_lost_valueRpm",
    "prebid_lost_revenue",
    "prebid_lost_pageView_rpm",
    "prebid_lost_pageView_valueRpm",
    "prebid_lost_session_rpm",
    "prebid_lost_session_valueRpm",
    "prebid_incremental_value",
    "prebid_incremental_value_rpm",
    "bias",
    "lost_rpm_hb",
    "min_cpm",
    "diff",
    "won_lost_diff",
    "lineItem_revenue",
    "dynamicAllocation_predicted_impressions",
    "dynamicAllocation_predicted_impressions_error",
    "dynamicAllocation_predicted_revenue",
    "dynamicAllocation_predicted_rpm",
    "dynamicAllocation_predicted_revenue_error",
    "dynamicAllocation_predicted_revenue_relative_error",
    "dynamicAllocation_predicted_revenue_r2",
    "dynamicAllocation_predicted_revenue_mae",
    "dynamicAllocation_predicted_revenue_rmse",
    "dynamicAllocation_predicted_revenue_server",
    "dynamicAllocation_predicted_rpm_server",
    "dynamicAllocation_predicted_revenue_error_server",
    "dynamicAllocation_predicted_revenue_r2_server",
    "dynamicAllocation_predicted_revenue_mae_server",
    "dynamicAllocation_predicted_revenue_rmse_server",
    "dynamicAllocation_predicted_revenue_relative_error_server",
    "dynamicAllocation_confirmed_clicks_rate",
    ...VitalsCalculatedMetrics,
    "uniqueExternalId",
    "adSense_impressions",
    "adSense_revenue",
    "adSense_rpm",
    "adSense_pageView_rpm",
    "adSense_session_rpm",
    "adSense_winRate",
    "ayMediation_impressions",
    "ayMediation_revenue",
    "ayMediation_rpm",
    "ayMediation_pageView_rpm",
    "ayMediation_session_rpm",
    "ayMediation_winRate",
    "floor_rpm",
    "floor_impressions",
    "floor_empirical_revenue_ratio",
    "clicks_bounce_session_rate",
    "sessions_with_bounced_clicks",
    "miss_click_impression_pct",
    "blocked_impressions",
    "blocked_impressions_rate",
    "aps_impressions",
    "aps_revenue",
    "aps_rpm",
    "aps_pageView_rpm",
    "aps_session_rpm",
    "aps_winRate",
    "prebid_user_id_providers_avg",
    "prebid_user_id_providers_zero_usages",
    "prebid_user_id_providers_zero_usages_rate",
] as const;

export const ALL_INDUSTRY_METRICS = ["ind_fair_share_win_rate", "ind_fair_share_revenue", "ind_presence"] as const;

export const ALL_AD_REVENUE_METRICS = ["ad_revenue_index_percentage", "ad_revenue_index_impressions"] as const;

export const ALL_REQUEST_REDUCTION_CLIENT_METRICS = [
    "request_reduction_client_requests",
    "request_reduction_client_waste_ratio",
    "request_reduction_client_saved_waste_ratio",
    "request_reduction_client_lost_bids_ratio",
    "request_reduction_client_lost_wins_ratio",
    "request_reduction_client_bid_volume_loss_ratio",
    "request_reduction_client_won_volume_loss_ratio",
    "request_reduction_client_untouched_bid_volume",
    "request_reduction_client_bid_volume_total",
    "request_reduction_client_bid_cpm",
    "request_reduction_client_bids",
    "request_reduction_client_wins",
    "request_reduction_client_saved_waste",
    "request_reduction_client_untouched_bids",
    "request_reduction_client_lost_bids",
    "request_reduction_client_lost_wins",
    "request_reduction_client_left_waste",
] as const;

export const ALL_REQUEST_REDUCTION_SERVER_LATENCY_METRICS = [
    "request_reduction_server_latency_predict_requests",
    "request_reduction_server_latency_predict_errors",
    "request_reduction_server_latency_predict_bidders",
    "request_reduction_server_latency_predict_latencies_p50",
    "request_reduction_server_latency_predict_latencies_p99",
    "request_reduction_server_latency_predict_latencies_p95",
    "request_reduction_server_latency_result_requests",
    "request_reduction_server_latency_predict_result_ratio",
    "request_reduction_server_latency_result_latencies_p50",
    "request_reduction_server_latency_result_errors",
    "request_reduction_server_latency_result_latencies_p99",
    "request_reduction_server_latency_result_latencies_p95",
    "request_reduction_server_latency_average_bidder_per_auctions",
    "request_reduction_server_latency_sampled_traffic_pct",
] as const;

export const ALL_REQUEST_REDUCTION_SERVER_SAMPLES_PROFILE_METRICS = [
    "request_reduction_server_samples_requests",
    "request_reduction_server_samples_win_rate",
    "request_reduction_server_samples_bid_rate",
    "request_reduction_server_samples_wins",
    "request_reduction_server_samples_bids",
    "request_reduction_server_samples_win_revenue",
    "request_reduction_server_samples_bid_revenue",
    "request_reduction_server_samples_win_cpm",
    "request_reduction_server_samples_bid_cpm",
] as const;

export const ALL_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_METRICS = [
    ...ALL_REQUEST_REDUCTION_SERVER_SAMPLES_PROFILE_METRICS,
    "request_reduction_server_samples_req_reduction_rate",
    "request_reduction_server_samples_lost_bids_rate",
    "request_reduction_server_samples_lost_wins_rate",
    "request_reduction_server_samples_lost_revenue_rate",
    "request_reduction_server_samples_missed_revenue",
    "request_reduction_server_samples_predicts",
    "request_reduction_server_samples_rpb",
    "request_reduction_server_samples_reduction_efficiency",
    "request_reduction_server_samples_bid_accuracy",
    "request_reduction_server_samples_bid_precision",
    "request_reduction_server_samples_bid_recall",
    "request_reduction_server_samples_bid_specificity",
    "request_reduction_server_samples_win_accuracy",
    "request_reduction_server_samples_win_precision",
    "request_reduction_server_samples_win_recall",
    "request_reduction_server_samples_win_specificity",
] as const;

export const ALL_BUY_METRICS = [
    "buy_report_clicks",
    "buy_report_sessions",
    "buy_report_session_click_ratio",
    "buy_report_cpc",
    "buy_report_spent",
    "buy_report_roi",
    "buy_report_roi_with_forecast",
    "buy_report_profit",
    "buy_report_profit_with_forecast",
    "buy_report_click_rpm",
    "buy_report_click_rpm_with_forecast",
    "buy_report_click_revenue",
    "buy_report_click_revenue_with_forecast",
    "buy_report_cps",
    "buy_report_cpai",
    "buy_report_new_sessions",
    "buy_report_ffi",
] as const;

export const ALL_SITE_METRICS = [
    "site_report_clicks",
    "site_report_sessions",
    "site_report_session_click_ratio",
    "site_report_cpc",
    "site_report_spent",
    "site_report_roi",
    "site_report_roi_with_forecast",
    "site_report_profit",
    "site_report_profit_with_forecast",
    "site_report_click_rpm",
    "site_report_click_rpm_with_forecast",
    "site_report_click_revenue",
    "site_report_click_revenue_with_forecast",
    "site_report_cps",
    "site_report_cpai",
    "site_report_new_sessions",
    "site_report_ffi",
] as const;

export const ALL_SPENT_BY_SECTION_REPORT_METRICS = [
    "spent_by_section_report_clicks",
    "spent_by_section_report_sessions",
    "spent_by_section_report_session_click_ratio",
    "spent_by_section_report_cpc",
    "spent_by_section_report_spent",
    "spent_by_section_report_roi",
    "spent_by_section_report_roi_with_forecast",
    "spent_by_section_report_profit",
    "spent_by_section_report_profit_with_forecast",
    "spent_by_section_report_click_rpm",
    "spent_by_section_report_click_rpm_with_forecast",
    "spent_by_section_report_click_revenue",
    "spent_by_section_report_click_revenue_with_forecast",
    "spent_by_section_report_cps",
    "spent_by_section_report_cpai",
    "spent_by_section_report_new_sessions",
    "spent_by_section_report_ffi",
] as const;

export const ALL_DISCREPANCY_REVENUE_REPORT_RAW_METRICS = [
    "assertive_revenue",
    "actual_revenue",
    "assertive_impressions",
    "actual_impressions",
] as const;

export type DiscrepancyRevenueReportRawMetrics = (typeof ALL_DISCREPANCY_REVENUE_REPORT_RAW_METRICS)[number];

export const ALL_DISCREPANCY_REVENUE_REPORT_METRICS = [
    "actual_revenue",
    "assertive_impressions",
    "actual_impressions",
    "assertive_revenue",
    "calculated_revenue_discrepancy",
    "calculated_revenue_discrepancy_percentage",
    "calculated_impressions_discrepancy",
    "calculated_impressions_discrepancy_percentage",
] as const;

export const ALL_FINANCIAL_REPORT_METRICS = [
    "actual_revenue",
    "actual_impressions",
    "actual_revenue_ssp_currency",
] as const;

export type FinancialReportMetrics = (typeof ALL_FINANCIAL_REPORT_METRICS)[number];

export type PrebidMetricsType = PrebidMetrics;
export type YieldMetricsType = (typeof ALL_YIELD_METRICS)[number];
export type IndustryWideDataMetricsType = (typeof ALL_INDUSTRY_METRICS)[number];
export type AddRevenueIndexMetricsType = (typeof ALL_AD_REVENUE_METRICS)[number];
export type RequestReductionClientMetricsType = (typeof ALL_REQUEST_REDUCTION_CLIENT_METRICS)[number];
export type RequestReductionServerLatencyMetricsType = (typeof ALL_REQUEST_REDUCTION_SERVER_LATENCY_METRICS)[number];
export type RequestReductionServerSamplesPredictMetricsType =
    (typeof ALL_REQUEST_REDUCTION_SERVER_SAMPLES_PREDICT_METRICS)[number];
export type RequestReductionServerSamplesProfileMetricsType =
    (typeof ALL_REQUEST_REDUCTION_SERVER_SAMPLES_PROFILE_METRICS)[number];
export type BuyReportMetricsType = (typeof ALL_BUY_METRICS)[number];
export type SiteReportMetricsType = (typeof ALL_SITE_METRICS)[number];
export type SpentBySectionReportMetricsType = (typeof ALL_SPENT_BY_SECTION_REPORT_METRICS)[number];
export type DiscrepancyRevenueReportMetricsType = (typeof ALL_DISCREPANCY_REVENUE_REPORT_METRICS)[number];
export type FinancialReportMetricsType = (typeof ALL_FINANCIAL_REPORT_METRICS)[number];

export type MainMetricsType =
    | YieldMetricsType
    | WebMetrics
    | ErrorAnalyticsMetrics
    | IndustryWideDataMetricsType
    | AddRevenueIndexMetricsType
    | RequestReductionClientMetricsType
    | RequestReductionServerLatencyMetricsType
    | RequestReductionServerSamplesPredictMetricsType
    | RequestReductionServerSamplesProfileMetricsType
    | BuyReportMetricsType
    | SiteReportMetricsType
    | SpentBySectionReportMetricsType
    | PrebidMetricsType
    | DiscrepancyRevenueReportMetricsType
    | FinancialReportMetricsType;

export type RawMetricsType =
    | RawRequestReductionServerSamplesMetrics
    | RawYieldMetrics
    | RawPrebidMetrics
    | DiscrepancyRevenueReportRawMetrics;
