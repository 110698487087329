import Topo from "@hapi/topo";
import type { IssStat } from "../../utils/iss";
import type { MainMetricsType, RawMetricsType } from "../metrics.constants";
import type { IRawMetric } from "../raw-metrics.constants";

// export type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never;

export type AggOpNames = "total" | "byDate" | "groupTotal" | "groupByDate";
export type Formula<T extends string> = (
    m: Record<T, number>,
    opname: AggOpNames,
    total: Record<T, number>,
    totalByDate: Record<T, number>
) => number | null;

/**
 * Calculation Type is used to define the chart type that is going to be used
 * to visualize the metric, and to apply statistical significance.
 * We render two variants of charts: "sum" and "division".
 * "sum" charts are rendered as a stacked bar chart.
 * "division" charts are rendered as overlapped line charts.
 * Statistical significance depends on the type of division, which can be either
 * "proportion" or "mean", but we also support a special type "SpecialRate" which should
 * be used when the data required to calculate statistical significance is not provided.
 */
export enum CalculationType {
    /**
     * Always statistically significant.
     * Uses "sum" chart variant.
     */
    Sum = "Sum",
    /**
     * Metrics which can be thought of as a "percentage", or rate.
     * Statistical significance is fully automatic.
     * Uses "division" chart variant.
     */
    ProportionRatio = "ProportionRatio",
    /**
     * Metrics which can be thought of as a "percentage", or rate.
     * Statistical significance is fully automatic.
     * Uses "division" chart variant.
     */
    ProportionPercentage = "ProportionPercentage",
    /**
     * Metrics which are value-based, not a "percentage".
     * Statistical significance uses hard-coded data.
     * It will throw an error in CI if a metric of this type do not inform it's statistical significance data.
     * Uses "division" chart variant.
     */
    Mean = "Mean",
    /**
     * Statistically significance will ignore these metrics, making them always statistically significance.
     * Uses "division" chart variant.
     */
    SpecialRate = "SpecialRate",
}

export enum OptimizationObjective {
    Maximize = "Maximize",
    Minimize = "Minimize",
    NoObjective = "NoObjective",
}
export interface IMetric<MainKeys extends string, RawKeys extends string> {
    label: string;
    explanation: string;
    formula: Formula<MainKeys | RawKeys>;
    issStats?: IssStat;
    calculationType: CalculationType;
    objective: OptimizationObjective;
    hideFromApiDocs?: boolean;
    hideFromAlerts?: boolean;
    timeSpan?: number;
    decimalPlaces?: number;
    keepFormulaFalsyAsFalsy?: boolean; // default behavior is to coerce a formula's null to zero, but we need to break this rule for certain metrics
}

export interface IMetricReturns<MainKeys extends string, RawKeys extends string> extends IMetric<MainKeys, RawKeys> {
    id: MainKeys;
    formulaDependencies: (MainKeys | RawKeys)[];
    rawDependencies: RawKeys[];
    config?: {
        [key: string]: number;
    };
    shareOfVoiceChildren: MainMetricsType[];
}
export type AnyPossibleMetricItem = IMetricReturns<any, any>;

export type AllPossibleMetricsMap = Partial<Record<MainMetricsType, AnyPossibleMetricItem>>;

export type AnyMetric = MainMetricsType;
export type AnyMetricOrRaw = MainMetricsType | RawMetricsType;

// preprocess metrics
// regex: use generic regex because variable names change when the js is minified
const re = /\w\.(\w+)/g;

export function addFormulaDependenciesAndId(ms: AllPossibleMetricsMap): void {
    Object.entries(ms).forEach(([id, m]) => {
        (m as any).id = id;
    });
    Object.values(ms).forEach(m => {
        if (m.formula) {
            const dependencies: (MainMetricsType | RawMetricsType)[] = [];
            let match;
            do {
                // be aware of the stateful regex
                // https://stackoverflow.com/questions/11477415/why-does-javascripts-regex-exec-not-always-return-the-same-value
                match = re.exec(m.formula.toString());
                //@ts-ignore
                if (match && match[1] && !dependencies.includes(match[1])) {
                    //@ts-ignore
                    dependencies.push(match[1]);
                }
            } while (match);

            (m as any).formulaDependencies = dependencies;
        } else {
            (m as any).formulaDependencies = [];
        }
    });

    Object.values(ms).forEach(m => {
        if (m.calculationType === CalculationType.Sum) {
            m.shareOfVoiceChildren = m.formulaDependencies.filter(
                it => Object.keys(ms).includes(it) && it != m.id
            ) as MainMetricsType[];
        } else {
            m.shareOfVoiceChildren = [];
        }
    });
}
export const sortMetricsTopological = (it: readonly AnyPossibleMetricItem[]): AnyPossibleMetricItem[] => {
    const sorter = new Topo.Sorter<AnyPossibleMetricItem>();
    it.forEach(metric => {
        sorter.add(metric, {
            after: metric.formulaDependencies?.filter(dep => dep !== metric.id) ?? [],
            group: metric.id,
        });
    });
    return sorter.nodes;
};
export function addRawDependencies(
    ms: AllPossibleMetricsMap,
    rawMetrics: Record<string, IRawMetric>,
    otherMetrics: AllPossibleMetricsMap = {}
): void {
    sortMetricsTopological(Object.values(ms)).forEach((m: AnyPossibleMetricItem) => {
        if (m.formulaDependencies) {
            const rawDependencies: RawMetricsType[] = [];

            m.formulaDependencies.forEach((dep: MainMetricsType | RawMetricsType) => {
                if (rawMetrics[dep]) {
                    rawDependencies.push(dep as any);
                } else if ((otherMetrics as any)[dep]) {
                    const m = (otherMetrics as any)[dep].rawDependencies;
                    if (m == null) {
                        throw new Error(`metric ${dep} is missing rawDependencies`);
                    }
                    rawDependencies.push(...m);
                } else if ((ms as any)[dep]) {
                    const m = (ms as any)[dep].rawDependencies;
                    if (m == null) {
                        throw new Error(`metric ${dep} is missing rawDependencies`);
                    }
                    rawDependencies.push(...m);
                }
            });
            m.rawDependencies = rawDependencies;
            rawDependencies.forEach(dep => {
                const { config } = rawMetrics[dep] as any;
                if (config) {
                    m.config = { ...m.config, ...config };
                }
            });
        }
    });
}
