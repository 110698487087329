import type {
    AddRevenueIndexMetricsType,
    DiscrepancyRevenueReportMetricsType,
    DiscrepancyRevenueReportRawMetrics,
    ErrorAnalyticsMetrics,
    FinancialReportMetrics,
    FinancialReportMetricsType,
    IndustryWideDataMetricsType,
    PrebidMetricsType,
    RawErrorAnalyticsMetrics,
    RawPrebidMetrics,
    RawRequestReductionClientV2Metrics,
    RawRequestReductionServerSamplesMetrics,
    RawWebMetrics,
    RawYieldMetrics,
    RequestReductionClientMetricsType,
    RequestReductionServerLatencyMetricsType,
    RequestReductionServerSamplesPredictMetricsType,
    RequestReductionServerSamplesProfileMetricsType,
    WebMetrics,
    YieldMetricsType,
} from "../../metrics.constants";
import {
    rawMetricsDiscrepancyRevenue,
    rawMetricsErrorAnalytics,
    rawMetricsFinancialReport,
    rawMetricsPrebid,
    rawMetricsRequestReductionClientV2,
    rawMetricsRequestReductionServerSamples,
    rawMetricsWeb,
    rawMetricsYield,
} from "../../raw-metrics.constants";
import {
    addFormulaDependenciesAndId,
    addRawDependencies,
    type IMetric,
    type IMetricReturns,
} from "../calculated-metrics.types-input";

export const WebMetricMap = (
    args: Record<WebMetrics, IMetric<WebMetrics, RawWebMetrics>>
): Record<WebMetrics, IMetricReturns<WebMetrics, RawWebMetrics>> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsWeb);
    return args as any;
};
export const ErrorAnalyticsMetricMap = (
    args: Record<ErrorAnalyticsMetrics, IMetric<ErrorAnalyticsMetrics, RawErrorAnalyticsMetrics>>
): Record<ErrorAnalyticsMetrics, IMetricReturns<ErrorAnalyticsMetrics, RawErrorAnalyticsMetrics>> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsErrorAnalytics);
    return args as any;
};

export const YieldMetricMap = (
    args: Record<YieldMetricsType, IMetric<YieldMetricsType, RawYieldMetrics>>
): Record<YieldMetricsType, IMetricReturns<YieldMetricsType, RawYieldMetrics>> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsYield);
    return args as any;
};

export const PrebidMetricMap = (
    args: Record<PrebidMetricsType, IMetric<PrebidMetricsType, RawPrebidMetrics>>
): Record<PrebidMetricsType, IMetricReturns<PrebidMetricsType, RawPrebidMetrics>> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsPrebid);
    return args as any;
};

export type RawIndustryMetrics =
    | "prebid_won_impressions"
    | "fair_available_impressions"
    | "prebid_won_revenue"
    | "fair_total_prebid_revenue"
    | "presence"
    | "entities_count"
    | "avg_present_in_days_frac";
export const IndustryMetricMap = (
    args: Record<IndustryWideDataMetricsType, IMetric<IndustryWideDataMetricsType, RawIndustryMetrics>>
): Record<IndustryWideDataMetricsType, IMetricReturns<IndustryWideDataMetricsType, RawIndustryMetrics>> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsYield);
    return args as any;
};

type RawAdRevenueMetrics = "weighed_stouffer" | "agg_count" | "impressions";
export const AdRevenueMetricMap = (
    args: Record<AddRevenueIndexMetricsType, IMetric<AddRevenueIndexMetricsType, RawAdRevenueMetrics>>
): Record<AddRevenueIndexMetricsType, IMetricReturns<AddRevenueIndexMetricsType, RawAdRevenueMetrics>> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsYield);
    return args as any;
};

export const RequestReductionServerSamplesPredictMetricMap = (
    args: Record<
        RequestReductionServerSamplesPredictMetricsType,
        IMetric<RequestReductionServerSamplesPredictMetricsType, RawRequestReductionServerSamplesMetrics>
    >
): Record<
    RequestReductionServerSamplesPredictMetricsType,
    IMetricReturns<RequestReductionServerSamplesPredictMetricsType, RawRequestReductionServerSamplesMetrics>
> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsRequestReductionServerSamples);
    return args as any;
};

export const RequestReductionServerSamplesProfileMetricMap = (
    args: Record<
        RequestReductionServerSamplesProfileMetricsType,
        IMetric<RequestReductionServerSamplesProfileMetricsType, RawRequestReductionServerSamplesMetrics>
    >
): Record<
    RequestReductionServerSamplesProfileMetricsType,
    IMetricReturns<RequestReductionServerSamplesProfileMetricsType, RawRequestReductionServerSamplesMetrics>
> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsRequestReductionServerSamples);
    return args as any;
};

type RawRequestReductionServerLatencyMetrics =
    | "predict_requests"
    | "result_requests"
    | "predict_errors"
    | "predict_bidders"
    | "_rows"
    | "predict_latencies_p50"
    | "predict_latencies_p95"
    | "predict_latencies_p99"
    | "result_errors"
    | "result_latencies_p50"
    | "result_latencies_p95"
    | "result_latencies_p99"
    | "predict_samples";
export const RequestReductionServerLatencyMetricMap = (
    args: Record<
        RequestReductionServerLatencyMetricsType,
        IMetric<RequestReductionServerLatencyMetricsType, RawRequestReductionServerLatencyMetrics>
    >
): Record<
    RequestReductionServerLatencyMetricsType,
    IMetricReturns<RequestReductionServerLatencyMetricsType, RawRequestReductionServerLatencyMetrics>
> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsYield);
    return args as any;
};
type RequestReductionClientV1MetricsType =
    | "request_reduction_client_requests"
    | "request_reduction_client_waste_ratio"
    | "request_reduction_client_saved_waste_ratio"
    | "request_reduction_client_lost_bids_ratio"
    | "request_reduction_client_lost_wins_ratio"
    | "request_reduction_client_bid_volume_loss_ratio"
    | "request_reduction_client_won_volume_loss_ratio"
    | "request_reduction_client_untouched_bid_volume"
    | "request_reduction_client_bid_volume_total"
    | "request_reduction_client_bid_cpm"
    | "request_reduction_client_bids"
    | "request_reduction_client_wins"
    | "request_reduction_client_saved_waste"
    | "request_reduction_client_untouched_bids"
    | "request_reduction_client_lost_bids"
    | "request_reduction_client_lost_wins"
    | "request_reduction_client_left_waste";
export const RequestReductionClientMetricMap = (
    args: Record<
        RequestReductionClientV1MetricsType,
        IMetric<RequestReductionClientV1MetricsType, RawRequestReductionClientV2Metrics>
    >
): Record<
    RequestReductionClientV1MetricsType,
    IMetricReturns<RequestReductionClientV1MetricsType, RawRequestReductionClientV2Metrics>
> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsYield);
    return args as any;
};

export const RequestReductionClientV2MetricMap = (
    args: Record<
        RequestReductionClientMetricsType,
        IMetric<RequestReductionClientMetricsType, RawRequestReductionClientV2Metrics>
    >
): Record<
    RequestReductionClientMetricsType,
    IMetricReturns<RequestReductionClientMetricsType, RawRequestReductionClientV2Metrics>
> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsRequestReductionClientV2);
    return args as any;
};

export const DiscrepancyRevenueMetricMap = (
    args: Record<
        DiscrepancyRevenueReportMetricsType,
        IMetric<DiscrepancyRevenueReportMetricsType, DiscrepancyRevenueReportRawMetrics>
    >
): Record<
    DiscrepancyRevenueReportMetricsType,
    IMetricReturns<DiscrepancyRevenueReportMetricsType, DiscrepancyRevenueReportRawMetrics>
> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsDiscrepancyRevenue);
    return args as any;
};

export const FinancialMetricMap = (
    args: Record<FinancialReportMetricsType, IMetric<FinancialReportMetricsType, FinancialReportMetrics>>
): Record<FinancialReportMetricsType, IMetricReturns<FinancialReportMetricsType, FinancialReportMetrics>> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsFinancialReport);
    return args as any;
};
