import { CalculationType, OptimizationObjective } from "../../calculated-metrics.types-input";
import { FinancialMetricMap } from "../calculated-metrics.types";

export const calculatedFinancialReportMetrics = FinancialMetricMap({
    actual_revenue: {
        label: "Actual Revenue (USD)",
        explanation: "Actual revenue represented from SSP's connections in USD",
        formula: m => m.actual_revenue,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.NoObjective,
    },
    actual_impressions: {
        label: "Actual Impressions",
        explanation: "Actual impressions represented from SSP's connections",
        formula: m => m.actual_impressions,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.NoObjective,
    },
    actual_revenue_ssp_currency: {
        label: "Actual Revenue (SSP Currency)",
        explanation: "Actual revenue represented from SSP's connections in SSP currency",
        formula: m => m.actual_revenue_ssp_currency,
        calculationType: CalculationType.Sum,
        objective: OptimizationObjective.NoObjective,
    },
});
