export const ALL_TIME_GRANULARITIES_EXCLUDING_NO = [
    "by_minute",
    "by_10_minutes",
    "by_hour",
    "by_day",
    "by_week",
    "by_month",
] as const;
export type ValidTimeGranularityExceptNo = (typeof ALL_TIME_GRANULARITIES_EXCLUDING_NO)[number];

export const ALL_TIME_GRANULARITIES = [...ALL_TIME_GRANULARITIES_EXCLUDING_NO, "no_granularity"] as const;

export type TimeGranularity = (typeof ALL_TIME_GRANULARITIES)[number];

export const BUY_REPORT_INVALID_TIME_GRANULARITIES: TimeGranularity[] = ["by_10_minutes", "by_minute"];
export const SITE_REPORT_INVALID_TIME_GRANULARITIES: TimeGranularity[] = ["by_10_minutes", "by_minute", "by_hour"];

export const INDUSTRY_REPORT_TIME_GRANULARITIES: TimeGranularity[] = ["by_day", "by_week", "by_month"];

export const SITE_REPORT_TIME_GRANULARITIES: TimeGranularity[] = ["by_day", "by_week", "by_month"];

export const SPENT_BY_SECTION_REPORT_TIME_GRANULARITIES: TimeGranularity[] = ["by_day", "by_week", "by_month"];

export const BUY_REPORT_TIME_GRANULARITIES: TimeGranularity[] = ["by_hour", "by_day", "by_week", "by_month"];
export const DISCREPANCY_REVENUE_TIME_GRANULARITIES: TimeGranularity[] = ["by_day", "by_week"];
export const FINANCIAL_TIME_GRANULARITIES: TimeGranularity[] = ["by_day", "by_week"];
export const DEFAULT_TIME_GRANULARITY: TimeGranularity = "by_day";

export const TIME_GRANULARITY_LABELS: Record<TimeGranularity, string> = {
    by_minute: "by Minute",
    by_10_minutes: "by 10 Minutes",
    by_hour: "by Hour",
    by_day: "by Day",
    by_week: "by Week",
    by_month: "by Month",
    no_granularity: "No Granularity",
};

export const TIME_GRANULARITY_DURATION: Record<TimeGranularity, number> = {
    by_minute: 1000 * 60,
    by_10_minutes: 1000 * 60 * 10,
    by_hour: 1000 * 60 * 60,
    by_day: 1000 * 60 * 60 * 24,
    by_week: 1000 * 60 * 60 * 24 * 7,
    by_month: 1000 * 60 * 60 * 24 * 30,
    no_granularity: 0,
};
