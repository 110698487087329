import type {
    BuyReportMetricsType,
    RawYieldMetrics,
    SiteReportMetricsType,
    SpentBySectionReportMetricsType,
    YieldMetricsType,
} from "../../metrics.constants";
import { rawMetricsYield } from "../../raw-metrics.constants";
import {
    addFormulaDependenciesAndId,
    addRawDependencies,
    type IMetric,
    type IMetricReturns,
} from "../calculated-metrics.types-input";
import { calculatedYieldMetrics } from "./by-placement/yield";

export type RawBuyReportMetrics = RawYieldMetrics | YieldMetricsType | "clicks_bought" | "spent";
export const BuyReportMetricMap = (
    args: Record<BuyReportMetricsType, IMetric<BuyReportMetricsType, RawBuyReportMetrics>>
): Record<BuyReportMetricsType, IMetricReturns<BuyReportMetricsType, RawBuyReportMetrics>> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsYield, calculatedYieldMetrics);
    return args as any;
};

export const SiteReportMetricMap = (
    args: Record<SiteReportMetricsType, IMetric<SiteReportMetricsType, RawBuyReportMetrics>>
): Record<SiteReportMetricsType, IMetricReturns<SiteReportMetricsType, RawBuyReportMetrics>> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsYield, calculatedYieldMetrics);
    return args as any;
};

export const SpentBySectionReportMetricMap = (
    args: Record<SpentBySectionReportMetricsType, IMetric<SpentBySectionReportMetricsType, RawBuyReportMetrics>>
): Record<SpentBySectionReportMetricsType, IMetricReturns<SpentBySectionReportMetricsType, RawBuyReportMetrics>> => {
    addFormulaDependenciesAndId(args as any);
    addRawDependencies(args as any, rawMetricsYield, calculatedYieldMetrics);
    return args as any;
};
